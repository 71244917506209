import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Categoria } from '../models/categoria';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {
  selectedCategory: Categoria;
  categories: Categoria[];

  constructor(private http: HttpClient) { 
    this.selectedCategory = new Categoria();
    this.categories = [];
  }

  getCategorias() {
    return this.http.get(Global.URL_API + 'category/getCategory', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    })
  }

  postCategoria(Categoria: Categoria) {
    return this.http.post(Global.URL_API + 'category/saveCategory', Categoria, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  putCategoria(Categoria: Categoria) {
    return this.http.put(Global.URL_API + 'category/Category' + `/${Categoria._id}`, Categoria, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  deleteCategoria(_id: string) {
    return this.http.delete(Global.URL_API + 'category/Category' + `/${_id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
