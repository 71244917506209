export class ValeCaja{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public folio_control:string;
    public id_servicio:number;
    public num_cajero:number;
    public total_operacion:number;
    public cantidad_recibida:number;
    public cantidad_devuelta:number;
    public cantidad_nodevuelta:number;
    public usuario_final:string;
    public tel_usuario_final:string;
    public fecha_hora_vale:string;
    public fecha_ticketd: Date;             //alta
    public hora_tickett:String;  
    public importe_total_pagado: number; //alta
    public contribuyente: String;             //alta
    public desc_servicio:String;               //alta
    public referencia_pago: String;             //alta
    public tecnico: String;             //alta
    public statusincidencia:String;               //alta

    //alta
}
