<main>
    <div class="container-fluid">
        <div class="row mt-3"  #passport id="passport">
            <div class="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <mdb-card class="mt-3">
                    <mdb-card-body class="pt-0 pl-0 pr-0">
                        <mdb-card-text class="card-header-bg white-text">
                            <h1 class="h1-responsive text-center">¡Hola {{ evento.Guest.NombreInvitado }}!</h1>
                            <h4 class="h4-responsive text-center">
                                <b>{{ evento.User.NombreResidente }}</b>
                                te envía un pase de entrada a {{ evento.User.NombreFraccionamiento }}
                                para el evento "{{evento.Event.NombreEvento}}". Para ingresar, simplemente
                                presenta este código en la caseta de acceso en los carriles
                                marcados con el simbolo de código QR. También puedes
                                capturar el siguiente código:</h4><br>
                            <h1 class="h1-responsive text-center font-weight-bold">
                                <h4 class="h4-responsive font-weight-bold">{{evento.Guest.pin}}</h4>
                            </h1> <br>

                            <h4 class="h4-responsive text-center">
                                Puedes utilizar cualquier carril desocupado de entrada
                                y salida.
                            </h4>
                        </mdb-card-text>
                        <div class="text-center">
                            <img class="img-fluid"
                                src="{{evento.Guest.QR}}">
                        </div>
                        <h1 class="h1-responsive text-center mt-2 font-weitght-bold">QuickAccess</h1>
                        <hr>
                        <h5 class="h5-responsive text-center">
                            válido del {{evento.Event.FechaInicio + ' ' + evento.Event.HoraInicio}} hrs
                            hasta {{evento.Event.FechaFin + ' ' + evento.Event.HoraFin}} hrs
                        </h5>
                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <button (click)="downloadPassport()" class="btn btn-block btn-success mt-2">Descargar QR</button>
            </div>
        </div>
    </div>
</main>
<footer class="page-footer font-small mdb-color mt-3">
    <div class="footer-copyright text-center py-3">© 2020 Copyright QuickAccess</div>
</footer>