<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <h2 class="card-title">{{page_title}}</h2>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <form (ngSubmit)="getAuditoria()" #filtrosForm="ngForm" [formGroup]="myForm" >
                        <div class="form-group row">
                            <div class="col-md-6">
                            <label for="name">Fecha inicio</label>
                            <input class="form-control" id="fechaInicio" f type="date" name="fechaInicio"  #fechaInicio="ngModel"
                             [(ngModel)]="selectedFechaInicio" placeholder="Fecha inicio" >
                            
                            </div>
                            <div class="col-md-6">
                            <label for="name">Fecha fin</label>
                            <input class="form-control" id="fechafin" type="date" name="fechafin" #fechafin="ngModel"
                            [(ngModel)]="selectedFechaFin" placeholder="Fecha fin">
                            
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="select1">Técnicos</label>
                                <select class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="select1">Cajeros</label>
                                <ng-select name="cajero" id="cajero" [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedCajero">
                                </ng-select>
                                
                            </div>
                        </div>
                        <div class="form-group row">
            
                            <div class="col-md-4"></div>
                            <div class="col-md-4 text-center">
                                <button  class="btn  btn-success ">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table" id="excel-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Pagadas</th>
                                            <th scope="col">Canceladas</th>
                                            <th scope="col">Vales Caja</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <td >{{auditoria.pagado|currency:'USD':'symbol'}}</td>
                                            <td *ngIf="auditoria.cancelado">{{auditoria.cancelado|currency:'USD':'symbol'}}</td>
                                            <td *ngIf="!auditoria.cancelado">{{0|currency:'USD':'symbol'}}</td>
                                            <td *ngIf="auditoria.valecaja">{{auditoria.valecaja|currency:'USD':'symbol'}}</td>
                                            <td *ngIf="!auditoria.valecaja">{{0|currency:'USD':'symbol'}}</td>
                                    </tbody>
                                </table>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
