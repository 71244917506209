<div class="modal-header">
    <h4 class="modal-title">{{page_title}}</h4>
    <button type="button" class="close " aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h4 class="header text-center"><b>{{username}}</b>
        <br>
        <span
            *ngIf="perfil == 'sa' || perfil == 'Administrador'">{{userAddressService.selectedUserAddress.idUsuario}}</span>
        <span *ngIf="perfil == 'Residente'">{{userAddressService.selectedUserAddress.noCuenta}}</span>
    </h4>

    <hr>
    <div class="row">
        <div class="col-md-12">
            <form (ngSubmit)="addAddress(addressForm)" #addressForm="ngForm">
                <input type="hidden" name="_id" id="_id" #_id="ngModel"
                    [(ngModel)]="userAddressService.selectedUserAddress._id">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label for="idInmueble">Tipo de inmueble</label>
                            <select class="form-control" name="idInmueble" id="idInmueble" #idInmueble="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.idInmueble">
                                <option *ngFor="let inmueble of inmuebleService.inmuebles" [value]="inmueble._id">
                                    {{inmueble.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label for="calle">Nombre</label>
                            <input class="form-control" type="text" name="alias" id="alias" #alias="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.alias"
                                placeholder="Nombre del inmueble">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="calle">Calle</label>
                            <input class="form-control" type="text" name="calle" id="calle" #calle="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.calle" placeholder="Calle">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="numExt">Número exterior</label>
                            <input class="form-control" type="text" name="numExt" id="numExt" #numExt="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.numExt"
                                placeholder="Número exterior">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="numInt">Número interior</label>
                            <input class="form-control" type="text" name="numInt" id="numInt" #numInt="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.numInt"
                                placeholder="Número interior">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="cp">C.P.</label>
                            <input class="form-control" type="text" name="cp" id="cp" #cp="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.cp" placeholder="Código postal">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="colonia">Colonia</label>
                            <input class="form-control" type="text" name="colonia" id="colonia" #colonia="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.colonia" placeholder="Colonia">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label for="noHabitantes">No. habitantes</label>
                            <input class="form-control" type="text" name="noHabitantes" id="noHabitantes"
                                #noHabitantes="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.noHabitantes"
                                placeholder="No. habitantes">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="checkpoint">Asociar punto de control</label>
                            <ng-multiselect-dropdown name="checkpoint" #checkpoint="ngModel" [placeholder]="'Puntos de control'" [settings]="dropdownSettings"
                                [data]="checkpointService.checkpoints" [(ngModel)]="userAddressService.selectedUserAddress.checkpoint" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)" >
                            </ng-multiselect-dropdown>

                            <!-- <select multiple [compareWith]="compareFn" class="form-control" name="checkpoint"
                                id="checkpoint" #checkpoint="ngModel"
                                [(ngModel)]="userAddressService.selectedUserAddress.checkpoint">
                                <option *ngFor="let checkpoint of checkpointService.checkpoints" [value]="checkpoint">
                                    <input type="checkbox" name="checkpoint" >{{checkpoint.nombre}} 
                                </option>
                            </select> -->
                        </div>
                    </div>

                </div>



                <!-- <div class="form-group">
                    <label for="areaid">Licencia</label>
                    <select class="form-control" name="licenciaId" id="licenciaId" #licenciaId="ngModel"
                        [(ngModel)]="userAddressService.selectedUserAddress.licenciaId">
                        <option *ngFor="let address of licenciaService.addresss" [value]="address._id">
                            {{ address.addressname }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="status">Número de la licencia</label>
                    <input class="form-control" type="text" name="numero" id="numero" #numero="ngModel"
                        [(ngModel)]="userAddressService.selectedUserAddress.numero" placeholder="Número de licencia">
                </div>
                <div class="form-group">
                    <label for="surname">Vigencia</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                            #d="ngbDatepicker" [minDate]="minDate" autocomplete="off" readonly>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-day-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-4.785-6.145a.425.425 0 0 1-.43-.425c0-.242.192-.43.43-.43a.428.428 0 1 1 0 .855zm.336.563v4.105h-.672V8.418h.672zm-6.867 4.105v-2.3h2.261v-.61H4.684V7.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V9.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="status">Estatus</label>
                    <select class="form-control" name="status" id="status" #status="ngModel"
                        [(ngModel)]="userAddressService.selectedUserAddress.status">
                        <option [value]=1>ACTIVO</option>
                        <option [value]=0>INACTIVO</option>
                    </select>
                </div> -->
                <div class="form-group">
                    <button class="btn btn-success">Save</button>
                </div>
            </form>
        </div>
    </div>

</div>