import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cajero } from 'src/app/models/cajero';
import { DetalleIncidencia, Incidencia } from 'src/app/models/incidencia';
import { NivelServicio } from 'src/app/models/nivelservicio';
import { Servicio } from 'src/app/models/servicio';
import { StatusIncidente } from 'src/app/models/statusincidente';
import { OrigenIncidencia, TipoIncidencia } from 'src/app/models/tipoincidencia';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';
import { ValeCaja } from 'src/app/models/valecaja';
import { CajeroService } from 'src/app/services/cajero.service';
import { ExcelService } from 'src/app/services/excel.service';
import { IncidenciaService } from 'src/app/services/incidencia.service';
import { NivelServicioService } from 'src/app/services/nivelservicio.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { StatusIncidenteService } from 'src/app/services/statusincidente.service';
import { TipoIncidenciaService } from 'src/app/services/tipoincidencia.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserService } from 'src/app/services/user.service';
import { ValeCajaService } from 'src/app/services/valecaja.service';
import * as moment from 'moment';

@Component({
  selector: 'app-monitoreo-inicencias',
  templateUrl: './monitoreo-inicencias.component.html',
  styleUrls: ['./monitoreo-inicencias.component.css'],
  providers:[DatePipe]
})
export class MonitoreoInicenciasComponent implements OnInit {
  page_title = 'Incidencias';
  activeModal: NgbModalRef;
  activeModalVale: NgbModalRef;
  activeModalFecha: NgbModalRef;
  page = 1;
  pageSize = 10;
  filter = "";
  public selectedCajero: number=0;
  public selectedCajeroObj: Cajero=new Cajero();
  public selectedTecnico:User;
  filteredIncidencias:Incidencia[]=[];
  isDisabled = false;  
  myForm:FormGroup;
  myDialogForm:FormGroup;
  myDialogFormVale:FormGroup;
  myDialogFormFecha:FormGroup;
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public selectedFiltroCajero: number=0;
  public selectedFiltroTecnico:User=new User();
  public selectedFiltroUsuario:User=new User();
  public selectedFiltroTipoIncidente:TipoIncidencia=new TipoIncidencia();
  public selectedFiltroStatusIncidente:StatusIncidente=new StatusIncidente();
  activeIncidentes:boolean=false;
  addIncidentes:boolean=false;
  addvalecaja:boolean=false;
  constructor(public modal: NgbModal,public modalVale: NgbModal,public modalFecha:NgbModal,public incidenciaService:IncidenciaService,public cajeroService:CajeroService,public tipoIncidenciaService:TipoIncidenciaService,public userService:UserService,public toastService: ToastService,public nivelServicioService: NivelServicioService,public statusIncidenteService: StatusIncidenteService,public datepipe: DatePipe,private excelService:ExcelService,private valeCajaService:ValeCajaService,private servicioService:ServicioService) { }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'cajero': new FormControl(""),
      'tecnico': new FormControl(""),
      'usuarios':new FormControl(""),
      'tipoincidencia': new FormControl(""),
      'statusincidente':new FormControl("")
    });
    this.getIncidencias();
    this.getCajeros();
    this.getTiposIncidencia();
    this.getOrigenIncidencia();
    this.getTecnicos();
    this.getUsers();
    this.getNivelesServicio();
    this.getStatusIncidente();
    this.getServicios();
    this.incidenciaService.selectedIncidencia=new Incidencia();
    this.incidenciaService.selectedDetalleIncidencia=new DetalleIncidencia();
    this.incidenciaService.selectedOrigenIncidencia=new OrigenIncidencia();
    this.nivelServicioService.selectedNivelServicio=new NivelServicio();
    this.statusIncidenteService.selectedStatusIncidente=new StatusIncidente();
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    this.activeIncidentes=(user&&(user.category.name=='ADMINISTRADOR'||user.category.name=="TECNICO"))
    this.addIncidentes=(user&&(user.category.name=='ADMINISTRADOR'||user.category.name=="TECNICO"||user.insertar==1))
    this.addvalecaja=(user&&(user.category.name=='ADMINISTRADOR'))

  }
  getIncidencias():void{
    
    let tecnico:number;
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    if(userData.category.name=='TECNICO'){
      tecnico=Number(userData._id);
    }else{
      tecnico=this.selectedFiltroTecnico.id_usuario;
    }
    this.incidenciaService.getIncidencias(this.selectedFiltroTipoIncidente,this.selectedFiltroCajero,tecnico,this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'),this.selectedFiltroUsuario.id_usuario,this.selectedFiltroStatusIncidente.id_statusincidencia)
    .subscribe((res: any) => {
      this.incidenciaService.incidencias = res.datos as Incidencia[];
      if(userData.category.name=='MONITOREO'){
        this.incidenciaService.incidencias=this.incidenciaService.incidencias.filter((val) => {
          let rVal = (val.id_tipoincidencia!=16);
          return rVal;
        });
      }

      this.filteredIncidencias=this.incidenciaService.incidencias;

    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
          index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
      if(userData.category.name=='TECNICO'){
        this.cajeroService.cajeros=this.cajeroService.cajeros.filter((val) => {
          let rVal = (val.id_usuario.toString()==userData._id);
          return rVal;
        })
      }
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getTiposIncidencia():void{
    this.tipoIncidenciaService.getTipoIncidencia()
    .subscribe((res: any) => {
      this.tipoIncidenciaService.allTipoIncidencia=res.datos as TipoIncidencia[]
      this.tipoIncidenciaService.tiposincidencia= this.tipoIncidenciaService.allTipoIncidencia.filter((val)=>{
        let rval=(val.status==1);
        return rval;
      });
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getOrigenIncidencia():void{
    this.tipoIncidenciaService.getOrigenIncidencia()
    .subscribe((res: any) => {
      this.tipoIncidenciaService.origenincidencia= res.datos as OrigenIncidencia[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getNivelesServicio():void{
    this.nivelServicioService.getNivelesServicio()
    .subscribe((res: any) => {
      this.nivelServicioService.nivelesServicio= res.datos as NivelServicio[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getStatusIncidente():void{
    this.statusIncidenteService.getStatusIncidente()
    .subscribe((res: any) => {
      this.statusIncidenteService.statusIncidentes= res.datos as StatusIncidente[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getValesCaja(): void {
    this.valeCajaService.getValeCaja()
      .subscribe((res: any) => {
        this.valeCajaService.valesCaja = res.datos as any[];
        let date:Date=new Date();
        let header:string[]=["Cajero","Fecha y Hora","Importe Total Pagado","Cantidad No Devuelta","Contribuyente","Descripción Del Servicio","Referencia Pago","Folio Control","Técnico","Status"]
        let data:any[]=this.jsontoArrValeCaja();
        this.excelService.generateExcel(header,data,"Vales de Caja","A1:I1",'Vales de Caja'+this.datepipe.transform(date, 'ddMM')+'.xlsx');
  
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
      this.servicioService.servicios=this.servicioService.servicios.filter((val) => {
        let rVal = (val.padre==0) 
        return rVal;
      });
  
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getDetalleIncidencia(idIncidencia:string):void{
    this.incidenciaService.getDetalleIncidencia(idIncidencia)
    .subscribe((res: any) => {
      this.incidenciaService.detalles = res.datos as DetalleIncidencia[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  openModal(content) {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    let timeformat=this.datepipe.transform(date, 'HH:mm')
    this.incidenciaService.selectedIncidencia.fecha_ticketd=date;
//    this.incidenciaService.selectedIncidencia.hora_tickett={hours:date.getHours(),minutes:date.getMinutes()};
    this.incidenciaService.selectedIncidencia.hora_tickett=timeformat;
      this.myDialogForm = new FormGroup({
        'cajeros': new FormControl(""),
        'usuariofinal': new FormControl(""),
        'telusuariofinal': new FormControl(""),
        'fecha_ticket': new FormControl(dateformat),
        'hora_ticket': new FormControl(timeformat),
        'tipoincidencia': new FormControl(""),
        'origenincidencia': new FormControl(""),
        'folio_Control': new FormControl(""),
        'statusincidente': new FormControl(this.statusIncidenteService.selectedStatusIncidente),
        'nivelservicio': new FormControl(this.nivelServicioService.selectedNivelServicio),
        'vobo': new FormControl(this.incidenciaService.selectedIncidencia.visto_bueno),
        'Observaciones': new FormControl(""),
      });
  

    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    }).catch(() => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    });
  }
  closeModal(){
    this.incidenciaService.selectedIncidencia = new Incidencia();
    this.incidenciaService.selectedTipoIncidencia=new TipoIncidencia();
    this.incidenciaService.selectedOrigenIncidencia=new OrigenIncidencia();
    this.nivelServicioService.selectedNivelServicio=new NivelServicio();
    this.selectedCajero=0;
    this.selectedTecnico=new User();
    this.isDisabled=false;
    this.activeModal.close();
    // this.getCajeros();
    
  }
  getUsers(): void {
    this.userService.getUsers()
      .subscribe((res: any) => {
        this.userService.users = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }

  addIncidencia(form?: NgForm) {

    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    this.incidenciaService.selectedIncidencia.id_tipoincidencia=this.incidenciaService.selectedTipoIncidencia.id_tipoincidencia;
    this.incidenciaService.selectedIncidencia.num_cajero=Number(this.selectedCajero);
    this.incidenciaService.selectedIncidencia.id_usuario=Number(userData._id);
    this.incidenciaService.selectedIncidencia.id_proyecto=userData.id_proyecto;
    if (this.incidenciaService.selectedIncidencia.id_incidencia>0) {
      if(this.statusIncidenteService.selectedStatusIncidente.id_statusincidencia==3)
        this.incidenciaService.selectedIncidencia.id_origenincidencia=this.incidenciaService.selectedOrigenIncidencia.id_origenincidencia;
      this.incidenciaService.selectedIncidencia.id_nivelservicio=this.nivelServicioService.selectedNivelServicio.id_nivelservicio;
      this.incidenciaService.selectedIncidencia.id_statusincidencia=this.statusIncidenteService.selectedStatusIncidente.id_statusincidencia;//Pendiente
      this.incidenciaService.putIncidencia(this.incidenciaService.selectedIncidencia)
      .subscribe((res: any) => {
        if (res.codigo==200) {
          this.resetForm(form);
          this.toastService.show('Incidente Actualizado',{classname: 'bg-primary text-white',headertext: 'Información'});
          this.getIncidencias();
          this.activeModal.close();
        }
        else {
          this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
        }
      }, (error: HttpErrorResponse) => {
        this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
      });
  }else{
        this.incidenciaService.selectedIncidencia.id_statusincidencia=1;//Pendiente
        this.incidenciaService.selectedIncidencia.id_nivelservicio=3;
        this.incidenciaService.selectedIncidencia.usuario_final=this.incidenciaService.selectedIncidencia.usuario_final.toUpperCase();
        this.incidenciaService.selectedIncidencia.fecha_ticket=this.datepipe.transform(this.incidenciaService.selectedIncidencia.fecha_ticketd, 'yyyy-MM-dd')
        this.incidenciaService.selectedIncidencia.hora_ticket=this.incidenciaService.selectedIncidencia.hora_tickett;//this.datepipe.transform(this.incidenciaService.selectedIncidencia.hora_tickett,'HH:mm')//.hours?(this.incidenciaService.selectedIncidencia.hora_tickett.hours.toString().padStart(2,'0')+":"+this.incidenciaService.selectedIncidencia.hora_tickett.minutes):this.incidenciaService.selectedIncidencia.hora_tickett.hours+":"+this.incidenciaService.selectedIncidencia.hora_tickett.minutes;
        this.incidenciaService.selectedIncidencia.comentarios=this.incidenciaService.selectedIncidencia.comentarios.toUpperCase();
        this.incidenciaService.postIncidencia(this.incidenciaService.selectedIncidencia)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.resetForm(form);
            this.toastService.show('Incidente Añadido',{classname: 'bg-primary text-white',headertext: 'Información'});
            this.getIncidencias();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
        });
    }
  }
  addValeCaja(form?: NgForm) {
    this.valeCajaService.selectedValeCaja.fecha_hora_vale=this.datepipe.transform(this.valeCajaService.selectedValeCaja.fecha_ticketd, 'yyyy-MM-dd')
    this.valeCajaService.selectedValeCaja.fecha_hora_vale+='T'+this.valeCajaService.selectedValeCaja.hora_tickett+":00"
    this.valeCajaService.selectedValeCaja.id_servicio=this.servicioService.selectedServicio.id_servicio;
    this.valeCajaService.postValeCaja(this.valeCajaService.selectedValeCaja)
    .subscribe((res: any) => {
      if (res.codigo==200) {
        this.resetForm(form);
        this.toastService.show('Vale de caja añadido',{classname: 'bg-primary text-white',headertext: 'Información'});
        this.getIncidencias();
        this.activeModalVale.close();
      }
      else {
        this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
      }
    }, (error: HttpErrorResponse) => {
      this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
    });
  }
  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.incidenciaService.selectedIncidencia = new Incidencia();
      this.incidenciaService.selectedTipoIncidencia=new TipoIncidencia();
      this.incidenciaService.selectedOrigenIncidencia=new OrigenIncidencia();
      this.nivelServicioService.selectedNivelServicio=new NivelServicio();
      this.selectedCajero=0;
      this.selectedTecnico=new User();
      this.isDisabled=false;
    }
    // this.getCajeros();
  }
  editIncidencia(incidencia:Incidencia,content)
  {
    this.isDisabled=true;
    this.getDetalleIncidencia(incidencia.id_incidencia.toString());
    this.incidenciaService.selectedIncidencia = incidencia;
    this.selectedCajero=this.cajeroService.cajeros.find(e=>e.numero==this.incidenciaService.selectedIncidencia.num_cajero).numero;
    this.selectedTecnico=this.userService.tecnicos.find(e=>e.id_usuario==this.incidenciaService.selectedIncidencia.id_usuario);
    this.incidenciaService.selectedTipoIncidencia=this.tipoIncidenciaService.allTipoIncidencia.find(e=>e.id_tipoincidencia==this.incidenciaService.selectedIncidencia.id_tipoincidencia);
    this.incidenciaService.selectedOrigenIncidencia=this.tipoIncidenciaService.origenincidencia.find(e=>e.id_origenincidencia==this.incidenciaService.selectedIncidencia.id_origenincidencia);
    this.statusIncidenteService.selectedStatusIncidente=this.statusIncidenteService.statusIncidentes.find(e=>e.id_statusincidencia==this.incidenciaService.selectedIncidencia.id_statusincidencia);
    this.nivelServicioService.selectedNivelServicio=this.nivelServicioService.nivelesServicio.find(e=>e.id_nivelservicio==this.incidenciaService.selectedIncidencia.id_nivelservicio);
    this.openModal(content);

    
  }
  filterChange(e){
    console.log(e)
    e=e.toLocaleLowerCase();
    this.filteredIncidencias=this.incidenciaService.incidencias.filter((val) => {
      let rVal = (val.num_cajero.toString().includes(e)) 
      || (val.reporte.toLocaleLowerCase().includes(e))
      || (val.ubicacionfisica.toLocaleLowerCase().includes(e))
      || (val.fecha.toString().includes(e))
      || (val.tecnico.toLocaleLowerCase().includes(e))
      || (val.folio_control?.toLocaleLowerCase().includes(e))
      || (val.tipo_incidencia.toLocaleLowerCase().includes(e))
      || (val.status_incidencia.toLocaleLowerCase().includes(e))
      return rVal;
    })
  }
  exportExcel(){
    {
      let date:Date=new Date();
      let header:string[]=["Reporte","Cajero","Ubicación","Fecha de Reporte","Fecha de Solución","Técnico asignado","Incidencia","Status","Fecha de Incidencia","Numero de operación","Visto Bueno","Nivel de Servicio"]
      let data:any[]=this.jsontoArr();
      this.excelService.generateExcel(header,data,"Monitoreo de Incidencias","A1:M1",'MonitoreoIncidencias'+this.datepipe.transform(date, 'ddMM')+'.xlsx');
    }
  }

  jsontoArr():any[]
  {
    let data:any[]=[];
    for(var i in this.incidenciaService.incidencias) 
    {
      let row:any[]=[this.incidenciaService.incidencias[i].reporte,this.incidenciaService.incidencias[i].num_cajero,this.incidenciaService.incidencias[i].ubicacionfisica,this.incidenciaService.incidencias[i].sfecha_ticket.substring(0,10)+" "+this.incidenciaService.incidencias[i].shora_ticket.substring(11,19),this.incidenciaService.incidencias[i].fecharesuelto?this.incidenciaService.incidencias[i].fecharesuelto.substring(0,10):""+" "+this.incidenciaService.incidencias[i].hora_resuelto?this.datepipe.transform(this.incidenciaService.incidencias[i].hora_resuelto, 'HH:mm:ss'):"",
      this.incidenciaService.incidencias[i].tecnico,this.incidenciaService.incidencias[i].tipo_incidencia,this.incidenciaService.incidencias[i].status_incidencia,this.incidenciaService.incidencias[i].fecha_ticket.substring(0,10)+" "+this.incidenciaService.incidencias[i].hora_ticket.substring(11,19),this.incidenciaService.incidencias[i].folio_control,this.incidenciaService.incidencias[i].visto_bueno,this.incidenciaService.incidencias[i].nivel_servicio];

      // let row:any[]=[this.incidenciaService.incidencias[i].reporte,this.incidenciaService.incidencias[i].num_cajero,this.incidenciaService.incidencias[i].ubicacionfisica,this.incidenciaService.incidencias[i].sfecha_ticket.substring(0,10)+" "+this.incidenciaService.incidencias[i].shora_ticket.substring(11,19),(this.incidenciaService.incidencias[i].fecha?this.incidenciaService.incidencias[i].fecha.substring(0,10):"")+" "+(this.incidenciaService.incidencias[i].hora?this.datepipe.transform(this.incidenciaService.incidencias[i].hora, 'HH:mm:ss'):""),(this.incidenciaService.incidencias[i].fecharesuelto?this.incidenciaService.incidencias[i].fecharesuelto.substring(0,10):"")+" "+(this.incidenciaService.incidencias[i].hora_resuelto?this.datepipe.transform(this.incidenciaService.incidencias[i].hora_resuelto, 'HH:mm:ss'):""),
      // ((this.incidenciaService.incidencias[i].diferencia%1440)/60).toString().split('.')[0]+ ' horas ' +this.incidenciaService.incidencias[i].diferencia%60+' minutos',
      // this.incidenciaService.incidencias[i].tecnico,this.incidenciaService.incidencias[i].tipo_incidencia,this.incidenciaService.incidencias[i].status_incidencia,this.incidenciaService.incidencias[i].fecha_ticket.substring(0,10)+" "+this.incidenciaService.incidencias[i].hora_ticket.substring(11,19),this.incidenciaService.incidencias[i].folio_control,this.incidenciaService.incidencias[i].visto_bueno,this.incidenciaService.incidencias[i].nivel_servicio];

      data.push(row);
    }
    return data;
  
  }
  exportValeCaja(){
    {
      this.getValesCaja();
    }
  }

  jsontoArrValeCaja():any[]
  {
    let data:any[]=[];
    for(var i in this.valeCajaService.valesCaja) 
    {
      let row:any[]=[
        this.valeCajaService.valesCaja[i].num_cajero,
        this.valeCajaService.valesCaja[i].fecha_hora_vale.replace('T',' ').replace('Z',''),
        this.valeCajaService.valesCaja[i].importe_total_pagado,
        this.valeCajaService.valesCaja[i].cantidad_nodevuelta,
        this.valeCajaService.valesCaja[i].contribuyente,
        this.valeCajaService.valesCaja[i].desc_servicio,
        this.valeCajaService.valesCaja[i].referencia_pago,
        this.valeCajaService.valesCaja[i].folio_control,
        this.valeCajaService.valesCaja[i].tecnico,
        this.valeCajaService.valesCaja[i].statusincidencia
      ];
      data.push(row);
    }
    return data;
  
  }
  onChangeCajero(){
    this.selectedCajeroObj=this.cajeroService.cajeros.find(e=>e.numero==Number(this.selectedCajero))
  }

  openModalVale(content) {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    let timeformat=this.datepipe.transform(date, 'HH:mm')
    this.valeCajaService.selectedValeCaja.fecha_ticketd=date;
//    this.incidenciaService.selectedIncidencia.hora_tickett={hours:date.getHours(),minutes:date.getMinutes()};
    this.valeCajaService.selectedValeCaja.hora_tickett=timeformat;
      this.myDialogFormVale = new FormGroup({
        'cajeros': new FormControl(""),
        'usuariofinal': new FormControl(""),
        'telusuariofinal': new FormControl(""),
        'folio_control': new FormControl(""),
        'fecha_ticket': new FormControl(dateformat),
        'hora_ticket': new FormControl(timeformat),
        'servicio':new FormControl(0),
        'total_operacion': new FormControl(0),
        'cantidad_recibida': new FormControl(0),
        'cantidad_devuelta': new FormControl(0),
        'cantidad_nodevuelta': new FormControl(0),
      });
  

    this.activeModalVale = this.modalVale.open(content, { size: 'lg' });
    this.activeModalVale.result.then(res => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    }).catch(() => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    });
  }
  openModalFecha(incidencia:Incidencia,content) {

    this.incidenciaService.selectedIncidencia=incidencia;
    const date = moment(this.incidenciaService.selectedIncidencia.fecha_resuelto.substring(0,10));
    const time = moment(this.incidenciaService.selectedIncidencia.hora_resuelto.substring(0,19));

    let dateformat=this.datepipe.transform(date.toDate(), 'yyyy-MM-dd')
    let timeformat=this.datepipe.transform(time.toDate(), 'HH:mm')

    this.incidenciaService.selectedIncidencia.fecha_resueltod=date.toDate();
    this.incidenciaService.selectedIncidencia.hora_resueltot=timeformat;
    console.log(this.incidenciaService.selectedIncidencia);
    this.myDialogFormFecha = new FormGroup({
        'fecha_resueltod': new FormControl(dateformat),
        'hora_resueltot': new FormControl(timeformat),
      });
    this.activeModalFecha = this.modalFecha.open(content, { size: 'lg' });
    this.activeModalFecha.result.then(res => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    }).catch(() => {
      this.incidenciaService.selectedIncidencia = new Incidencia();
    });
  }
  closeModalVale(){
    this.valeCajaService.selectedValeCaja=new ValeCaja();
    this.servicioService.selectedServicio=new Servicio();
    this.selectedCajero=0;
    this.activeModalVale.close();
    // this.getCajeros();
    
  }
  closeModalFecha(){
    this.activeModalFecha.close();
  }

  editFechaIncidente(form?: NgForm) {
    
    const fecha_ticket = moment(this.incidenciaService.selectedIncidencia.fecha_ticket.substring(0,10));
    const fecha_resuelto=moment(this.incidenciaService.selectedIncidencia.fecha_resueltod);

    const hora_ticket = moment(this.incidenciaService.selectedIncidencia.hora_ticket.substring(0,19));
    const hora_resuelto=moment(this.incidenciaService.selectedIncidencia.hora_ticket.substring(0,11)+this.incidenciaService.selectedIncidencia.hora_resueltot+this.incidenciaService.selectedIncidencia.hora_ticket.substring(16,19));

    if(fecha_resuelto.toDate()>fecha_ticket.toDate()){
      this.toastService.show('La fecha de solucion es distinta a la fecha del incidente.',{classname: 'bg-primary text-white',headertext: 'Información'});
      return;
    }
    if(fecha_resuelto.toDate()<fecha_ticket.toDate()){
      this.toastService.show('La fecha de solucion no puede ser menor a la fecha del incidente.',{classname: 'bg-primary text-white',headertext: 'Información'});
      return;
    }
    if(hora_resuelto<hora_ticket){
      this.toastService.show('La hora de solucion no puede ser menor a la hora del incidente.',{classname: 'bg-primary text-white',headertext: 'Información'});
      return;
    }
    if((hora_resuelto.toDate().getTime()-hora_ticket.toDate().getTime())>14400000)
    {
      this.toastService.show('La hora de solucion no puede superar las 4 horas.',{classname: 'bg-primary text-white',headertext: 'Información'});
      return;
    }
    let dateformat=this.datepipe.transform(fecha_resuelto.toDate(), 'yyyy-MM-dd')
    let timeformat=this.datepipe.transform(hora_resuelto.toDate(), 'HH:mm')

    this.incidenciaService.putFecha(this.incidenciaService.selectedIncidencia,dateformat,timeformat)
    .subscribe((res: any) => {
      if (res.codigo==200) {
        this.resetForm(form);
        this.toastService.show('Incidente Actualizado',{classname: 'bg-primary text-white',headertext: 'Información'});
        this.getIncidencias();
        this.activeModal.close();
      }
      else {
        this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
      }
    }, (error: HttpErrorResponse) => {
      this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
    });

    this.activeModalFecha.close();
  }

}
