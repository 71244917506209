import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Inmueble } from 'src/app/models/inmueble';
import { User } from 'src/app/models/user';
import { InmuebleService } from 'src/app/services/inmueble.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserAddressService } from 'src/app/services/user-address.service';
import { CheckpointService } from 'src/app/services/checkpoint.service';
import { Checkpoint } from 'src/app/models/checkpoint';
import { UserAddress } from 'src/app/models/user-address';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.css']
})
export class UserAddressComponent implements OnInit {
  page_title = 'Domicilio del usuario';
  @Input() public username: string;
  @Input() public _id: string;
  @Input() public perfil: string;
  public dropdownSettings: IDropdownSettings = {};

  constructor(
    public activeModal: NgbActiveModal,
    public inmuebleService: InmuebleService,
    private toast: ToastService,
    public userAddressService: UserAddressService,
    public checkpointService: CheckpointService
  ) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'nombre',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    }
    this.getUserAddress();
    this.getInmuebles();
    this.getCheckpoints();
  }

  getUserAddress() {
    this.userAddressService.getUserAddress(this._id)
      .subscribe((res: any) => {
        if (!res.message) {
          this.userAddressService.selectedUserAddress = res.UserAddress as UserAddress;
        }
        else {
          this.userAddressService.selectedUserAddress = new UserAddress();
        }
      }, (error: HttpErrorResponse) => {
        this.toast.show(error.message);
        this.userAddressService.selectedUserAddress = new UserAddress();
      });
  }

  getInmuebles() {
    this.inmuebleService.getInmuebles()
      .subscribe((res: any) => {
        this.inmuebleService.inmuebles = res.Property as Inmueble[];
      }, (error: HttpErrorResponse) => {
        this.toast.show(error.message);
      });
  }

  getCheckpoints() {
    this.checkpointService.getCheckpoints()
      .subscribe((res: any) => {
        this.checkpointService.checkpoints = res.Checkpoint as Checkpoint[];
      }, (error: HttpErrorResponse) => {
        this.toast.show(error.message);
      });
  }

  addAddress(form?: NgForm) {
    form.value.idUsuario = this._id;
    if (form.value._id) {
      this.userAddressService.putUserAddress(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Domicilio actualizado');
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
    else {
      this.userAddressService.postUserAddress(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Usuario creado');
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }

        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }

  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.userAddressService.selectedUserAddress = new UserAddress();
    }
  }

  compareFn(ua1: UserAddress, ua2: UserAddress): boolean {
    return ua1 && ua2 ? ua1._id === ua2._id : ua1 === ua2;
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

}
