import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, PipeTransform  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/services/event.service';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [DecimalPipe]

})
export class EventComponent implements OnInit {
  page_title = 'Tus eventos';
  public tipoInvitado: number = 0;
  page = 1;
  pageSize = 5;
  collectionSize = 0;
  filter = new FormControl('');
  constructor(public eventService: EventService, private modal: NgbModal, pipe: DecimalPipe) {

  }

  ngOnInit(): void {
    this.getEvents();
  }

  getEvents(): void {
    this.eventService.getEvents()
      .subscribe((res: any) => {
        this.eventService.events = res.Event as any[];
        // this.eventService.massiveEvents = res.Event.filter(x => x.idTipoInvitado == 0) as MassiveEvent[];
        // this.eventService.personalEvents = res.Event.filter(x => x.idTipoInvitado == 1) as PersonalEvent[];
        // this.eventService.providerEvents = res.Event.filter(x => x.idTipoInvitado == 2) as ProviderEvent[];
        // this.eventService.employeeEvents = res.Event.filter(x => x.idTipoInvitado == 3) as EmployeeEvent[];
      }, (err: HttpErrorResponse) => {
        console.log(err);
      })
  }

  showResult(optionSelected) {
    this.tipoInvitado = optionSelected as number;
  }

  selectEvent(event: any) {
    this.eventService.selectedEvent = event;
  }

  viewEventDetail(event: any): void {
    const modalRef = this.modal.open(EventDetailComponent, { size: 'lg', backdrop: true, keyboard: false });
    modalRef.componentInstance.event = event;
  }

  downloadExcel(): void {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Eventos");

    worksheet.addRow(["Evento / Visita", "Inicio", "Fin"]);
    this.eventService.events.forEach(evento => {
      let fechaInicio = evento.fechaInicio + ' ' + evento.horaInicio.split(' ')[1].split('.')[0];
      let fechaFin = evento.fechaFin + ' ' + evento.horaFin.split(' ')[1].split('.')[0];
      if (evento.nombreEvento !== undefined) {
        worksheet.addRow([evento.nombreEvento, fechaInicio, fechaFin]);
      }
      if (evento.nombreProveedor !== undefined) {
        worksheet.addRow([evento.nombreProveedor, fechaInicio, fechaFin]);
      }
      if (evento.nombreEmpleado !== undefined) {
        worksheet.addRow([evento.nombreEmpleado, fechaInicio, fechaFin]);
      }
      
    });
    let fname = "Eventos";
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });

  }

}
