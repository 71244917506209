import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Checkpoint } from '../models/checkpoint';

@Injectable({
  providedIn: 'root'
})
export class CheckpointService {
  selectedCheckpoint: Checkpoint;
  checkpoints: Checkpoint[];

  constructor(private http: HttpClient) { 
    this.selectedCheckpoint = new Checkpoint();
    this.checkpoints = [];
  }

  getCheckpoints()
  {
    return this.http.get(Global.URL_API + 'checkpoint/getCheckpoint', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    })
  }

  postCheckpoint(checkpoint: Checkpoint) {
    return this.http.post(Global.URL_API + 'checkpoint/saveCheckpoint', checkpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  putCheckpoint(checkpoint: Checkpoint) {
    return this.http.put(Global.URL_API + 'checkpoint/Checkpoint' + `/${checkpoint._id}`, checkpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  deleteCheckpoint(_id: string) {
    return this.http.delete(Global.URL_API + 'checkpoint/Checkpoint' + `/${_id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
