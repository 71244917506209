import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toastservice.service';
import { TipoInmuebleService } from 'src/app/services/tipo-inmueble.service';
import { TipoInmueble } from 'src/app/models/tipo-inmueble';
import { UserData } from 'src/app/models/user-data';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-admin-inmuebles',
  templateUrl: './admin-inmuebles.component.html',
  styleUrls: ['./admin-inmuebles.component.css']
})
export class AdminInmueblesComponent implements OnInit {
  page_title: string = 'Gestión del fraccionamiento';
  activeModal: NgbModalRef;
  page: number = 1;
  pageSize: number = 5;
  userData: UserData = (<UserData>JSON.parse(localStorage.getItem('userData')));
  public privedifcalle = [
    { val: 'EDIFICIO', text: 'Edificio' },
    { val: 'PRIVADA', text: 'Privada' }
  ];

  constructor(public inmuebleService: TipoInmuebleService, public modal: NgbModal, public toast: ToastService) { }

  ngOnInit(): void {
    this.getData();
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.inmuebleService.selectedData = new TipoInmueble();
    }).catch(() => {
      this.inmuebleService.selectedData = new TipoInmueble();
    });
  }

  async addInmueble(form?: NgForm) {
    // let userData: UserData = (<UserData>JSON.parse(localStorage.getItem('userData')));
    if (this.userData.category.name == "Administrador")
    {
      form.value.padreId = this.userData._id;
    }
    else{
      this.toast.show("Función disponible solo para el administrador del lugar");
    }
      
    if (form.value._id) {
      console.log(form.value);
      try {
        let resp = await this.inmuebleService.putTipoInmueble(form.value).toPromise() as any;
        if (!resp.message) {
          await this.resetForm(form);
          this.toast.show('Inmueble actualizado');
          await this.getData();
          this.activeModal.close();
        }
        else {
          this.toast.show(resp.message);
        }
      }
      catch (err) {
        this.toast.show(err.message);
      }
    } else {
      console.log(form.value);
      try {
        let resp = await this.inmuebleService.postTipoInmueble(form.value).toPromise() as any;
        if (!resp.message) {
          this.resetForm(form);
          this.toast.show('Inmueble creado');
          await this.getData();
          this.activeModal.close();
        }
        else {
          this.toast.show(resp.message);
        }
      }
      catch (err) {
        this.toast.show(err.message);
      }
    }
  }

  editInmueble(inmueble: TipoInmueble, content) {
    this.inmuebleService.selectedData = inmueble;
    this.openModal(content);
  }

  deleteInmueble(_id: string) {
    // swal({
    //   title: "¿Estás seguro?",
    //   text: "¡Una vez eliminado, no podrás recuperar el registro!",
    //   icon: "warning",
    //   buttons: ['Conservar', 'Eliminar'],
    //   dangerMode: true,
    // })
    //   .then((willDelete) => {
    //     if (willDelete) {
    //       this.inmuebleService.deleteTipoInmueble(_id)
    //         .subscribe(res => {
    //           this.getData();
    //           swal("¡El registro se ha eliminado!", {
    //             icon: "success",
    //           });
    //         }, (error: HttpErrorResponse) => {
    //           this.toast.show(error.message);
    //         });
    //     }
    //   });
  }

  async getData() {
    try {
      let response = await this.inmuebleService.getTipoInmueble(this.userData._id).toPromise() as any;
      this.inmuebleService.data = response.PropertyType as TipoInmueble[];
    }
    catch (err) {
      console.log(err);
    }
  }

  async resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.inmuebleService.selectedData = new TipoInmueble();
    }
    await this.getData();
  }

}
