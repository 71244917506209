import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MainModule } from './main/main.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PositionsComponent } from './components/positions/positions.component';
import { ToastscontainerComponent } from './components/toastscontainer/toastscontainer.component';
import { UsersComponent } from './components/users/users.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { InmueblesComponent } from './components/inmuebles/inmuebles.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { UserAddressComponent } from './components/users/user-address/user-address.component';
import { CheckpointsComponent } from './components/checkpoints/checkpoints.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { RecoveryCodeComponent } from './components/recovery-code/recovery-code.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventComponent } from './components/event/event.component';
import { MonitoreoCajerosComponent } from './components/monitoreoCajeros/monitoreocajero.component';
import { EventDetailComponent } from './components/event/event-detail/event-detail.component';
import { ResidentesComponent } from './components/residentes/residentes.component';
import { AdminInmueblesComponent } from './components/inmuebles/admin-inmuebles/admin-inmuebles.component';
import { CajeroComponent } from './components/cajero/cajero.component';
import { ServicioComponent } from './components/servicio/servicio.component';
import { ServicioactivoComponent } from './components/servicioactivo/servicioactivo.component';
import { MonitoreoInicenciasComponent } from './components/monitoreo-inicencias/monitoreo-inicencias.component';
import { ReporteServiciosComponent } from './components/reporte-servicios/reporte-servicios.component';
import { ReporteServiciosFechaComponent} from './components/reporte-servicios-fecha/reporte-servicios-fecha.component';
import { ReporteDetalleServiciosComponent } from './components/reporte-detalle-servicios/reporte-detalle-servicios.component';
import { ReporteCompulsaComponent } from './components/reporte-compulsa/reporte-compulsa.component';
import { ReporteExcelComponent } from './components/reporte-excel/reporte-excel.component';
import { ReprocesarComponent } from './components/reprocesar/reprocesar.component';
import { ExcelService } from './services/excel.service';
import { TipoIncidenciaComponent } from './components/tipo-incidencia/tipo-incidencia.component';
import { AuditoriaComponent } from './components/auditoria/auditoria.component';
import { OrderBy } from './services/orderBy.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingComponent} from './components/loading/loading';
import { MonitoreoValesCajaComponent } from './components/monitoreo-valescaja/monitoreo-valescaja.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    PositionsComponent,
    ToastscontainerComponent,
    UsersComponent,
    NavbarComponent,
    SidebarComponent,
    InmueblesComponent,
    CategoriasComponent,
    UserAddressComponent,
    CheckpointsComponent,
    PasswordRecoveryComponent,
    RecoveryCodeComponent,
    DashboardComponent,
    EventComponent,
    MonitoreoCajerosComponent,
    EventDetailComponent,
    ResidentesComponent,
    AdminInmueblesComponent,
    CajeroComponent,
    ServicioComponent,
    ServicioactivoComponent,
    MonitoreoInicenciasComponent,
    ReporteServiciosComponent,
    ReporteServiciosFechaComponent,
    ReporteDetalleServiciosComponent,
    ReporteCompulsaComponent,
    ReporteExcelComponent,
    ReprocesarComponent,
    TipoIncidenciaComponent,
    AuditoriaComponent,
    OrderBy,
    LoadingComponent,
    MonitoreoValesCajaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MainModule,
    NgxCaptchaModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule
  ],
  providers: [ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
