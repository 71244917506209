<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    <app-sidebar></app-sidebar>
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
      <div class="card mt-4">
        <div class="card-header">
          <div class="form-group row">
              <div class="col-md-10">
                  <h2 class="card-title">{{page_title}}</h2>
              </div>
              <div class="col-md-2">
                  <button class="btn btn-success" (click)="openModal(content)">Agregar usuario</button>
              </div>
          </div>
        </div>
        <div class="card-body">
          <!-- <button class="btn btn-success" (click)="openModal(content)">Agregar usuario</button> -->
          <br>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 text-right">
                <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>
            </div>
          </div>

          <br>
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <th class="text-center">Usuario</th>
                <th class="text-center">Nombre</th>
                <th class="text-center">Registro</th>
                <th class="text-center">Celular</th>
                <th class="text-center">Email</th>
                <th class="text-center">Status</th>
                <th class="text-center">Opciones</th>
              </thead>
              <tbody>
                <tr *ngFor="let user of filteredUsers | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <td class="text-left">{{user.usuario}}</td>
                  <td class="text-left">{{user.nombres}} {{user.ap_paterno}}</td>
                  <td class="text-center">{{user.fecha_alta.substring(0,10)}}</td>
                  <td class="text-left">{{user.celular}}</td>
                  <td class="text-left">{{user.email}}</td>
                  <td class="text-center" *ngIf="user.status==1">Activo</td>
                  <td class="text-center" *ngIf="user.status==0">Inactivo</td>
                  <td class="text-center">
                    <a (click)="editUsuario(user, content)" placement="top" ngbTooltip="Editar">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </a>&nbsp;
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-8">
                <div *ngIf="filteredUsers.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredUsers.length}} registros</div>
                <div *ngIf="filteredUsers.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredUsers.length}} de un total de {{filteredUsers.length}} registros</div>
            </div>
            <div class="col-md-4" >
                <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="filteredUsers.length" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination> 

            </div>
        </div>
      </div>
      </div>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h5 class="modal-title">Información de usuario</h5>
          <button type="button" class="close" aria-label="Close" (click)="onReset()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="registerForm" #myForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Nombres</label>
                  <input  class="form-control"  type="text" formControlName="nombres"  name="nombres" id="nombres" [(ngModel)]="userService.selectedUser.nombres"  [ngClass]="{ 'is-invalid': submitted && f.nombres.errors }"   />
                  <div *ngIf="submitted && f.nombres.errors" class="invalid-feedback">
                      <div *ngIf="f.nombres.errors.required">Nombres es un campo requerido</div>
                  </div>
              </div>
                <div class="form-group col-md-4">
                    <label>Apellido Paterno</label>
                    <input type="text" formControlName="ap_paterno" class="form-control" name="ap_paterno" id="ap_paterno" [(ngModel)]="userService.selectedUser.ap_paterno" [ngClass]="{ 'is-invalid': submitted && f.ap_paterno.errors }" />
                    <div *ngIf="submitted && f.ap_paterno.errors" class="invalid-feedback">
                        <div *ngIf="f.ap_paterno.errors.required">Apellido paterno es un campo requerido</div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Apellido Materno</label>
                    <input type="text" formControlName="ap_materno" class="form-control" name="ap_materno" id="ap_materno" [(ngModel)]="userService.selectedUser.ap_materno"  />
                </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-6">
                <label>Correo Electrónico</label>
                <input type="text" formControlName="email" class="form-control" name="email" id="email" [(ngModel)]="userService.selectedUser.email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Correo electrónico es un campo requerido</div>
                    <div *ngIf="f.email.errors.email">La dirección de correo electrónico debe ser válida</div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Teléfono</label>
                <input type="text" formControlName="telefono" class="form-control" name="telefono" id="telefono" [(ngModel)]="userService.selectedUser.telefono"  />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Celular</label>
                <input type="text" formControlName="celular" class="form-control" name="celular" id="celular" [(ngModel)]="userService.selectedUser.celular" [ngClass]="{ 'is-invalid': submitted && f.celular.errors }" />
                <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                    <div *ngIf="f.celular.errors.required">Celular es un campo requerido</div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>NSS</label>
                <input type="text" formControlName="nss" class="form-control" name="nss" id="nss" [(ngModel)]="userService.selectedUser.nss"  />
              </div>
  
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Usuario</label>
                  <input type="text" formControlName="usuario" class="form-control" name="usuario" id="usuario" [(ngModel)]="userService.selectedUser.usuario" [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }" />
                  <div *ngIf="submitted && f.usuario.errors" class="invalid-feedback">
                      <div *ngIf="f.usuario.errors.required">Usuario es un campo requerido</div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Contraseña</label>
                    <input type="password" formControlName="password" class="form-control" name="contrasena" id="contrasena" [(ngModel)]="userService.selectedUser.contrasena" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Contraseña es un campo requerido</div>
                        <div *ngIf="f.password.errors.minlength">La contraseña debe ser de al menos 6 caracteres</div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Repetir Contraseña</label>
                    <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Es necesario que ingrese nuevamente la contraseña</div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch">La contraseña debe coincidir</div>
                    </div>
                </div>
            </div>
            <h5 class="card-title" >Permisos</h5>
            <hr >
            <div class="row">
              <div class="form-group col-md-4">
                <label>Vigencia</label>
                <input id="vigencia" type="date" class="form-control" formControlName="vigencia"  />
                            <!-- <input f type="date" formControlName="vigencia" class="form-control" name="vigencia" id="vigencia" [(ngModel)]="userService.selectedUser.vigencia" #vigencia="ngModel"  /> -->
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label for="status">Status</label>
                  <select formControlName="status" class="form-control" name="status" id="status"  [(ngModel)]="userService.selectedUser.status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                    <option [value]=1>ACTIVO</option>
                    <option [value]=0>INACTIVO</option>
                  </select>
                  <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">Status es un campo requerido</div>
                </div>
              </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="status">Perfil</label>
                  <select formControlName="perfil" class="form-control" name="perfil" id="perfil"  [(ngModel)]="perfilService.selectedPerfil" [ngClass]="{ 'is-invalid': submitted && f.perfil.errors }">
                    <option  *ngFor="let perfil of perfilService.perfiles" [ngValue]="perfil">
                      {{perfil?.name}}</option>
                  </select>
                  <div *ngIf="submitted && f.perfil.errors" class="invalid-feedback">
                    <div *ngIf="f.perfil.errors.required">Perfil es un campo requerido</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group text-center">
                    <label for="borrar">Borrar</label>
                    <div class="custom-control custom-switch form-group">
                        <input formControlName="borrar" type="checkbox" class="custom-control-input form-control" name="borrar" id="borrar" 
                        [(ngModel)]="userService.selectedUser.borrar">
                        <label class="custom-control-label" for="borrar" *ngIf="userService.selectedUser.borrar">Activo</label>
                        <label class="custom-control-label" for="borrar" *ngIf="!userService.selectedUser.borrar">Inactivo</label>
                    </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group text-center">
                    <label for="insertar">Insertar</label>
                    <div class="custom-control custom-switch form-group">
                        <input formControlName="insertar" type="checkbox" class="custom-control-input form-control" name="insertar" id="insertar" 
                        [(ngModel)]="userService.selectedUser.insertar">
                        <label class="custom-control-label" for="insertar" *ngIf="userService.selectedUser.insertar">Activo</label>
                        <label class="custom-control-label" for="insertar" *ngIf="!userService.selectedUser.insertar">Inactivo</label>
                    </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group text-center">
                    <label for="modificar">Modificar</label>
                    <div class="custom-control custom-switch form-group">
                        <input formControlName="modificar" type="checkbox" class="custom-control-input form-control" name="modificar" id="modificar" 
                        [(ngModel)]="userService.selectedUser.modificar">
                        <label class="custom-control-label" for="modificar" *ngIf="userService.selectedUser.modificar">Activo</label>
                        <label class="custom-control-label" for="modificar" *ngIf="!userService.selectedUser.modificar">Inactivo</label>
                    </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-lg-6 text-center">
                <div class="form-group text-center">
                  <label for="reporteemail">Reporte T+1</label>
                  <div class="custom-control custom-switch form-group">
                      <input formControlName="reporteemail" type="checkbox" class="custom-control-input form-control" name="reporteemail" id="reporteemail" 
                      [(ngModel)]="userService.selectedUser.reporteemail">
                      <label class="custom-control-label" for="reporteemail" *ngIf="userService.selectedUser.reporteemail">Activo</label>
                      <label class="custom-control-label" for="reporteemail" *ngIf="!userService.selectedUser.reporteemail">Inactivo</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 text-center">
                <div class="form-group text-center">
                    <label for="notificacion">Notificación</label>
                    <div class="custom-control custom-switch form-group">
                        <input formControlName="notificacion" type="checkbox" class="custom-control-input form-control" name="notificacion" id="notificacion" 
                        [(ngModel)]="userService.selectedUser.notificacion">
                        <label class="custom-control-label" for="notificacion" *ngIf="userService.selectedUser.notificacion">Activo</label>
                        <label class="custom-control-label" for="notificacion" *ngIf="!userService.selectedUser.notificacion">Inactivo</label>
                    </div>
                </div>
              </div>
            </div>
            <div class="text-right">
                <button class="btn btn-primary mr-1">Guardar</button>
                <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>

        </div>
      </ng-template>
      <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    </main>
  </div>
</div>