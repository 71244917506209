import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { User } from '../models/user';
import { Global } from '../config/config';
import { Login } from '../models/login';
import { Router } from '@angular/router';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  selectedUser: User;
  users: User[];
  tecnicos: User[];

  constructor(private http: HttpClient, private router: Router) {
    this.selectedUser = new User();
    this.users = [];
  }

  getUsers(perfil?:string) {
    if(!perfil){
      perfil='all'
    }
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.get(Global.URL_API + 'usuarios'+ `/${perfil}` ,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  postUser(user: User) {
    console.log(user)
    return this.http.post(Global.URL_API + 'usuario', user,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  login(user: Login) {
    return this.http.post(Global.URL_API + 'login', user);
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  putUser(user: User) {
    
    return this.http.put(Global.URL_API + 'usuario' + `/${user.id_usuario}`, user, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  deleteUser(_id: string) {
    return this.http.delete(Global.URL_API + 'user/delete-user' + `/${_id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  postFile(fileToUpload: String, _id: string) {
    return this.http.post(Global.URL_API + 'user/upload-image' + `/${_id}`, { image: fileToUpload }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  getEvent(_id: string) {
    return this.http.get(Global.URL_API + 'event/QR' + `/${_id}`);
  }
}
