<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    <app-sidebar></app-sidebar>
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
      <div class="card mt-4">
        <div class="card-header">
          <div class="form-group row">
              <div class="col-md-10">
                  <h2 class="card-title">{{page_title}}</h2>
              </div>
              <div class="col-md-2">
                  <button class="btn btn-success" (click)="openModal(content)">Agregar Tipo de Incidencia</button>
              </div>
          </div>
        </div>
        <div class="card-body">
          <!-- <button class="btn btn-success" (click)="openModal(content)">Agregar usuario</button> -->
          <br>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 text-right">
                <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>
            </div>
          </div>

          <br>
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <th class="text-center">Tipo Incidente</th>
                <th class="text-center">Nombre</th>
                <th class="text-center">Status</th>
                <th class="text-center">Opciones</th>
              </thead>
              <tbody>
                <tr *ngFor="let tipoincidencia of filteredtipoincidencia | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <td class="text-left">{{tipoincidencia.id}}</td>
                  <td class="text-left">{{tipoincidencia.name}}</td>
                  <td class="text-center" *ngIf="tipoincidencia.status==1">ACTIVO</td>
                  <td class="text-center" *ngIf="tipoincidencia.status==0">INACTIVO</td>
                  <td class="text-center">
                    <a (click)="editTipoIncidencia(tipoincidencia, content)" placement="top" ngbTooltip="Editar">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </a>&nbsp;
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-8">
                <div *ngIf="filteredtipoincidencia.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredtipoincidencia.length}} registros</div>
                <div *ngIf="filteredtipoincidencia.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredtipoincidencia.length}} de un total de {{filteredtipoincidencia.length}} registros</div>
            </div>
            <div class="col-md-4" >
                <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="filteredtipoincidencia.length" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination> 

            </div>
        </div>
      </div>
      </div>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h5 class="modal-title">Información de tipo de incidencia</h5>
          <button type="button" class="close" aria-label="Close" (click)="onReset()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="registerForm" #myForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Nombre</label>
                  <input [disabled]="isEdit"  class="form-control"  type="text" formControlName="name"  name="name" id="name" [(ngModel)]="selectedTipoIncidencia.name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }"   />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Nombre es un campo requerido</div>
                  </div>
                  <!-- <div *ngIf="isEdit" class="form-control" style="background-color: #E9ECEF;">{{selectedTipoIncidencia.name}}</div> -->
                </div>
                <div class="form-group col-md-6">
                    <div class="form-group">
                        <label for="status">Status</label>
                        <select formControlName="status" class="form-control" name="status" id="status"  [(ngModel)]="selectedTipoIncidencia.status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                          <option [value]=1>ACTIVO</option>
                          <option [value]=0>INACTIVO</option>
                        </select>
                        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                          <div *ngIf="f.status.errors.required">Status es un campo requerido</div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button class="btn btn-primary mr-1">Guardar</button>
                <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>

        </div>
      </ng-template>
      <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    </main>
  </div>
</div>