import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { TipoInmueble } from '../models/tipo-inmueble';

@Injectable({
  providedIn: 'root'
})
export class TipoInmuebleService {
  selectedData: TipoInmueble;
  data: TipoInmueble[];

  constructor(private http: HttpClient) { 
    this.selectedData = new TipoInmueble();
    this.data = [];
  }

  getTipoInmueble(padreId: string){
    return this.http.get(Global.URL_API + 'property/PropertyType' + `/${padreId}`);
  }

  getTipoInmueblePorTipo(padreId: string, tipoInmueble: string){
    return this.http.get(Global.URL_API + 'property/PropertyType' + `/${padreId}/${tipoInmueble}`);
  }

  postTipoInmueble(tipoInmueble: TipoInmueble){
    return this.http.post(Global.URL_API + 'property/PropertyType', tipoInmueble, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    })
  }

  putTipoInmueble(tipoInmueble: TipoInmueble){
    return this.http.put(Global.URL_API + 'property/PropertyType' + `/${tipoInmueble._id}`, tipoInmueble, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    })
  }

  deleteTipoInmueble(_id: string){
    return this.http.delete(Global.URL_API + 'property/PropertyType' + `/${_id}`,{
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    })
  }
}
