<div class="col-md-4 mx-auto">
    <div class="card-group mt-4">
        <div class="card">
            <div class="card-body">
                <form ngForm #loginForm="ngForm" (ngSubmit)="recoverPassword(loginForm)" ngNativeValidate>
                    <h1>QuickAccess</h1>
                    <p class="text-muted">¿Olvidaste tu contraseña? Ingresa tu número de teléfono o correo</p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend"><span class="input-group-text">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                </svg>
                            </span>
                        </div>
                        <input name="userdata" id="userdata" #userdata="ngModel" [(ngModel)]="passwordService.userData.userdata"
                            class="form-control" placeholder="Teléfono o correo electrónico" required type="text">
                    </div>
                    <div class="input-group mb-4">
                    </div>
                    <div class="row">
                        <div class="col-12"><button class="btn btn-primary px-4 btn-block">Recuperar contraseña</button></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>