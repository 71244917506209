import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { CheckpointsComponent } from './components/checkpoints/checkpoints.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InmueblesComponent } from './components/inmuebles/inmuebles.component';
import { AdminInmueblesComponent } from './components/inmuebles/admin-inmuebles/admin-inmuebles.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { RecoveryCodeComponent } from './components/recovery-code/recovery-code.component';
import { UsersComponent } from './components/users/users.component';
import { AdminGuard } from './guards/admin.guard';
import { LoginGuard } from './guards/login.guard';
import { EventComponent } from './components/event/event.component';
import { MonitoreoCajerosComponent } from './components/monitoreoCajeros/monitoreocajero.component';
import { ResidentesComponent } from './components/residentes/residentes.component';
import { CajeroComponent } from './components/cajero/cajero.component';
import { ServicioComponent } from './components/servicio/servicio.component';
import { ServicioactivoComponent } from './components/servicioactivo/servicioactivo.component';
import { MonitoreoInicenciasComponent } from './components/monitoreo-inicencias/monitoreo-inicencias.component';
import { ReporteServiciosComponent } from './components/reporte-servicios/reporte-servicios.component';
import { ReporteDetalleServiciosComponent } from './components/reporte-detalle-servicios/reporte-detalle-servicios.component';
import { ReporteExcelComponent } from './components/reporte-excel/reporte-excel.component';
import { CatalogosGuard } from './guards/catalogos.guard';
import { ReportesGuard } from './guards/reportes.guard';
import { ReprocesarComponent } from './components/reprocesar/reprocesar.component';
import { TipoIncidenciaComponent } from './components/tipo-incidencia/tipo-incidencia.component';
import { AuditoriaComponent } from './components/auditoria/auditoria.component';
import { ReporteServiciosFechaComponent } from './components/reporte-servicios-fecha/reporte-servicios-fecha.component';
import { ReporteCompulsaComponent } from './components/reporte-compulsa/reporte-compulsa.component';
import { MonitoreoValesCajaComponent } from './components/monitoreo-valescaja/monitoreo-valescaja.component';
const routes: Routes = [
  // { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: '',component: LoginComponent  },
  { path: 'home', component: DashboardComponent, canActivate: [LoginGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard] },
  { path: 'users', component: UsersComponent, canActivate: [CatalogosGuard] },
  { path: 'monitoreocajeros', component: MonitoreoCajerosComponent, canActivate: [LoginGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'cajeros', component: CajeroComponent, canActivate: [CatalogosGuard] },
  { path: 'tipoincidencia', component: TipoIncidenciaComponent, canActivate: [CatalogosGuard] },
  { path: 'servicios', component: ServicioComponent, canActivate: [CatalogosGuard] },
  { path: 'serviciosactivos', component: ServicioactivoComponent, canActivate: [CatalogosGuard] },
  { path: 'monitoreoincidencias', component: MonitoreoInicenciasComponent, canActivate: [LoginGuard] },
  { path: 'monitoreovalescaja', component: MonitoreoValesCajaComponent, canActivate: [CatalogosGuard] },
  { path: 'reporteservicios', component: ReporteServiciosComponent, canActivate: [LoginGuard] },
  { path: 'reportecompulsa', component: ReporteCompulsaComponent, canActivate: [LoginGuard] },
  { path: 'reporteserviciosfecha', component: ReporteServiciosFechaComponent, canActivate: [LoginGuard] },
  { path: 'reprocesar', component: ReprocesarComponent, canActivate: [LoginGuard] },
  { path: 'reportedetalleservicios', component: ReporteDetalleServiciosComponent, canActivate: [ReportesGuard] },
  { path: 'reporteexcel', component: ReporteExcelComponent, canActivate: [ReportesGuard] },
  { path: 'auditoria', component: AuditoriaComponent, canActivate: [CatalogosGuard] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
