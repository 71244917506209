import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Servicio } from '../models/servicio';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class ServicioService {
  servicios: Servicio[] = [];
  selectedServicio:Servicio=new Servicio();
  constructor(private http: HttpClient) { }

  getServicios() {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.get(Global.URL_API + 'servicio',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  postServicio(servicio: Servicio) {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    servicio.id_proyecto=userData.id_proyecto;
    return this.http.post(Global.URL_API + 'servicio', servicio,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

}
