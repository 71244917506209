import { Time } from "@angular/common";
import * as internal from "stream";

export class Incidencia {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    folio_control: string;          //alta
    usuario_final: string;          //alta
    tel_usuario_final: string = ''; //alta
    comentarios:string='';        //alta
    fecha_ticket: String;             //alta
    hora_ticket:String;               //alta
    fecha_ticketd: Date;             //alta
    hora_tickett:String;               //alta
    fecha_resuelto: String;             //alta
    hora_resuelto:String;               //alta
    fecha_resueltod: Date;             //alta
    hora_resueltot:String;               //alta
    id_tipoincidencia: number;      //alta
    id_origenincidencia: number;      //alta
    id_proyecto: number;            //alta
    id_usuario: number;             //alta
    id_vale: number;                //alta
    id_statusincidencia: number;    //alta
    num_cajero: number ;            //alta
    diferencia:number;
    
    id_incidencia:number;      //vista/modificacion     
    ubicacionfisica: string;   //vista
    tecnico: string;           //vista
    status_incidencia: string; //vista
    tipo_incidencia: string;   //vista
    reporte:string;            //vista
    fecha:string;
    hora:Time;
    fecharesuelto:string;
    nivel_servicio:string;
    sfecha_ticket:string;
    shora_ticket:string;
    id_nivelservicio:number;   //modificacion a en proceso 

    solucion:string='';        //modificacion a resuelto
    visto_bueno:string='';     //modificacion a resuelto

}

export class DetalleIncidencia {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    id_detincidencia:number;
    id_incidencia:number;
    id_statusincidencia:number;
    id_usuario:number;
    id_nivelservicio:number;
    comentarios:string;
    vobo:string;
    fecha:string; 
    hora:Time;
    usuario:string;
    statusincidencia:string;
    nivelservicio:string;

}
