<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="form-group row">
                        <div class="col-md-10">
                            <h2 class="card-title">{{page_title}}</h2>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success" (click)="openModal(content)">Agregar usuario</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Clave Cliente</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col">Vigencia</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let servicio of servicioService.servicios | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                            <td >{{servicio.name}}</td>
                                            <td >{{servicio.clave_cliente}}</td>
                                            <td align="right">{{servicio.precio |currency:'USD':'symbol'}}</td>
                                            <td >{{servicio.vigencia_precio | date:'dd/MM/yyyy'}}</td>
                                            <td *ngIf="servicio.status">Activo</td>
                                            <td *!ngIf="servicio.status">Inactivo</td>
                                            <td >iconos</td>
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="servicioService.servicios.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination> 
                </div>
                    </div>
                </div>
            </div>
            <ng-template #content let-modal>
                <div class="modal-header">
                  <h5 class="modal-title">Información de servicio</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form (ngSubmit)="addServicio(servicioForm)" #servicioForm="ngForm" ngNativeValidate>
                    <div class="row">
                      <div class="col-lg-6">
                        <input type="hidden" name="_id" id="_id" #_id="ngModel" [(ngModel)]="selectedServicio.id_servicio">
                        <div class="form-group">
                          <label for="status">Nombre</label>
                          <input class="form-control" type="text" name="name" id="name" #name="ngModel"
                            [(ngModel)]="selectedServicio.name" placeholder="Nombre" required>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="cve">Clave Cliente</label>
                          <input class="form-control" type="text" name="clave_cliente" id="clave_cliente" #clave_cliente="ngModel"
                            [(ngModel)]="selectedServicio.clave_cliente" placeholder="Clave Cliente" required>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                              <label for="precio">Precio</label>
                              <input class="form-control" type="text" name="precio" id="precio" #precio="ngModel"
                                [(ngModel)]="selectedServicio.precio" placeholder="Precio" required>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                              <label for="vigencia">Vigencia</label>
                              <input class="form-control" id="vigencia" type="date" name="vigencia" 
                              [(ngModel)]="selectedServicio.vigencia" placeholder="Vigencia" >
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <div class="custom-control custom-switch form-group">
                                    <input type="checkbox" class="custom-control-input form-control" name="status" id="status" #status="ngModel"
                                    [(ngModel)]="selectedServicio.status">
                                    <label class="custom-control-label" for="status" *ngIf="selectedServicio.status">Activo</label>
                                    <label class="custom-control-label" for="status" *ngIf="!selectedServicio.status">Inactivo</label>
                                </div>
                            </div>
  

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <button class="btn btn-success">Guardar</button>
                          </div>
                        </div>
                      </div>
                 </form>
              </div>
            </ng-template>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>