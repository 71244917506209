<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <h2 class="card-title">{{page_title}}</h2>
                </div>
                <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="select1">Cajeros</label>
                                <ng-select  [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedCajero">
                                </ng-select>
                            </div>
                            <div class="col-md-4">
                                <label for="select1">Técnicos</label>
                                <select *ngIf="nombreTecnico==''"  class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                                <div *ngIf="nombreTecnico!=''" class="form-control" style="background-color: #E9ECEF;">{{nombreTecnico}}</div>

                            </div>
                            <div class="col-md-4">
                                <label for="select1">Status del cajero</label>
                                <select  class="form-control" name="status" id="status"
                                    #status="ngModel" [(ngModel)]="selectedStatusCajero" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let status of statusCajeroService.statusCajero" [ngValue]="status">
                                        {{status?.name}}</option>
                                </select>
                                
                            </div>
                        </div>
                    <form (ngSubmit)="getDataMonitoreo()" #filtrosForm="ngForm" [formGroup]="myForm">
                        <div class="form-group row">
                            <div class="col-md-5"></div>
                            
                            <div class="col-md-4">
                                <button class="btn btn-success">Buscar</button>
                            </div>

                        </div>

                    </form>

                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Exportar
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" (click)="exportExcel()">Excel</a>
                                  <!-- <a class="dropdown-item" (click)="exportPDF()">PDF</a> -->
                                </div>
                              </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-right">
                            <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cajero</th>
                                            <th scope="col">Municipio</th>
                                            <th scope="col">Ubicacion</th>
                                            <th scope="col">Fecha y Hora</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Formatos para Actas</th>
                                            <th scope="col">Hojas Bond</th>
                                            <th scope="col">M1P</th>
                                            <th scope="col">M5P</th>
                                            <th scope="col">M10P</th>
                                            <th scope="col">B50P</th>
                                            <th scope="col">Versión</th>
                                            <th scope="col">Cambio</th>
                                            <th scope="col">Técnico</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of filteredmonitoreo | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                            <td *ngIf="item.Cajero !== undefined">{{item.Cajero}}</td>
                                            <td *ngIf="item.Municipio !== undefined">{{item.Municipio}}</td>
                                            <td *ngIf="item.Ubicacion">{{item.Ubicacion}}</td>
                                            <td>{{item.Fecha.substring(0,10)}} {{item.Hora | date:'HH:mm:ss' }}</td>
                                            <td *ngIf="item.StatusCajero=='CORRECTO'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="CORRECTO">
                                                    <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                    </svg>                                                </a>

                                            </td>
                                            <td *ngIf="item.StatusCajero=='SIN CONEXIÓN A INTERNET'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="SIN CONEXIÓN A INTERNET">
                                                    <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wifi-off" viewBox="0 0 16 16">
                                                        <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                                                    </svg>
                                                </a>
                                            </td>
                                            <td *ngIf="item.StatusCajero=='POCOS FOLIOS'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="EL CAJERO NO CUENTA CON SUFICIENTES FOLIOS">
                                                <svg style="color: orange;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-clipboard-x" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/>
                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                                </svg>  
                                                </a>
                                            </td>
                                            <td *ngIf="item.StatusCajero=='POCO EFECTIVO'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="EL CAJERO NO CUENTA CON SUFICIENTE EFECTIVO">
                                                <svg style="color: orange;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                                                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                                                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                                                </svg>
                                                </a>

                                            </td>
                                            <td *ngIf="item.StatusCajero=='POCOS FOLIOS Y POCO EFECTIVO'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="EL CAJERO NO CUENTA CON SUFICIENTES FOLIOS NI EFECTIVO">
                                                <svg style="color: orange;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                                  </svg>                                                
                                                </a>

                                            </td>
                                            <td *ngIf="item.StatusCajero=='SIN INSTALAR'">
                                                <a href="javascript:void(0);" 
                                                placement="top" [autoClose]="true" ngbTooltip="SIN INSTALAR">
                                                <svg style="color: blue;"xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wrench" viewBox="0 0 16 16">
                                                    <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"/>
                                                  </svg>                                                
                                                </a>

                                            </td>
                                            <td>{{item.FolioRestantesB1}}</td>
                                            <td>{{item.FolioRestantesB2}}</td>
                                            <td align="right">{{item.M1p*1 |currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.M5p*5 |currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.M10p*10 |currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.B50p*50 |currency:'USD':'symbol'}}</td>
                                            <td>{{item.Version}}</td>
                                            <td>{{item.Stock|currency:'USD':'symbol'}}</td>
                                            <td>{{item.Nombre+' '+item.ApPaterno+' '+item.ApMaterno}}</td>
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="filteredmonitoreo.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredmonitoreo.length}} registros</div>
                            <div *ngIf="filteredmonitoreo.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredmonitoreo.length}} de un total de {{filteredmonitoreo.length}} registros</div>
                        </div>
                        <div class="col-md-4" >
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="filteredmonitoreo.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination> 

                        </div>
                    </div>


                </div>
            </div>
        </main>
    </div>
</div>