export class TipoInmueble {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    _id: string = '';
    nombre: string = '';
    calle: string = '';
    padreId: string = '';
    tipoInmueble: string = '';
    status: number = 1;
}
