<div id="login">
    <div class="container">
       
            <div class="card">
                <div class="card-body">
                    <form  ngForm #loginForm="ngForm" (ngSubmit)="login(loginForm)" ngNativeValidate >
                        <p class="text-muted text-center">Iniciar Sesión</p>
                        <div class="input-group mb-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                    </span>
                                        </div>            
                            <input name="email" id="email" #email="ngModel" [(ngModel)]="loginData.usuario"
                                class="form-control" placeholder="Ingrese el nombre del usuario" required type="text">
                        </div>
                        <div class="input-group mb-4">    
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="fa"  [ngClass]="{
                                        'fa-eye-slash': !fieldTextType,
                                        'fa-eye': fieldTextType
                                      }"
                                      (click)="toggleFieldTextType()"></i>
                                  </span>
                            </div>         
                            <input name="password" id="password" [(ngModel)]="loginData.contrasena"
                            autocomplete="current-password" class="form-control" placeholder="Contraseña"  
                            [type]="fieldTextType ? 'text' : 'password'" #password="ngModel"  required>
                                
                        </div>
                        
                        <div class="row">
                            <div class="col-md-4">
                                <div class="col-6"><button class="btn btn-primary px-4">Login</button></div>
                            </div>
                        </div>
                        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>                
                    </form>
                </div>
            </div>
 
    </div>
</div>
