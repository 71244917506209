import { Time } from "@angular/common";
import * as internal from "stream";

export class ResTotal{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public Total:number;
    public MontoTotal:number;
    public TotalTarj:number;
    public MontoTotalTarj:number;
}

export class ResReporteGeneralServicios{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public DescServicio:string;
    public Total:number;
    public MontoTotal:number;
}

export class ResReporteDetallePagos{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public Cajero:number;
    public Ubicacion:string;
    public Nombre:string;
    public ApPaterno:string;
    public ApMaterno:string;
    public Total:number;
    public MontoTotal:number;
    public TotalTarj:number;
    public MontoTotalTarj:number;
}

export class ResReportePagosDia{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public Fecha:string;
    public Total:number;
    public MontoTotal:number;
    public TotalTarj:number;
    public MontoTotalTarj:number;}
export class ResReporteFormaPago{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public FormaPago:string;
    public Total:number;
    public MontoTotal:number;
}
export class DatosReporteGeneralServicios{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public RegReporteGeneralServicios:ResReporteGeneralServicios[];
    public TotReporteGeneralServicios:ResTotal;
    public RegDetallePagos:ResReporteDetallePagos[];
    public TotDetallePagos:ResTotal;
    public RegPagoDia:ResReportePagosDia[];
    public RegFormaPago:ResReporteFormaPago[];
}
export class DatosReporteServicios{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public RegReporteGeneralServicios:ResReporteGeneralServicios[];
    public TotReporteGeneralServicios:ResTotal;
}
export class ResReporteDetalleServicios{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public Cajero:number;
    public Fecha:string;
    public Hora:string;
    public Ubicacion:string;
    public Servicio:string;
    public Referencia:string;
    public Contribuyente:string;
    public Total:number;
    public folio_control:string;
    public cfe:number;
    public FormaPago:string;
}
export class ResReporteCompulsa{
    constructor(t:any = {}){
        Object.assign(this, t);
    }

    public fecha:string;
    public transaccionescompulsa:number;
    public importecompulsa:number;
    public transaccionesactual:number;
    public importeactual:number;
}