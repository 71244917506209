import { Checkpoint } from '../models/checkpoint';
import { TipoInmueble } from './tipo-inmueble';
export class UserAddress {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    _id: string = '';
    idUsuario: string = '';
    idInmueble: string = '';
    calle: string = '';
    numExt: string = '';
    numInt: string = '';
    cp: number = 0;
    colonia: string = '';
    status: number = 1;
    noHabitantes: number = 0;
    checkpoint: Checkpoint[] = [];
    alias: string = '';
    tipoInmueble: TipoInmueble = new TipoInmueble();
    noCuenta: string = '';
}