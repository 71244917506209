import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Global } from '../config/config';
import { Router } from '@angular/router';
import { ValeCaja } from '../models/valecaja';
import { TipoIncidencia } from '../models/tipoincidencia';

@Injectable({
  providedIn: 'root'
})
export class ValeCajaService {

  selectedValeCaja: ValeCaja;
  valesCaja:ValeCaja[] ;
  constructor(private http: HttpClient, private router: Router) {
    this.selectedValeCaja = new ValeCaja();
  }


  postValeCaja(valecaja: ValeCaja) {
    return this.http.post(Global.URL_API + 'valecaja', valecaja,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getValeCaja() {
    return this.http.get(Global.URL_API + 'valecaja',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getReporteValesCaja(tecnico:number,fechaInicio:string,fechafin:string,id_statusincidencia:number) {
    const body={fechainicio:fechaInicio,fechafin:fechafin,id_tecnico:tecnico,id_statusincidencia:id_statusincidencia};
    return this.http.post(Global.URL_API + 'reportevalecaja',body,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

}
