import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { UserAddress } from '../models/user-address';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {
  selectedUserAddress: UserAddress;

  constructor(private http: HttpClient) { 
    this.selectedUserAddress = new UserAddress();
  }

  getResidentials() {
    return this.http.get(Global.URL_API + 'property/getResidentialArea', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getUserAddress(_id: string)
  {
    return this.http.post(Global.URL_API + 'user/get-userAddress',{id: _id}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  getUserAddressByAccountNumber(accountNumber: string)
  {
    return this.http.post(Global.URL_API + 'user/getUserAddressByAccountNumber',{accountNumber: accountNumber}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  postUserAddress(userAddress: UserAddress) {
    return this.http.post(Global.URL_API + 'user/saveUserAddress', userAddress, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  putUserAddress(userAddress: UserAddress) {
    return this.http.put(Global.URL_API + 'user/update-userAddress' + `/${userAddress._id}`, userAddress, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  deleteUserAddress(_id: string) {
    return this.http.delete(Global.URL_API + 'user/UserAddress' + `/${_id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}