import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Cajero } from '../models/cajero';
import { Estado } from '../models/estado';
import { Municipio } from '../models/municipio';
import { StatusCajero } from '../models/statuscajero';
import { User } from '../models/user';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class CajeroService {
  cajeros: Cajero[] = [];
  selectedCajero:Cajero;
  selectedMunicipio:Municipio;
  selectedEstado:Estado;
  selectedUsuario:User;
  selectedStatus:StatusCajero;
  constructor(private http: HttpClient) { }

  getCajeros() {
    return this.http.get(Global.URL_API + 'cajero',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  putCajero(cajero: Cajero) {
    console.log(JSON.stringify(cajero))
    return this.http.put(Global.URL_API + 'cajero' + `/${cajero.id_cajero}`, cajero, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  postCajero(cajero: Cajero) {
    return this.http.post(Global.URL_API + 'cajero' , cajero, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
