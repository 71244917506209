export class Servicio{
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public id_servicio:number;
    public id_proyecto:number;
    public name:string;
    public clave_cliente:string;
    public precio:number;
    public vigencia_precio:Date;
    public fecha_alta:Date;
    public status:number;
    public padre:number;
}