import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { ResReporteDetalleServicios } from '../models/reportes';
import { ResConsultaPago } from '../models/reprocesar';

@Injectable({
  providedIn: 'root'
})
export class ReprocesarService {

  constructor(private http: HttpClient) { }


  postConsultaPago(referencia: string) {
    const body={referencia:referencia};
    return this.http.post(Global.CONSULTA_PAGO, body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
      })
    });
  }

  postLoginConsultaInformacion(usuario: string,password:string,ambiente:string) {
    const body={usuario:usuario,password:password,ambiente:ambiente};
    return this.http.post(Global.LOGIN_CONSULTA_INFORMACION, body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
      })
    });
  }

  postConsultaInformacion(token:string,referencia:string) {
    const body={numeroServicio:8000,campo:referencia,campo2:'',extra:''};
    return this.http.post(Global.CONSULTA_INFORMACION , body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    });
  }
  postRenotificacion(token:string,payment:ResConsultaPago,resReporteDetalleServicios:ResReporteDetalleServicios) {
    const body={
      token :token,
      folioSeguimiento : payment.folio_seguimiento,
      servicio : payment.servicio,
      importePagado : payment.importe,
      referencia : payment.referencia,
      referenciaPago : resReporteDetalleServicios.folio_control,
      fechaPago : resReporteDetalleServicios.Fecha,
      numeroAutorizacion: payment.folio,
      oficina :90,
      numcaja : resReporteDetalleServicios.Cajero
    };
    return this.http.post(Global.RENOTIFICACION , body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }
  postReprocesar(token:string,resReporteDetalleServicios:ResReporteDetalleServicios) {
    const body={
      referencia:resReporteDetalleServicios.Referencia,
      fechaPago:resReporteDetalleServicios.Fecha.substring(8,10)+resReporteDetalleServicios.Fecha.substring(5,7)+resReporteDetalleServicios.Fecha.substring(0,4),
      tipoPago: 4,
      importe:resReporteDetalleServicios.Total.toString().replace(".", ""),
      secuenciaReferencia :0,
      folioBanco :"",
      aprobacion :resReporteDetalleServicios.folio_control,
      correoElectronico : "",
      estatusPago :0,
      claveBanco :90,
      caja :resReporteDetalleServicios.Cajero.toString().padStart(2, '0'),
      proceso:1,
      cuentaAfiliacion:"",
      hora:resReporteDetalleServicios.Hora.replace(":","").replace(":",""),
      extra :""

    };
    return this.http.post(Global.REPROCESAR , body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    });
  }

}
