import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { DetCajeroServicio } from '../models/detcajeroservicio';

@Injectable({
  providedIn: 'root'
})
export class DetCajeroServicioService {
  detallesCajeroSevicio: DetCajeroServicio[] = [];
  constructor(private http: HttpClient) { }

  getDetCajeroServicio(id_cajero:number) {
    return this.http.get(Global.URL_API + 'detcajeroservicio' + `/${id_cajero}`, {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  postDetCajeroServicio(detCajeroServicio: DetCajeroServicio) {
    console.log(detCajeroServicio)
    return this.http.post(Global.URL_API + 'detcajeroservicio' , detCajeroServicio, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}