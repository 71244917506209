<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="form-group row">
                        <div class="col-md-10">
                            <h2 class="card-title">{{page_title}}</h2>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <br>
                    <div class="row">
                        <div class="col-md-9"></div>
                        <div class="col-md-3 text-right">
                            <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>

                        </div>
                    </div>
                    <br>
                    <div class="table-responsive">
                        <table class="table table-striped table-sm">
                          <thead>
                            <th class="text-center">Número</th>
                            <th class="text-center">Ubicación</th>
                            <th class="text-center">Técnico</th>
                            <th class="text-center">TeamViewer</th>
                            <th class="text-center">Operaciones</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let cajero of filteredcajero | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                              <td class="text-left">{{cajero.numero}}</td>
                              <td class="text-left">{{cajero.ubicacionfisica}}</td>
                              <td class="text-left">{{cajero.tecnico}}</td>
                              <td class="text-left">{{cajero.idteamviewer}}</td>
                              <td class="text-center">
                                <a (click)="editCajero(cajero, content)" placement="top" ngbTooltip="Servicios">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                      </svg>                                
                                </a>&nbsp;
                                
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <div class="row">
                          <div class="col-md-8">
                              <div *ngIf="filteredcajero.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredcajero.length}} registros</div>
                              <div *ngIf="filteredcajero.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredcajero.length}} de un total de {{filteredcajero.length}} registros</div>
                          </div>
                          <div class="col-md-4" >
                              <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                  [collectionSize]="filteredcajero.length" [maxSize]="5" [rotate]="true"
                                  [ellipses]="false" [boundaryLinks]="true">
                              </ngb-pagination> 
  
                          </div>
                      </div>
                      <ng-template #content let-modal let-c="close" let-d="dismiss">
                            <div class="modal-header">
                              <h5 class="modal-title">Detalle Servicios Activos</h5>
                              <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                                <div class="table-responsive">
                                    <table class="table table-striped table-sm">
                                      <thead>
                                        <th class="text-center">Servicio</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Efectivo</th>
                                        <th class="text-center">Tarjeta</th>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let detcajeroservicio of detCajeroServicioService.detallesCajeroSevicio; let i=index; ">
                                          <td class="text-left">{{detcajeroservicio.name}}</td>
                                          <td class="text-left">           
                                            <input type="checkbox"  value="{{detcajeroservicio.id_cajeroservicio}}"
                                            [(ngModel)]="detCajeroServicioService.detallesCajeroSevicio[i].status" (change)="changeStatus(i)">
                                          </td>
                                          <td class="text-left">           
                                            <input type="checkbox"  value="{{detcajeroservicio.id_cajeroservicio}}"
                                            [(ngModel)]="detCajeroServicioService.detallesCajeroSevicio[i].efectivo" (change)="changeStatus(i)">
                                          </td>
                                          <td class="text-left">           
                                            <input type="checkbox"  value="{{detcajeroservicio.id_cajeroservicio}}"
                                            [(ngModel)]="detCajeroServicioService.detallesCajeroSevicio[i].tarjeta" (change)="changeStatus(i)">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                </div>
            
                            </div>
                        </ng-template>
                </div>
            </div>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>