import { Component, OnInit } from '@angular/core';
import { CatalogosGuard } from 'src/app/guards/catalogos.guard';
import { UserData } from 'src/app/models/user-data';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  activeCatalogos:boolean=false;
  activeReportes:boolean=false;
  catalogosopen=false;
  monitoreoopen=false;
  reportesopen=false;
  constructor() { }

  ngOnInit(): void {
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    this.activeCatalogos=(user && (user.category.name == "ADMINISTRADOR" ));
    this.activeReportes=(user && (user.category.name == "ADMINISTRADOR" || user.category.name == "MONITOREO"));
  }
  onCatalogosClick(){
    this.catalogosopen=this.catalogosopen?false:true;
  }
  onMonitoreoClick(){
    this.monitoreoopen=this.monitoreoopen?false:true;
  }
  onReportesClick(){
    this.reportesopen=this.reportesopen?false:true;
  }

}
