import { TemplateBindingParseResult } from "@angular/compiler";

export class Cajero{
    num_cajero: string;
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    public id_cajero:number=0;
    public numero:number;
    public num_serie:string;
    public id_estado:number;
    public id_municipio:number;
    public calle:string;
    public num_ext:string;
    public num_int:string;
    public colonia:string;
    public cp:string;
    public ubicacionfisica:string;
    public referencia:string;
    public mac_address:string;
    public id_teamviewer:string;
    public pw_teamviewer:string;
    public id_usuario:number;
    public id_statuscajero:number;

    public no_terminal:string;
    public id_proyecto:number;
    public acta_entrega:string="";
    public fecha_alta:Date;
    public latitud:string;
    public longitud:string;
    public status:boolean=false;
    public isLocal:number;
    public tempLabel:String;
    
}