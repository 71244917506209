import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cajero } from 'src/app/models/cajero';
import { Estado } from 'src/app/models/estado';
import { Municipio } from 'src/app/models/municipio';
import { StatusCajero } from 'src/app/models/statuscajero';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';
import { CajeroService } from 'src/app/services/cajero.service';
import { EstadoService } from 'src/app/services/estado.service';
import { MunicipioService } from 'src/app/services/municipio.service';
import { StatusCajeroService } from 'src/app/services/statuscajero.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cajero',
  templateUrl: './cajero.component.html',
  styleUrls: ['./cajero.component.css']
})
export class CajeroComponent implements OnInit {
  page_title = 'Cajeros';
  activeModal: NgbModalRef;
  page = 1;
  pageSize = 10;
  filter = "";
  public filteredcajero: any[] = [];
  constructor(public cajeroService:CajeroService,public userService:UserService, public modal: NgbModal,public statusCajeroService:StatusCajeroService,public estadoService:EstadoService,public municipioService:MunicipioService,public toastService: ToastService) { }

  ngOnInit(): void {
    
    this.getCajeros();
    this.getTecnicos();
    this.getStatusCajero();
    this.getEstados();
    this.getMunicipios();
    this.cajeroService.selectedCajero=new Cajero();
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.filteredcajero=this.cajeroService.cajeros;
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getStatusCajero():void{
    this.statusCajeroService.getStatusCajero()
    .subscribe((res: any) => {
      this.statusCajeroService.statusCajero = res as StatusCajero[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getEstados():void{
    this.estadoService.getEstados()
    .subscribe((res: any) => {
      this.estadoService.estados = res as Estado[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  getMunicipios():void{
    this.municipioService.getMunicipios()
    .subscribe((res: any) => {
      this.municipioService.municipios = res as Municipio[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.cajeroService.selectedCajero = new Cajero();
    }).catch(() => {
      this.cajeroService.selectedCajero = new Cajero();
    });
  }
  editCajero(cajero:Cajero,content)
  {
    this.cajeroService.selectedCajero = cajero;
    this.cajeroService.selectedEstado=this.estadoService.estados.find(e=>e.id_estado==this.cajeroService.selectedCajero.id_estado);
    this.cajeroService.selectedMunicipio=this.municipioService.municipios.find(e=>e.id_municipio==this.cajeroService.selectedCajero.id_municipio);
    this.cajeroService.selectedUsuario=this.userService.tecnicos.find(e=>e.id_usuario==this.cajeroService.selectedCajero.id_usuario);
    this.cajeroService.selectedStatus=this.statusCajeroService.statusCajero.find(e=>e.id_statuscajero==this.cajeroService.selectedCajero.id_statuscajero);
    this.openModal(content);
    
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  addCajero(form?: NgForm) {
    this.cajeroService.selectedCajero.id_estado=this.cajeroService.selectedEstado.id_estado;
    this.cajeroService.selectedCajero.id_municipio=this.cajeroService.selectedMunicipio.id_municipio;
    this.cajeroService.selectedCajero.id_usuario=this.cajeroService.selectedUsuario.id_usuario;
    this.cajeroService.selectedCajero.id_statuscajero=this.cajeroService.selectedStatus.id_statuscajero;
    if (form.value.id_cajero>0) {
      this.cajeroService.putCajero(this.cajeroService.selectedCajero)
        .subscribe((res: any) => {
          console.log(res)
          if (res.codigo==200) {
            this.resetForm(form);
            this.toastService.show('Cajero Actualizado',{classname: 'bg-primary text-white',headertext: 'Información'});
            this.getCajeros();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
        });
    }
    else {
      let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
      this.cajeroService.selectedCajero.id_proyecto=userData.id_proyecto;
      this.cajeroService.selectedCajero.fecha_alta=new Date();
      this.cajeroService.postCajero(this.cajeroService.selectedCajero)
        .subscribe((res: any) => {
          console.log(res);
          if (res.codigo==200) {
            this.resetForm(form);
            this.toastService.show('Cajero Añadido',{classname: 'bg-primary text-white',headertext: 'Información'});
            this.getCajeros();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
        });
    }
  }
  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.cajeroService.selectedCajero = new Cajero();
      this.cajeroService.selectedEstado=new Estado();
      this.cajeroService.selectedMunicipio=new Municipio();
      this.cajeroService.selectedStatus=new StatusCajero();
      this.cajeroService.selectedUsuario=new User();
    }
    this.getCajeros();
  }
  closeModal(){
    this.cajeroService.selectedCajero = new Cajero();
    this.activeModal.close();
    this.getCajeros();
    
  }
  filterChange(e){
    e=e.toLocaleLowerCase();
    this.filteredcajero=this.cajeroService.cajeros.filter((val) => {
      let rVal = (val.numero.toString().includes(e)) 
      || (val.ubicacionfisica.toLocaleLowerCase().includes(e))
      || (val.id_usuario.toString().includes(e))
      || (val.id_teamviewer.toLocaleLowerCase().includes(e))
      return rVal;
    })

  }

}
