import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Servicio } from 'src/app/models/servicio';
import { ServicioService } from 'src/app/services/servicio.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.css'],
  providers: [DatePipe]
})
export class ServicioComponent implements OnInit {
  page_title = 'Servicios';
  page = 1;
  pageSize = 7;
  activeModal: NgbModalRef;
  selectedServicio:Servicio=new Servicio();
  constructor(public servicioService:ServicioService,public modal: NgbModal,public toastService: ToastService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getServicios();
  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.selectedServicio = new Servicio();
      this.getServicios();
    }).catch(err => {
      this.selectedServicio = new Servicio();
      this.getServicios();
    });
  }
  addServicio(form?: NgForm) {
    console.log(this.selectedServicio);
    if (form.value.id_servicio) {
      console.log('aqui se va a modificar')
      // this.userService.putUser(form.value)
      //   .subscribe((res: any) => {
      //     if (!res.message) {
      //       this.resetForm(form);
      //       this.toastService.show('Usuario actualizado');
      //       this.getUsers();
      //       this.activeModal.close();
      //     }
      //     else {
      //       this.toastService.show(res.message);
      //     }
      //   }, (error: HttpErrorResponse) => {
      //     this.toastService.show(error.message);
      //   });
    } else {
      form.value.fecha_alta=this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      this.servicioService.postServicio(form.value)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.resetForm(form);
            this.toastService.show('Usuario creado',{classname: 'bg-primary text-white',headertext: 'Información'});
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Error'});
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Error'});
        });
    }
  }
  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.selectedServicio = new Servicio();
    }
    this.getServicios();
  }
}
