import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderBy } from '../../services/orderBy.pipe';
import { DatosReporteGeneralServicios, ResReporteGeneralServicios, ResTotal } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { UserData } from 'src/app/models/user-data';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { element } from 'protractor';

@Component({
  selector: 'app-reporte-servicios',
  templateUrl: './reporte-servicios-fecha.component.html',
  styleUrls: ['./reporte-servicios-fecha.component.css'],
  providers: [DatePipe,OrderBy]
})
export class ReporteServiciosFechaComponent implements OnInit {
  public selectedServicio:Servicio=new Servicio();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public today:string;
  myForm:FormGroup;
  order: string = '';
  templist = [];
  isLoading = false;

  page_title = 'Reporte de Servicios';

  constructor(public servicioService:ServicioService,public reporteService:ReportesService,public datepipe: DatePipe, public toastService: ToastService) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.today=dateformat;
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'servicio': new FormControl("")
    });
    this.getServicios();
    this.getReporteGeneral();

  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
      this.servicioService.servicios=this.servicioService.servicios.filter((val) => {
        let rVal = (val.padre==0) 
        return rVal;
      });
  
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getReporteGeneral(): void {
    this.isLoading = true;
    this.reporteService.getReporteServicios(this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'))
      .subscribe((res: any) => {
        this.isLoading=false;
        
        this.reporteService.datosreporteServicios.RegReporteGeneralServicios = res.data as ResReporteGeneralServicios[];
        this.reporteService.datosreporteServicios.TotReporteGeneralServicios=new ResTotal();
        let total=0;
        var totalpagar=0;
        this.reporteService.datosreporteServicios.RegReporteGeneralServicios.forEach(element=>{
          total+=element.Total;
          totalpagar+=element.MontoTotal;
        });
        this.reporteService.datosreporteServicios.TotReporteGeneralServicios.Total=total;
        this.reporteService.datosreporteServicios.TotReporteGeneralServicios.MontoTotal=totalpagar;
        console.log(this.reporteService.datosreporteServicios);

      }, (err: HttpErrorResponse) => {
        this.isLoading=false;
        console.log('err',err);
      })
    //this.getReporteTmas1();
  }
}
