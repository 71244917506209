<app-navbar></app-navbar>
<div class="container-fluid" id="fondoinicio">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <!-- <div class="card mt-4">
                <div class="card-body">
                    <h2 class="header">{{page_title}}</h2>
                 
                </div>
            </div> -->
            <div  class="card mt-4">
                <div class="card-body">
                    <h2 class="header">{{page_title}}</h2>
                    <h5 class="card-title">
                      Bienvenido(a) estimado(a) <strong>{{userData.name + ' ' + userData.surname}}</strong>,  
                    </h5>                   
                            
                </div>
              </div>
        </main>
    </div>
</div>