import { Component, OnInit, Renderer2 } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  userData: UserData;

  constructor(public userService: UserService, private renderer: Renderer2) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
   }

  ngOnInit(): void {
  }

  toggle(): void {
    var el = document.getElementById("markLogin");
    if(el.classList.contains("toggleMark"))
    {
      this.renderer.removeClass(el, "toggleMark");
      this.renderer.setAttribute(el, "aria-hidden", "true");
    }
    else{
      this.renderer.addClass(el, "toggleMark");
      this.renderer.setAttribute(el, "aria-hidden", "false");
      el.focus();
    }
    
  }

}
