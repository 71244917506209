import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { NivelServicio } from '../models/nivelservicio';
import { Perfil } from '../models/perfil';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  perfiles: Perfil[] = [];
  selectedPerfil: Perfil;
  constructor(private http: HttpClient) { }

  getPerfiles() {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.get(Global.URL_API + 'perfil',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
