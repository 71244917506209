<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <loading *ngIf="isLoading">{{ isLoading }}</loading>
            <div class="card mt-4">
                <div class="card-header">
                    <h2 class="card-title">{{page_title}}</h2>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <form (ngSubmit)="getReporteGeneral()" #filtrosForm="ngForm" [formGroup]="myForm" >
                        <div class="form-group row">
                            <div class="col-md-6">
                            <label for="name">Fecha inicio</label>
                            <input class="form-control" id="fechaInicio" f type="date" name="fechaInicio"  #fechaInicio="ngModel"
                             [(ngModel)]="selectedFechaInicio" placeholder="Fecha inicio" >
                            
                            </div>
                            <div class="col-md-6">
                            <label for="name">Fecha fin</label>
                            <input class="form-control" id="fechafin" type="date" name="fechafin" #fechafin="ngModel"
                            [(ngModel)]="selectedFechaFin" placeholder="Fecha fin">
                            
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="select1">Servicios</label>
                                <select  class="form-control" name="servicio" id="servicio"
                                    #servicio="ngModel" [(ngModel)]="selectedServicio" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let servicio of servicioService.servicios" [ngValue]="servicio">
                                        {{servicio?.name}}</option>
                                </select>
                                    
                            </div>
                            <div class="col-md-4">
                                <label for="select1">Cajeros</label>
                                <ng-select name="cajero" id="cajero" [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedCajero">
                                </ng-select>
                                
                            </div>
                            <!-- <div class="col-md-4">
                                <label for="select1">Cajeros</label>
                                <select  class="form-control" name="cajero" id="cajero"
                                    #cajero="ngModel" [(ngModel)]="selectedCajero" required>
                                    <option  *ngFor="let cajero of cajeroService.cajeros" [ngValue]="cajero">
                                        {{cajero?.numero}}</option>
                                </select>
                                        
                            </div> -->
                            <div class="col-md-4">
                                <label for="select1">Técnicos</label>
                                <select *ngIf="idTecnico==0" class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                                <div *ngIf="idTecnico>0" class="form-control" style="background-color: #E9ECEF;">{{nombreTecnico}}</div>
                            </div>
                        </div>
                        <div class="form-group row">
            
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button  class="btn  btn-success">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <ul ngbNav #nav="ngbNav"  class="nav nav-tabs">
                        <li ngbNavItem class="nav-item">
                            <a ngbNavLink>Reporte General de Pagos</a>
                            <ng-template ngbNavContent>
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                        <th>DESCRIPCION</th>
                                        <th  align="right">TOTAL DE SERVICIOS</th>
                                        <th  align="right">MONTO TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.RegReporteGeneralServicios">
                                            <td >{{item.DescServicio}}</td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                        <tr>
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotReporteGeneralServicios">
                                            <td ><b>TOTAL</b></td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                        <tr>                                    
                                        
                                    </tbody>
                                </table>
    
                            </ng-template>
                        </li>
                        <li ngbNavItem class="nav-item">
                            <a ngbNavLink>Detalle de Pagos</a>
                            <ng-template ngbNavContent>
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            
                                            <th>NO. CAJERO</th>
                                            <th>UBICACIÓN</th>
                                            <th>TÉCNICO</th>
                                            <th align="right">SERVICIOS EFECTIVO</th>
                                            <th align="right">IMPORTE EFECTIVO</th> 
                                            <th align="right">SERVICIOS TARJETA</th>
                                            <th align="right">IMPORTE TARJETA</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.RegDetallePagos | orderBy : order :true">
                                            <td >{{item.Cajero}}</td>
                                            <td >{{item.Ubicacion}}</td>
                                            <td >{{item.Nombre+' '+item.ApPaterno+' '+item.ApMaterno}}</td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.TotalTarj}}</td>
                                            <td align="right">{{item.MontoTotalTarj| currency:'USD':'symbol'}}</td>
                                        <tr>
                                            <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotDetallePagos">
                                            <td colspan="3"><b>SUBTOTAL</b></td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.TotalTarj}}</td>
                                            <td align="right">{{item.MontoTotalTarj| currency:'USD':'symbol'}}</td>
                                        <tr>                                    
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotReporteGeneralServicios">
                                            <td  colspan="4"><b>TOTAL SERVICIOS</b></td>
                                            <td colspan="4" align="right">{{item.Total}}</td>
                                        <tr>                                    
                                            <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotReporteGeneralServicios">
                                            <td  colspan="4"><b>IMPORTE TOTAL</b></td>
                                            <td colspan="4" align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                        <tr>  
                                    </tbody>
                                </table>
                            </ng-template>
                        </li>
                        <li ngbNavItem class="nav-item">
                            <a ngbNavLink>Detalle de Pagos por Día</a>
                            <ng-template ngbNavContent>
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>FECHA</th>
                                            <th align="right">SERVICIOS EFECTIVO</th>
                                            <th align="right">IMPORTE EFECTIVO</th> 
                                            <th align="right">SERVICIOS TARJETA</th>
                                            <th align="right">IMPORTE TARJETA</th> 
                                            <th  align="center">ARCHIVO T+1 EFECTIVO</th>
                                            <th  align="center">ARCHIVO T+1 TARJETA</th>                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.RegPagoDia">
                                            <td >{{item.Fecha}}</td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.TotalTarj}}</td>
                                            <td align="right">{{item.MontoTotalTarj| currency:'USD':'symbol'}}</td>
                                            <td *ngIf='item.Fecha!=today' align="center">
                                                <a href="javascript:void(0);" (click)="getReporteTmas1(item)"
                                                placement="top" [autoClose]="true" ngbTooltip="Generar Archivo T+1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-font" viewBox="0 0 16 16">
                                                        <path d="M10.943 6H5.057L5 8h.5c.18-1.096.356-1.192 1.694-1.235l.293-.01v5.09c0 .47-.1.582-.898.655v.5H9.41v-.5c-.803-.073-.903-.184-.903-.654V6.755l.298.01c1.338.043 1.514.14 1.694 1.235h.5l-.057-2z"/>
                                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                    </svg>
                                                </a>
                                            </td>
                                            <td  align="right"*ngIf="item.Fecha==today"></td>
                                            <td *ngIf='item.Fecha!=today' align="center">
                                                <a href="javascript:void(0);" (click)="getReporteTmas1Tarjeta(item)"
                                                placement="top" [autoClose]="true" ngbTooltip="Generar Archivo T+1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-font" viewBox="0 0 16 16">
                                                        <path d="M10.943 6H5.057L5 8h.5c.18-1.096.356-1.192 1.694-1.235l.293-.01v5.09c0 .47-.1.582-.898.655v.5H9.41v-.5c-.803-.073-.903-.184-.903-.654V6.755l.298.01c1.338.043 1.514.14 1.694 1.235h.5l-.057-2z"/>
                                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                    </svg>
                                                </a>
                                            </td>
                                            <td  align="right" *ngIf="item.Fecha==today"></td>
                                        <tr>
                                            <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotDetallePagos">
                                            <td ><b>SUBTOTAL</b></td>
                                            <td align="right"><b>{{item.Total}}</b></td>
                                            <td align="right"><b>{{item.MontoTotal| currency:'USD':'symbol'}}</b></td>
                                            <td align="right"><b>{{item.TotalTarj}}</b></td>
                                            <td align="right"><b>{{item.MontoTotalTarj| currency:'USD':'symbol'}}</b></td>
                                            <td></td>
                                        <tr>                                    
                                            <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotReporteGeneralServicios">
                                            <td ><b>TOTAL SERVICIOS</b></td>
                                            <td colspan="4" align="right">{{item.Total}}</td>
                                            <td></td><td></td>
                                        <tr>                                    
                                            <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.TotReporteGeneralServicios">
                                            <td ><b>IMPORTE TOTAL</b></td>
                                            <td colspan="4" align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>
                                            <td></td><td></td>
                                        <tr>  
                                        
                                    </tbody>
                                </table>
                            </ng-template>
                        </li>
                        <li ngbNavItem class="nav-item">
                            <a ngbNavLink>Detalle por forma de pago</a>
                            <ng-template ngbNavContent>
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>FORMA DE PAGO</th>
                                            <th align="right">TOTAL DE SERVICIOS</th>
                                            <th align="right">MONTO TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of reporteService.datosreporteGeneral.RegFormaPago">
                                            <td >{{item.FormaPago}}</td>
                                            <td align="right">{{item.Total}}</td>
                                            <td align="right">{{item.MontoTotal| currency:'USD':'symbol'}}</td>

                                
                                        
                                    </tbody>
                                </table>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
                            <!-- <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                            [collectionSize]="monitoreoService.datosmonitoreo.length" [maxSize]="5" [rotate]="true"
                            [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination> -->
            </div>      
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

        </main>
    </div>
</div>