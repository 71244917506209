<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <h2 class="card-title">{{page_title}}</h2>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <form (ngSubmit)="getReporteDetalle()" #filtrosForm="ngForm" [formGroup]="myForm" >
                        <div class="form-group row">
                            <div class="col-md-6">
                            <label for="name">Fecha inicio</label>
                            <input class="form-control" id="fechaInicio" f type="date" name="fechaInicio"  #fechaInicio="ngModel"
                             [(ngModel)]="selectedFechaInicio" placeholder="Fecha inicio" >
                            
                            </div>
                            <div class="col-md-6">
                            <label for="name">Fecha fin</label>
                            <input class="form-control" id="fechafin" type="date" name="fechafin" #fechafin="ngModel"
                            [(ngModel)]="selectedFechaFin" placeholder="Fecha fin">
                            
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="select1">Servicios</label>
                                <select  class="form-control" name="servicio" id="servicio"
                                    #servicio="ngModel" [(ngModel)]="selectedServicio" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let servicio of servicioService.servicios" [ngValue]="servicio">
                                        {{servicio?.name}}</option>
                                </select>
                                    
                            </div>
                            <div class="col-md-6">
                                <label for="select1">Cajeros</label>
                                <ng-select name="cajero" id="cajero" [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedCajero">
                                </ng-select>
                                
                            </div>
                        </div>
                        <div class="form-group row">
            
                            <div class="col-md-4"></div>
                            <div class="col-md-4 text-center">
                                <button  class="btn  btn-success ">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Exportar
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" (click)="exportExcel()">Excel</a>
                                  <a class="dropdown-item" (click)="exportPDF()">PDF</a>
                                </div>
                              </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-right">
                            <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table" id="excel-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cajero</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Ubicación</th>
                                            <th scope="col">Servicio</th>
                                            <th scope="col">Referencia</th>
                                            <th scope="col">Contribuyente</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Forma de pago</th>
                                            <th scope="col" *ngIf="activeAdmin" >Opciones</th>
                                            <!-- <th *ngIf="activeAdmin" scope="col" >Operaciones</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let registro of filtereddatosreporte  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ">
                                            <td >{{registro.Cajero}}</td>
                                            <td >{{registro.Fecha}} {{registro.Hora}}</td>
                                            <td >{{registro.Ubicacion}}</td>
                                            <td >{{registro.Servicio}}</td>
                                            <td >{{registro.Referencia}}</td>
                                            <td >{{registro.Contribuyente}}</td>
                                            <td align="right">{{registro.Total |currency:'USD':'symbol'}}</td>
                                            <td >{{registro.FormaPago}}</td>
                                            <td style="text-align: center;" *ngIf="activeAdmin" >
                                                <div class="row">
                                                    <div class="col-md-1"></div>
                                                    <div class="col-md-3">
                                                        <a (click)="editRegistro(registro, contentdetail)" 
                                                        placement="top" [autoClose]="true" ngbTooltip="EDITAR">
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fill-rule="evenodd"
                                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                         </a>
        
                                                    </div>
                                                </div>

                                            </td>
                                            <!-- <td *ngIf="activeAdmin">
                                                <div class="row">
                                                    <div class="col-sm-5 text-center">
                                                        <a (click)="consultarStatus(registro, content)" placement="top" ngbTooltip="Consultar Status">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                            </svg>                                                  
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-5 text-center">
                                                        <a (click)="reprocesar(registro, content)" placement="top" ngbTooltip="Reprocesar Pago">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                                                                <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
                                                            </svg>                                                
                                                        </a>
                                                                
                                                    </div>
                                                </div>
                  
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="filtereddatosreporte.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filtereddatosreporte.length}} registros</div>
                            <div *ngIf="filtereddatosreporte.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filtereddatosreporte.length}} de un total de {{filtereddatosreporte.length}} registros</div>
                        </div>
                        <div class="col-md-4" >
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="filtereddatosreporte.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination> 

                        </div>
                    </div>
                </div>
                <ng-template #content let-modal let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h5 class="modal-title">Status Operacion</h5>
                      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="!statusOperacion">Procesando operación</div>
                        <div *ngIf="statusOperacion"><h6>Status: {{statusOperacion}}</h6></div>
                        <div *ngIf="statusOperacion" class="text-center">
                            <button  class="btn  btn-success " (click)="closeModal()">Aceptar</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #contentdetail let-modal let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h5  class="modal-title">DETALLE DE TRANSACCIÓN</h5>
                      <button type="button" class="close" aria-label="Close" (click)="closeModalDetail()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                        <form (ngSubmit)="editDetail(userForm)" #userForm="ngForm" [formGroup]="myDialogFormDetail" ngNativeValidate>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10" style="border: 1px solid;">
                                    <div class="row" >
                                        <div class="col-md-12" style="text-align: center; font-weight: bold;">Ubicación</div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-md-12" style="text-align: center;">{{selectedDetalle.Ubicacion}}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-3" >
                                    <label style="font-weight: bold;">Cajero: </label>
                                    <label style="font-weight: normal;">{{selectedDetalle.Cajero}} </label>
                                </div>
                                <div class="col-md-6" >
                                    <label style="font-weight: bold;">Fecha: </label>
                                    <label style="font-weight: normal;"> {{selectedDetalle.Fecha}} {{selectedDetalle.Hora}} </label>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10" >
                                    <label style="font-weight: bold;">Referencia: </label>
                                    <label style="font-weight: normal;">{{selectedDetalle.Referencia}} </label>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10" >
                                    <label style="font-weight: bold;">Contribuyente: </label>
                                    <label style="font-weight: normal;">{{selectedDetalle.Contribuyente}} </label>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10" >
                                    <label style="font-weight: bold;">Servicio: </label>
                                    <label style="font-weight: normal;">{{selectedDetalle.Servicio}} </label>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-3" >
                                    <label style="font-weight: bold;">Monto: </label>
                                    <label style="font-weight: normal;">{{selectedDetalle.Total}} </label>
                                </div>
                                <!-- <div class="col-md-6" >
                                    <label style="font-weight: bold;">Estatus: </label>
                                    <label style="font-weight: normal;"> {{selectedDetalle.Fecha}} {{selectedDetalle.Hora}} </label>
                                </div> -->

                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-3">
                                    <label style="font-weight: bold;" for="cfe">Impresiones CFE</label>
                                </div>
                                <div class="col-md-3">
                                    <input class="form-control" id="cfe" f type="text" name="cfe"  
                                     [(ngModel)]="selectedDetalle.cfe" placeholder="Número de impresiones CFE" >
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-success">Actualizar CFE</button>
                                </div>

                            </div>

                        </form>
                    </div>
                </ng-template>
            </div>
        </main>
    </div>
</div>
