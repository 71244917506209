<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="form-group row">
                        <div class="col-md-10">
                            <h2 class="card-title">{{page_title}}</h2>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success" (click)="openModal(content)">Agregar Cajero</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <br>
                    <div class="row">
                        <div class="col-md-9"></div>
                        <div class="col-md-3 text-right">
                            <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>

                        </div>
                    </div>
                    <br>
                    <div class="table-responsive">
                        <table class="table table-striped table-sm">
                          <thead>
                            <th class="text-center">Número</th>
                            <th class="text-center">Ubicación</th>
                            <th class="text-center">Técnico</th>
                            <th class="text-center">TeamViewer</th>
                            <th class="text-center">Operaciones</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let cajero of filteredcajero | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                              <td class="text-left">{{cajero.numero}}</td>
                              <td class="text-left">{{cajero.ubicacionfisica}}</td>
                              <td class="text-left">{{cajero.tecnico}}</td>
                              <td class="text-left">{{cajero.idteamviewer}}</td>
                              <td class="text-center">
                                <a (click)="editCajero(cajero, content)" placement="top" ngbTooltip="Editar">
                                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                  </svg>
                                </a>&nbsp;
                                
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="filteredcajero.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredcajero.length}} registros</div>
                            <div *ngIf="filteredcajero.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredcajero.length}} de un total de {{filteredcajero.length}} registros</div>
                        </div>
                        <div class="col-md-4" >
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="filteredcajero.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination> 

                        </div>
                    </div>
                      <ng-template #content let-modal let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h5 class="modal-title">Detalle Cajeros</h5>
                          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <form (ngSubmit)="addCajero(userForm)" #userForm="ngForm" ngNativeValidate>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <input  type="hidden" name="id_cajero" id="id_cajero" #id_cajero="ngModel" [(ngModel)]="cajeroService.selectedCajero.id_cajero">
                                        <div class="form-group">
                                            <label for="numserie">Número de Serie *</label>
                
                                            <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                                                <input class="form-control" type="text" name="num_serie" id="num_serie" #num_serie="ngModel"
                                                    [(ngModel)]="cajeroService.selectedCajero.num_serie" placeholder="Número de Serie" required>
                                            </div>
                                        </div>                          
            
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="numero">Número de Cajero</label>
                                            <input class="form-control" type="text" name="numero" id="numero" #numero="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.numero" placeholder="Número de Cajero" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="numero">Número de Terminal</label>
                                            <input class="form-control" type="text" name="numeroTerminal" id="numeroTerminal" #numeroTerminal="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.no_terminal" placeholder="Número de Terminal" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="estado">Estado</label>
                                            <select   class="form-control" name="estado" id="estado"
                                            #estado="ngModel" [(ngModel)]="cajeroService.selectedEstado" required>
                                                <option  *ngFor="let estado of estadoService.estados" [ngValue]="estado">
                                                    {{estado?.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="municipio">Municipio</label>
                                            <select   class="form-control" name="municipio" id="municipio" 
                                            #municipio="ngModel" [(ngModel)]="cajeroService.selectedMunicipio" required>
                                                <option  *ngFor="let municipio of municipioService.municipios" [ngValue]="municipio">
                                                    {{municipio.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="statusCajero">Origen</label>
                                            <select  class="form-control" name="origen" id="origen"
                                                #origen="ngModel" [(ngModel)]="cajeroService.selectedCajero.isLocal" required>
                                                <option value="1">LOCAL</option>
                                                <option value="0">FORÁNEO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="calle">Calle</label>
                                        <input class="form-control" type="text" name="calle" id="calle" #calle="ngModel"
                                        [(ngModel)]="cajeroService.selectedCajero.calle" placeholder="Calle">
                                    </div>
                                    </div>
                                    <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="phone">Número Exterior</label>
                                        <input class="form-control" type="text" name="numext" id="numext" #numext="ngModel"
                                        [(ngModel)]="cajeroService.selectedCajero.num_ext" placeholder="Número Exterior">
                                    </div>
                                    </div>
                                    <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="phone">Número Interior</label>
                                        <input class="form-control" type="text" name="numint" id="numint" #numint="ngModel"
                                        [(ngModel)]="cajeroService.selectedCajero.num_int" placeholder="Número Interior">
                                    </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="colonia">Colonia</label>
                                            <input class="form-control" type="text" name="colonia" id="colonia" #colonia="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.colonia" placeholder="Colonia">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="cp">Código Postal</label>
                                            <input class="form-control" type="text" name="cp" id="cp" #cp="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.cp" placeholder="Código Postal">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="ubicacionfisica">Ubicación Física *</label>
                                            <input class="form-control" type="text" name="ubicacionfisica" id="ubicacionfisica" #ubicacionfisica="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.ubicacionfisica" placeholder="Ubicación Física">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="cp">Referencia</label>
                                            <input class="form-control" type="text" name="referencia" id="referencia" #referencia="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.referencia" placeholder="Referencia">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="macaddress">Dirección MAC</label>
                                            <input class="form-control" type="text" name="macaddress" id="macaddress" #macaddress="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.mac_address" placeholder="Dirección MAC">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="idteamviewer">ID Team Viewer</label>
                                            <input class="form-control" type="text" name="idteamviewer" id="idteamviewer" #idteamviewer="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.id_teamviewer" placeholder="ID Team Viewer">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="pwteamviewer">Password Team Viewer</label>
                                            <input class="form-control" type="text" name="pwteamviewer" id="pwteamviewer" #pwteamviewer="ngModel"
                                            [(ngModel)]="cajeroService.selectedCajero.pw_teamviewer" placeholder="Password Team Viewer">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="nombreTecnico">Técnico</label>
                                            <select   class="form-control" name="tecnico" id="tecnico"
                                            #tecnico="ngModel" [(ngModel)]="cajeroService.selectedUsuario" required>
                                                <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                                    {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="statusCajero">Status Cajero</label>
                                            <select  class="form-control" name="status" id="status"
                                                #status="ngModel" [(ngModel)]="cajeroService.selectedStatus" required>
                                                <option  *ngFor="let status of statusCajeroService.statusCajero" [ngValue]="status">
                                                    {{status?.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-lg-10"></div>
                                    <div class="col-lg-2">
                                    <div class="form-group text-left" >
                                        <button class="btn btn-success">Guardar</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </div>
            </div>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>