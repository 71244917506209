import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { OrigenIncidencia, TipoIncidencia } from '../models/tipoincidencia';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class TipoIncidenciaService {
  tiposincidencia: TipoIncidencia[] = [];
  allTipoIncidencia: TipoIncidencia[] = [];
  origenincidencia: OrigenIncidencia[] = [];

  constructor(private http: HttpClient) { }

  getOrigenIncidencia() {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.get(Global.URL_API + 'origenincidencia',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getTipoIncidencia() {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.get(Global.URL_API + 'tipoincidencia',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  putTipoIncidencia(tipoincidencia: TipoIncidencia) {
    
    return this.http.put(Global.URL_API + 'tipoincidencia' + `/${tipoincidencia.id_tipoincidencia}`, tipoincidencia, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  postTipoIncidencia(tipoincidencia: TipoIncidencia) {
    return this.http.post(Global.URL_API + 'tipoincidencia', tipoincidencia,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
