import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StatusIncidente } from 'src/app/models/statusincidente';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';
import { ValeCaja } from 'src/app/models/valecaja';
import { ExcelService } from 'src/app/services/excel.service';
import { StatusIncidenteService } from 'src/app/services/statusincidente.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserService } from 'src/app/services/user.service';
import { ValeCajaService } from 'src/app/services/valecaja.service';
import * as moment from 'moment';

@Component({
  selector: 'app-monitoreo-valescaja',
  templateUrl: './monitoreo-valescaja.component.html',
  styleUrls: ['./monitoreo-valescaja.component.css'],
  providers:[DatePipe]
})
export class MonitoreoValesCajaComponent implements OnInit {
  page_title = 'Vales de Caja';
  page = 1;
  pageSize = 10;
  filter = "";
  public selectedTecnico:User;
  filteredIncidencias:ValeCaja[]=[];
  isDisabled = false;  
  myForm:FormGroup;
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public selectedFiltroTecnico:User=new User();
  public selectedFiltroStatusIncidente:StatusIncidente=new StatusIncidente();
  constructor(public modal: NgbModal,public modalVale: NgbModal,public modalFecha:NgbModal,public userService:UserService,public toastService: ToastService,public statusIncidenteService: StatusIncidenteService,public datepipe: DatePipe,private excelService:ExcelService,private valeCajaService:ValeCajaService) { }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'tecnico': new FormControl(""),
      'statusincidente':new FormControl("")
    });
    this.getReporteValesCaja();
    this.getTecnicos();
    this.getStatusIncidente();

    this.statusIncidenteService.selectedStatusIncidente=new StatusIncidente();
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
 
  }
  getReporteValesCaja():void{
    
    let tecnico:number;
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    if(userData.category.name=='TECNICO'){
      tecnico=Number(userData._id);
    }else{
      tecnico=this.selectedFiltroTecnico.id_usuario;
    }
    this.valeCajaService.getReporteValesCaja(tecnico,this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'),this.selectedFiltroStatusIncidente.id_statusincidencia)
    .subscribe((res: any) => {
      this.valeCajaService.valesCaja = res.datos as ValeCaja[];

      this.filteredIncidencias=this.valeCajaService.valesCaja;

    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getStatusIncidente():void{
    this.statusIncidenteService.getStatusIncidente()
    .subscribe((res: any) => {
      this.statusIncidenteService.statusIncidentes= res.datos as StatusIncidente[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getValesCaja(): void {
        let date:Date=new Date();
        let header:string[]=["Cajero","Fecha y Hora","Importe Total Pagado","Cantidad No Devuelta","Contribuyente","Descripción Del Servicio","Referencia Pago","Folio Control","Técnico","Status"]
        let data:any[]=this.jsontoArrValeCaja();
        this.excelService.generateExcel(header,data,"Vales de Caja","A1:I1",'Vales de Caja'+this.datepipe.transform(date, 'ddMM')+'.xlsx');
  
  }





  exportExcel(){
    {
      let date:Date=new Date();
      let header:string[]=["Reporte","Cajero","Ubicación","Fecha de Reporte","Fecha de Solución","Técnico asignado","Incidencia","Status","Fecha de Incidencia","Numero de operación","Visto Bueno","Nivel de Servicio"]
      let data:any[]=this.jsontoArr();
      this.excelService.generateExcel(header,data,"Monitoreo de Incidencias","A1:M1",'MonitoreoIncidencias'+this.datepipe.transform(date, 'ddMM')+'.xlsx');
    }
  }

  jsontoArr():any[]
  {
    let data:any[]=[];
    return data;
  
  }
  exportValeCaja(){
    {
      this.getValesCaja();
    }
  }

  jsontoArrValeCaja():any[]
  {
    let data:any[]=[];
    for(var i in this.valeCajaService.valesCaja) 
    {
      let row:any[]=[
        this.valeCajaService.valesCaja[i].num_cajero,
        this.valeCajaService.valesCaja[i].fecha_hora_vale,
        this.valeCajaService.valesCaja[i].importe_total_pagado,
        this.valeCajaService.valesCaja[i].cantidad_nodevuelta,
        this.valeCajaService.valesCaja[i].contribuyente,
        this.valeCajaService.valesCaja[i].desc_servicio,
        this.valeCajaService.valesCaja[i].referencia_pago,
        this.valeCajaService.valesCaja[i].folio_control,
        this.valeCajaService.valesCaja[i].tecnico,
        this.valeCajaService.valesCaja[i].statusincidencia
      ];
      data.push(row);
    }
    return data;
  
  }




}
