<div class="row d-flex align-items-center justify-content-center mt-5">
    <div class="col-12 col-md-5">
        <img src="assets/banner-2.jpg" alt="Contacto" class="img-fluid">
    </div>
    <div class="col-12 col-md-7">
        <h1 class="h1-responsive text-center"> Contáctanos </h1>
        <form novalidate="" action="javascript:void(0);" class="ng-untouched ng-pristine ng-invalid">
            <div class="row">
                <div class="col-12">
                    <div class="form-group"><label for="name">Nombre</label><input type="text" name="name" ngmodel=""
                            id="name" required="" class="form-control ng-untouched ng-pristine ng-invalid"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group"><label for="email">Correo</label><input type="email" name="email" ngmodel=""
                            id="email" required="" class="form-control ng-untouched ng-pristine ng-invalid"></div>
                </div>
                <div class="col-6">
                    <div class="form-group"><label for="telephone">Telefono</label><input type="number" name="telephone"
                            ngmodel="" id="telephone" required=""
                            class="form-control ng-untouched ng-pristine ng-invalid"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group"><label for="message">Mensaje</label><textarea name="message" id="message"
                            cols="30" rows="10" required="" class="form-control"></textarea></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12"><button type="submit" class="btn btn-block btn-primary"> Enviar </button></div>
            </div>
        </form>
    </div>
</div>