<div class="modal-header">
    <h4 class="modal-title">Lista de invitados</h4>
    <button type="button" class="close " aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="container">
    <div class="row">
        <div class="col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Invitado</th>
                        <th scope="col">Núm. Teléfono</th>
                        <th scope="col">Entrada</th>
                        <th scope="col">Salida</th>
                        <!-- <th scope="col" *ngIf="event.idTipoInvitado != 0">Acción</th> -->
                    </tr>
                </thead>
                <tbody *ngIf="event.idTipoInvitado == 0">
                    <tr *ngFor="let g of event.Guest | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                        <td *ngIf="g.guest.NombreInvitado">{{g.guest.NombreInvitado}}</td>
                        <td *ngIf="g.guest.NumeroTelefono">{{g.guest.NumeroTelefono}}</td>
                        <td *ngIf="isDefined(g.history[0])">{{g.history[0].Hora}}</td>
                        <td *ngIf="isDefined(g.history[1])">{{g.history[1].Hora}}</td>
                        <!-- <td *ngIf="guest.NumeroTelefono">{{guest.NumeroTelefono}}</td> -->
                        <td></td>
                        <td></td>
                        <!-- <td><a href="javascript:void(0);" placement="top" ngbTooltip="???">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-trash"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </a>
                        </td> -->
                    </tr>
                </tbody>
                <tbody *ngIf="event.idTipoInvitado == 1">
                    <tr>
                        <td *ngIf="event.NombreInvitado !== undefined">{{event.NombreInvitado}}</td>
                        <td>NA</td>
                        <td *ngIf="isDefined(event.History[0])">{{event.History[0].Hora}}</td>
                        <td *ngIf="isDefined(event.History[1])">{{event.History[1].Hora}}</td>
                        <!-- <td *ngIf="event.History[0] !== undefined">{{event.NombreInvitado}}</td> -->
                        <!-- <td><a href="javascript:void(0);">Eliminar</a></td> -->
                    </tr>
                </tbody>
                <tbody *ngIf="event.idTipoInvitado == 2">
                    <tr>
                        <td *ngIf="event.nombreProveedor !== undefined">{{event.nombreProveedor}}</td>
                        <td>NA</td>
                        <td *ngIf="isDefined(event.History[0])">{{event.History[0].Hora}}</td>
                        <td *ngIf="isDefined(event.History[1])">{{event.History[1].Hora}}</td>
                        <!-- <td><a href="javascript:void(0);">Eliminar</a></td> -->
                    </tr>
                </tbody>
                <tbody *ngIf="event.idTipoInvitado == 3">
                    <tr>
                        <td *ngIf="event.nombreEmpleado !== undefined">{{event.nombreEmpleado}}</td>
                        <td>NA</td>
                        <td *ngIf="isDefined(event.History[0])">{{event.History[0].Hora}}</td>
                        <td *ngIf="isDefined(event.History[1])">{{event.History[1].Hora}}</td>
                        <!-- <td><a href="javascript:void(0);">Eliminar</a></td> -->
                    </tr>
                </tbody>
            </table>
            <!-- <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="eventService.events.length"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination> -->
        </div>
    </div>
</div>