import { Component, OnInit } from '@angular/core';
import { Console } from 'console';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userData: UserData;
  page_title = 'Tablero de mandos';
 
  constructor() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
   }
  ngOnInit(): void {
    // /home/jcruzftp/ftp/flotillasWeb/src/app/components/dashboard/dashboard.component.ts
  }

}
