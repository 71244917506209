import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Cajero } from 'src/app/models/cajero';
import { ResReporteDetalleServicios } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { CajeroService } from 'src/app/services/cajero.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { ExcelService } from 'src/app/services/excel.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { ReprocesarService } from 'src/app/services/reprocesar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResConsultaPago } from 'src/app/models/reprocesar';
@Component({
  selector: 'app-reprocesar',
  templateUrl: './reprocesar.component.html',
  styleUrls: ['./reprocesar.component.css'],
  providers: [DatePipe]
})

export class ReprocesarComponent implements OnInit {
  public selectedCajero:string="";
  public selectedServicio:Servicio=new Servicio();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public filtereddatosreporte: ResReporteDetalleServicios[] = [];
  activeModal: NgbModalRef;
  myForm:FormGroup;
  page = 1;
  pageSize = 10;
  filter="";
  statusOperacion="";

  page_title = 'Reprocesar Referencias de Pago';

  constructor(public reprocesarService:ReprocesarService,public reporteService:ReportesService,public datepipe: DatePipe,private excelService:ExcelService,public modal: NgbModal) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'servicio': new FormControl(""),
      'cajero': new FormControl("")
    });
    this.getReporteDetalle();
  }

  getReporteDetalle(): void {
    this.reporteService.getReporteDetalleServicios(this.selectedServicio,Number(this.selectedCajero==""?"0":this.selectedCajero),this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'))
      .subscribe((res: any) => {
        this.reporteService.datosreporteDetalles = res.data as ResReporteDetalleServicios[];
        this.filtereddatosreporte=this.reporteService.datosreporteDetalles;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  filterChange(e){
    this.filtereddatosreporte=this.reporteService.datosreporteDetalles.filter((val) => {
      let rVal = (val.Referencia==e)
      return rVal;
    })

  }
  consultarStatus(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                console.log(res)
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postConsultaInformacion(token,registro.Referencia).subscribe((res: any) => {
                    this.statusOperacion=res.estatusDesc;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  reprocesar(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                console.log(res)
                let payment=res as ResConsultaPago;

                this.reprocesarService.postRenotificacion("309F1E78-E02E-44D7-BADF-200521D1E716",payment,registro).subscribe((res: any) => {
                    console.log(res);


                }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                  })
    

            }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postReprocesar(token,registro).subscribe((res: any) => {
                    this.statusOperacion=res.mensajeCiudadano;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'md' });
    this.activeModal.result.then(res => {
      this.statusOperacion="";
    }).catch(() => {
        this.statusOperacion="";
    });
  }
  closeModal(){
    this.statusOperacion = "";
    this.activeModal.close();
    
  }

}
