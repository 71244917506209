<app-main-navbar></app-main-navbar>
<!--Main Layout-->
<main>
    <div class="container-fluid">
        <div class="row mt-5 pt-5">
            <div class="col text-center">
                <!-- <app-inicio id="inicio"></app-inicio>
                <app-features id="features"></app-features>
                <app-solutions id="solutions"></app-solutions>
                <app-contact id="contact"></app-contact> -->
            </div>
        </div>
    </div>
</main>
<!--Main Layout-->
