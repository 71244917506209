import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserData } from '../models/user-data';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    let date=new Date();

    if (user&&user.vigencia && (user.category.name == "ADMINISTRADOR" || user.category.name == "MONITOREO")) {
      let vigencia=new Date(user.vigencia);
      if(vigencia.getTime()>date.getTime()){
        return true;
      }
      this.router.navigateByUrl('/login');
      return false;
    }
    else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }

}
