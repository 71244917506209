import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Cajero } from '../models/cajero';
import { DatosReporteGeneralServicios,DatosReporteServicios,ResReporteCompulsa,ResReporteDetalleServicios } from '../models/reportes';
import { Servicio } from '../models/servicio';
import { StatusCajero } from '../models/statuscajero';
import { User } from '../models/user';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  datosreporteGeneral: DatosReporteGeneralServicios = new DatosReporteGeneralServicios();
  datosreporteServicios: DatosReporteServicios=new DatosReporteServicios();
  datosreporteDetalles: ResReporteDetalleServicios[] = [];
  datosreporteCompulsa: ResReporteCompulsa[]=[];
  datostmas1: any[] = [];
  constructor(private http: HttpClient) { }

  getReporteGeneralServicios(servicio:Servicio,cajero:number,tecnico:number,fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin,numcajero:cajero,idtecnico:tecnico,idservicio:servicio.id_servicio};
    return this.http.post(Global.URL_API + 'reportes/generalservicios', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getReporteServicios(fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin};
    return this.http.post(Global.URL_API + 'reportes/servicios', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getReporteTmas1(fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin};
    return this.http.post(Global.URL_API + 'reportes/tmas1', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getReporteTmas1Tarjeta(fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin};
    return this.http.post(Global.URL_API + 'reportes/tmas1tarjeta', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }  
  getReporteDetalleServicios(servicio:Servicio,cajero:number,fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin,numcajero:cajero,idservicio:servicio.id_servicio};
    return this.http.post(Global.URL_API + 'reportes/detalleservicios', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getReporteCompulsa(fechaInicio:string,fechafin:string) {
    const body={fechainicio:fechaInicio,fechafin:fechafin};
    return this.http.post(Global.URL_API + 'reportes/compulsa', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  putCFE(detalle:ResReporteDetalleServicios) {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.put(Global.URL_API + 'operacioncajerocfe' , {referencia:detalle.Referencia,cfe:detalle.cfe}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
