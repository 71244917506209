import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Position } from '../models/position';
import { Global } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  selectedPosition: Position;
  positions: Position[];

  constructor(private http: HttpClient) {
    this.selectedPosition = new Position();
    this.positions = [];
   }

   getPositions() {
    return this.http.get(Global.URL_API + 'position/positions',{headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': JSON.parse(localStorage.getItem('userData')).token
    })});
  }

  postPosition(position: Position) {
    return this.http.post(Global.URL_API + 'position/savePosition', position,{headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': JSON.parse(localStorage.getItem('userData')).token
    })});
  }

  putPosition(position: Position) {
    return this.http.put(Global.URL_API + 'positions' + `/${position._id}`, position);
  }

  deletePosition(_id: string) {
    return this.http.delete(Global.URL_API + 'positions' + `/${_id}`);
  }
}
