<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="form-group row">
                        <div class="col-md-8">
                            <h2 class="card-title">{{page_title}}</h2>
                        </div>
                        <div class="col-md-2">
                            <button *ngIf="addvalecaja" class="btn btn-success" (click)="openModalVale(contentvale)">Agregar Vale de Caja</button>
                        </div>
                        <div class="col-md-2">
                            <button *ngIf="addIncidentes" class="btn btn-success" (click)="openModal(content)">Agregar Incidencia</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <form (ngSubmit)="getIncidencias()" #filtrosForm="ngForm" [formGroup]="myForm" >
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="name">Fecha inicio</label>
                                <input class="form-control" id="fechaInicio" f type="date" name="fechaInicio" 
                                [(ngModel)]="selectedFechaInicio" placeholder="Fecha inicio" >
                            
                            </div>
                            <div class="col-md-4">
                                <label for="name">Fecha fin</label>
                                <input class="form-control" id="fechafin" type="date" name="fechafin" #fechafin="ngModel"
                                [(ngModel)]="selectedFechaFin" placeholder="Fecha fin">
                            </div>
                            <div class="col-md-4">
                                <label for="select1">Status Incidente</label>
                                <select  class="form-control" name="statusincidente" id="statusincidente"
                                    #statusincidente="ngModel" [(ngModel)]="selectedFiltroStatusIncidente" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let statusIncidente of statusIncidenteService.statusIncidentes" [ngValue]="statusIncidente">
                                        {{statusIncidente?.name}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="form-group row" *ngIf="!addvalecaja">
                            <div class="col-md-4">
                                <label for="select1">Cajeros</label>
                                <ng-select name="cajero" id="cajero" [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedFiltroCajero">
                                </ng-select>
                                
                            </div>
                            <div class="col-md-4">
                                <label for="select1">Técnicos</label>
                                <select  class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedFiltroTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="select1">Tipo de Incidencia</label>
                                <select   class="form-control" name="tipoincidencia" id="tipoincidencia"  aria-placeholder="Seleccione el tipo de incidencia"
                                #tipoincidencia="ngModel" [(ngModel)]="selectedFiltroTipoIncidente" required [disabled]="isDisabled" >
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tipoincidencia of tipoIncidenciaService.tiposincidencia" [ngValue]="tipoincidencia">
                                            {{tipoincidencia.name}}</option>
                                </select>
                            
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="addvalecaja">
                            <div class="col-md-3">
                                <label for="select1">Cajeros</label>
                                <ng-select name="cajero" id="cajero" [items]="cajeroService.cajeros"
                                            bindLabel="tempLabel"
                                            bindValue="numero"
                                            placeholder="SELECCIONAR TODOS"
                                            notFoundText="ELEMENTO NO ENCONTRADO"
                                            [(ngModel)]="selectedFiltroCajero">
                                </ng-select>
                                
                            </div>
                            <div class="col-md-3">
                                <label for="select1">Técnicos</label>
                                <select  class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedFiltroTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="select1">Tipo de Incidencia</label>
                                <select   class="form-control" name="tipoincidencia" id="tipoincidencia"  aria-placeholder="Seleccione el tipo de incidencia"
                                #tipoincidencia="ngModel" [(ngModel)]="selectedFiltroTipoIncidente" required [disabled]="isDisabled" >
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tipoincidencia of tipoIncidenciaService.tiposincidencia" [ngValue]="tipoincidencia">
                                            {{tipoincidencia.name}}</option>
                                </select>
                            
                            </div>
                            <div class="col-md-3">
                                <label for="select1">Usuario que Registró</label>
                                <select   class="form-control" name="usuarios" id="usuarios"  aria-placeholder="Seleccione el usuario"
                                #usuarios="ngModel" [(ngModel)]="selectedFiltroUsuario" required [disabled]="isDisabled" >
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let usuario of userService.users" [ngValue]="usuario">
                                        {{usuario?.nombres+' '+usuario?.ap_paterno+' '+usuario?.ap_materno}}</option>
                                </select>
                            
                            </div>
                        </div>
                        <div class="form-group row">
            
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button  class="btn  btn-success">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Exportar
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" (click)="exportExcel()">Excel</a>
                                  <a *ngIf="addvalecaja" class="dropdown-item" (click)="exportValeCaja()">Vales de Caja</a>
                                  <!-- <a class="dropdown-item" (click)="exportPDF()">PDF</a> -->
                                </div>
                              </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-right">
                            <input class="form-control" type="text" [(ngModel)]="filter" (ngModelChange)="filterChange($event)" placeholder="Buscar"/>

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reporte</th>
                                            <th scope="col">Cajero</th>
                                            <th scope="col">Ubicación</th>
                                            <th scope="col">Fecha de Incidencia</th>
                                            <th scope="col">Fecha de Solución</th>
                                            <!-- <th scope="col">Tiempo de solución</th> -->
                                            <th scope="col">Técnico asignado</th>
                                            <th scope="col">Incidencia</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Operaciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of filteredIncidencias | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                            <td >{{item.reporte}}</td>
                                            <td >{{item.num_cajero}}</td>
                                            <td >{{item.ubicacionfisica}}</td>
                                            <td >{{item.sfecha_ticket.substring(0,10)}} {{item.shora_ticket.substring(11,19) }}</td>
                                            <td *ngIf="item.fecharesuelto">{{item.fecharesuelto?.substring(0,10)}} {{item.hora_resuelto.substring(11,19)}}</td>
                                            <td *ngIf="!item.fecharesuelto"></td>
                                            <!-- <td >{{(item.diferencia/1440).toString().split('.')[0]}} días {{((item.diferencia%1440)/60).toString().split('.')[0]}} horas {{item.diferencia%60}} minutos</td> -->
                                            <td >{{item.tecnico}}</td>
                                            <td >{{item.tipo_incidencia}}</td>
                                            <td >{{item.status_incidencia}}</td>
                                            <td style="text-align: center;" *ngIf="activeIncidentes" >
                                                <div class="row">
                                                    <div class="col-md-1"></div>
                                                    <div class="col-md-3">
                                                        <a (click)="editIncidencia(item, content)" 
                                                        placement="top" [autoClose]="true" ngbTooltip="EDITAR">
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fill-rule="evenodd"
                                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                         </a>
        
                                                    </div>
                                                    <div *ngIf="item.status_incidencia=='RESUELTO'" class="col-md-3">
                                                        <a (click)="openModalFecha(item, contentfechacierre)" 
                                                        placement="top" [autoClose]="true" ngbTooltip="CAMBIAR FECHA DE SOLUCIÓN">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                          </svg>                                                         </a>
                                                                
                                                    </div>
                                                    <div class="col-md-2"></div>
                                                </div>

                                            </td>
                                            <td style="text-align: center;" *ngIf="!activeIncidentes" >
                                                <a (click)="editIncidencia(item, content)" 
                                                placement="top" [autoClose]="true" ngbTooltip="VER DETALLE">
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fill-rule="evenodd"
                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>
                                                 </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                         </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="filteredIncidencias.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredIncidencias.length}} registros</div>
                            <div *ngIf="filteredIncidencias.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredIncidencias.length}} de un total de {{filteredIncidencias.length}} registros</div>
                        </div>
                        <div class="col-md-4" >
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="filteredIncidencias.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination> 

                        </div>
                    </div>
                    <ng-template #content let-modal let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h5 *ngIf="!isDisabled" class="modal-title">ALTA DE INCIDENCIA</h5>
                          <h5 *ngIf="isDisabled" class="modal-title">EDICIÓN DE INCIDENCIA</h5>
                          <button type="button" class="close" aria-label="Close" (click)="closeModal(userForm)">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <form (ngSubmit)="addIncidencia(userForm)" #userForm="ngForm" [formGroup]="myDialogForm" ngNativeValidate>
                                
                                <div class="form-group row">
                                    <div class="col-md-4">
                                        <label for="select1">Cajero</label>
                                        <ng-select *ngIf="!isDisabled" name="cajeros" id="cajeros" [items]="cajeroService.cajeros"
                                                    bindLabel="tempLabel"
                                                    bindValue="numero"
                                                    placeholder="SELECCIONAR CAJERO"
                                                    notFoundText="ELEMENTO NO ENCONTRADO"
                                                    [(ngModel)]="selectedCajero">
                                        </ng-select>
                                    <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.num_cajero}} {{incidenciaService.selectedIncidencia.ubicacionfisica.length>22?incidenciaService.selectedIncidencia.ubicacionfisica.substring(0,22):incidenciaService.selectedIncidencia.ubicacionfisica}}</div>
                           
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="usuariofinal">Usuario que reporta</label>
                                            <input *ngIf="!isDisabled" class="form-control upper" type="text" name="usuariofinal" id="usuariofinal" #usuariofinal="ngModel"
                                            [(ngModel)]="incidenciaService.selectedIncidencia.usuario_final" placeholder="Usuario que reporta" required [disabled]="isDisabled">
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.usuario_final}}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <!-- <input  type="hidden" name="id_incidencia" id="id_incidencia"  [(ngModel)]="incidenciaService.selectedIncidencia.id_incidencia"> -->
                                        <div class="form-group">
                                            <label for="telusuariofinal">Teléfono de quien reporta</label>
                
                                            <div *ngIf="!isDisabled" class="input-group">
                                                <input class="form-control" type="text" name="telusuariofinal" id="telusuariofinal" 
                                                    [(ngModel)]="incidenciaService.selectedIncidencia.tel_usuario_final" placeholder="TELÉFONO DE QUIEN REPORTA"  [disabled]="isDisabled">
                                            </div>
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.tel_usuario_final}}</div>
                                        </div>                          
            
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isDisabled">
                                    <div class="col-md-6">
                                        <label for="fecha_ticket">Fecha de incidencia</label>

                                        <input class="form-control" id="fecha_ticket" f type="date" name="fecha_ticket"  
                                         [(ngModel)]="incidenciaService.selectedIncidencia.fecha_ticketd" placeholder="Fecha de Incidencia" >
                                    </div>
                                    <div class="col-md-6">
                                        <label for="hora_ticket">Hora de incidencia</label>
                                        <input class="form-control" id="hora_ticket" f type="time" name="hora_ticket"  
                                         [(ngModel)]="incidenciaService.selectedIncidencia.hora_tickett" placeholder="Hora de Incidencia" >
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isDisabled">
                                    <div class="col-md-6">
                                        <label for="fecha_ticket">Fecha de incidencia</label>
                                        <div class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.sfecha_ticket.substring(0,10)}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="hora_ticket">Hora de incidencia</label>
                                        <div class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.shora_ticket.substring(11,19)}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label for="tipoincidencia">Tipo de incidencia</label>
                                        <select *ngIf="!isDisabled"  class="form-control" name="tipoincidencia" id="tipoincidencia"  aria-placeholder="Seleccione el tipo de incidencia"
                                         [(ngModel)]="incidenciaService.selectedTipoIncidencia" required [disabled]="isDisabled" >
                                            <option  *ngFor="let tipoincidencia of tipoIncidenciaService.tiposincidencia" [ngValue]="tipoincidencia">
                                                {{tipoincidencia.name}}</option>
                                        </select>
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedTipoIncidencia.name}}</div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="folio_control">Número de operación</label>
                
                                            <div *ngIf="!isDisabled" class="input-group">
                                                <input class="form-control" type="text" name="folio_control" id="folio_control"
                                                    [(ngModel)]="incidenciaService.selectedIncidencia.folio_control" placeholder="Número de operación" [disabled]="isDisabled">
                                            </div>
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.folio_control}}</div>
                                        </div>                          
                                    </div>
                                </div>
                                <h5 class="card-title" *ngIf="activeIncidentes&&isDisabled">Avance</h5>
                                <hr *ngIf="activeIncidentes&&isDisabled">
                                <div class="form-group row" *ngIf="activeIncidentes&&isDisabled">
                                    <div class="col-lg-4">
                                        <label for="status">Status de Incidencia</label>
                                        <select   class="form-control" name="statusincidente" id="statusincidente"  aria-placeholder="Seleccione el status de incidente"
                                         [(ngModel)]="statusIncidenteService.selectedStatusIncidente" required >
                                            <option  *ngFor="let statusincidente of statusIncidenteService.statusIncidentes" [ngValue]="statusincidente">
                                                {{statusincidente.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4">
                                        <label for="nivelservicio">Nivel de servicio</label>
                                        <select   class="form-control" name="nivelservicio" id="nivelservicio"  aria-placeholder="Seleccione el nivel de servicio"
                                         [(ngModel)]="nivelServicioService.selectedNivelServicio" required >
                                            <option  *ngFor="let nivelservicio of nivelServicioService.nivelesServicio" [ngValue]="nivelservicio">
                                                {{nivelservicio.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="folio_Control">Visto bueno</label>
                
                                            <div class="input-group">
                                                <input class="form-control upper" type="text" name="vobo" id="vobo" 
                                                    [(ngModel)]="incidenciaService.selectedIncidencia.visto_bueno" placeholder="Visto bueno" >
                                            </div>
                                        </div>                          
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="statusIncidenteService.selectedStatusIncidente?.id_statusincidencia==3&&activeIncidentes" >
                                    <div class="col-lg-12">
                                        <label for="origenincidencia">Origen de incidencia</label>
                                        <select   class="form-control" name="origenincidencia" id="origenincidencia"  aria-placeholder="Seleccione el origen de incidencia"
                                         [(ngModel)]="incidenciaService.selectedOrigenIncidencia" required >
                                            <option  *ngFor="let origenincidencia of tipoIncidenciaService.origenincidencia" [ngValue]="origenincidencia">
                                                {{origenincidencia.name}}</option>
                                        </select>
                                    </div>

                                </div>
                                <div class="form-group row" *ngIf="activeIncidentes||(addIncidentes&&!isDisabled)">
                                    <div class="col-md-12">
                                        <label for="Observaciones">Comentarios</label>
                                        <textarea class="form-control" type="text" name="Observaciones" id="Observaciones" 
                                        [(ngModel)]="incidenciaService.selectedIncidencia.comentarios"  required></textarea>
                                    </div>
                                </div>
                                <h5 *ngIf="isDisabled">Bitácora</h5>
                                <hr *ngIf="isDisabled">
                                <div class="row" *ngIf="isDisabled">
                                    <div class="col-12">
                                        <div class="table-wrapper">
                                            <table class="table table-striped table-bordered" data-toggle="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Fecha</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Usuario que actualizó</th>
                                                        <th scope="col">Comentarios</th>
                                                        <th scope="col">Visto bueno</th>
                                                        <th scope="col">Nivel servicio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let detalle of incidenciaService.detalles ">
                                                        <td >{{detalle.fecha.substring(0,10)}}</td>
                                                        <td >{{detalle.statusincidencia}}</td>
                                                        <td >{{detalle.usuario}}</td>
                                                        <td>{{detalle.comentarios}}</td>
                                                        <td>{{detalle.vobo}}</td>
                                                        <td>{{detalle.nivelservicio}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                
                                        </div>
                                    </div>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-10"></div>
                                    <div class="col-lg-2">
                                    <div class="form-group text-left" *ngIf="activeIncidentes||(addIncidentes&&!isDisabled)">
                                        <button class="btn btn-success">Guardar</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                    <ng-template #contentvale let-modal let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h5  class="modal-title">ALTA VALE DE CAJA</h5>
                          <button type="button" class="close" aria-label="Close" (click)="closeModalVale(userForm)">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <form (ngSubmit)="addValeCaja(userForm)" #userForm="ngForm" [formGroup]="myDialogFormVale" ngNativeValidate>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="select1">Cajero</label>
                                        <ng-select name="cajeros" id="cajeros" [items]="cajeroService.cajeros"
                                                    bindLabel="tempLabel"
                                                    bindValue="numero"
                                                    placeholder="SELECCIONAR CAJERO"
                                                    notFoundText="ELEMENTO NO ENCONTRADO"
                                                    [(ngModel)]="valeCajaService.selectedValeCaja.num_cajero">
                                        </ng-select>
                                        <!-- <input *ngIf="!isDisabled" class="form-control" type="text" id="cajeros" name="cajeros" list="cajeros" [(ngModel)]="selectedCajero" (ngModelChange)="onChangeCajero()" value="{{selectedCajeroObj.numero}} - {{selectedCajeroObj.ubicacionfisica}}"
                                        placeholder="SELECCIONAR CAJERO" required [disabled]="isDisabled">
                                        <datalist id="cajeros">
                                            <option *ngFor="let cajero of cajeroService.cajeros" [value]="cajero.numero" >{{cajero.ubicacionfisica}}</option>
                                        </datalist>  -->
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.num_cajero}} {{incidenciaService.selectedIncidencia.ubicacionfisica.length>22?incidenciaService.selectedIncidencia.ubicacionfisica.substring(0,22):incidenciaService.selectedIncidencia.ubicacionfisica}}</div>
                           
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="usuariofinal">Folio Control</label>
                                            <input *ngIf="!isDisabled" class="form-control upper" type="text" name="foliocontrol" id="foliocontrol" #foliocontrol="ngModel"
                                            [(ngModel)]="valeCajaService.selectedValeCaja.folio_control" placeholder="FOLIO CONTROL" required [disabled]="isDisabled">
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.usuario_final}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="usuariofinal">Usuario que reporta</label>
                                            <input *ngIf="!isDisabled" class="form-control upper" type="text" name="usuariofinal" id="usuariofinal" #usuariofinal="ngModel"
                                            [(ngModel)]="valeCajaService.selectedValeCaja.usuario_final" placeholder="Usuario que reporta" required [disabled]="isDisabled">
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.usuario_final}}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <!-- <input  type="hidden" name="id_incidencia" id="id_incidencia"  [(ngModel)]="incidenciaService.selectedIncidencia.id_incidencia"> -->
                                        <div class="form-group">
                                            <label for="telusuariofinal">Teléfono de quien reporta</label>
                
                                            <div *ngIf="!isDisabled" class="input-group">
                                                <input class="form-control" type="text" name="telusuariofinal" id="telusuariofinal" 
                                                    [(ngModel)]="valeCajaService.selectedValeCaja.tel_usuario_final" placeholder="TELÉFONO DE QUIEN REPORTA" required [disabled]="isDisabled">
                                            </div>
                                            <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.tel_usuario_final}}</div>
                                        </div>                          
            
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isDisabled">
                                    <div class="col-md-4">
                                        <label for="select1">Servicio</label>
                                        <select  class="form-control" name="servicio" id="servicio"
                                         [(ngModel)]="servicioService.selectedServicio" required>
                                            <option  *ngFor="let servicio of servicioService.servicios" [ngValue]="servicio">
                                                {{servicio?.name}}</option>
                                        </select>
                                            <!-- <input *ngIf="!isDisabled" class="form-control" type="text" id="cajeros" name="cajeros" list="cajeros" [(ngModel)]="selectedCajero" (ngModelChange)="onChangeCajero()" value="{{selectedCajeroObj.numero}} - {{selectedCajeroObj.ubicacionfisica}}"
                                        placeholder="SELECCIONAR CAJERO" required [disabled]="isDisabled">
                                        <datalist id="cajeros">
                                            <option *ngFor="let cajero of cajeroService.cajeros" [value]="cajero.numero" >{{cajero.ubicacionfisica}}</option>
                                        </datalist>  -->
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.num_cajero}} {{incidenciaService.selectedIncidencia.ubicacionfisica.length>22?incidenciaService.selectedIncidencia.ubicacionfisica.substring(0,22):incidenciaService.selectedIncidencia.ubicacionfisica}}</div>
                           
                                    </div>
                                    <div class="col-md-4">
                                        <label for="fecha_ticket">Fecha Vale de Caja</label>

                                        <input class="form-control" id="fecha_ticket" f type="date" name="fecha_ticket"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.fecha_ticketd" placeholder="Fecha de Incidencia" >
                                    </div>
                                    <div class="col-md-4">
                                        <label for="hora_ticket">Hora Vale de Caja</label>
                                        <input class="form-control" id="hora_ticket" f type="time" name="hora_ticket"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.hora_tickett" placeholder="Hora de Incidencia" >
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isDisabled">
                                    <div class="col-md-6">
                                        <label for="fecha_ticket">Fecha Vale de Caja</label>
                                        <div class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.sfecha_ticket.substring(0,10)}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="hora_ticket">Hora Vale de Caja</label>
                                        <div class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedIncidencia.shora_ticket.substring(11,19)}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-3">
                                        <label for="hora_ticket">Importe de operación</label>
                                        <input class="form-control" id="total_operacion" f type="text" name="total_operacion"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.total_operacion" >
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedTipoIncidencia.name}}</div>
                                    </div>
                                    <div class="col-lg-3">
                                        <label for="hora_ticket">Monto ingresado</label>
                                        <input class="form-control" id="cantidad_recibida" f type="text" name="cantidad_recibida"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.cantidad_recibida" placeholder="Hora de Incidencia" >
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedTipoIncidencia.name}}</div>
                                    </div>
                                    <div class="col-lg-3">
                                        <label for="hora_ticket">Monto devuelto</label>
                                        <input class="form-control" id="cantidad_devuelta" f type="text" name="cantidad_devuelta"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.cantidad_devuelta" placeholder="Hora de Incidencia" >
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedTipoIncidencia.name}}</div>
                                    </div>
                                    <div class="col-lg-3">
                                        <label for="hora_ticket">Monto no devuelto</label>
                                        <input class="form-control" id="cantidad_nodevuelta" f type="text" name="cantidad_nodevuelta"  
                                         [(ngModel)]="valeCajaService.selectedValeCaja.cantidad_nodevuelta" placeholder="Hora de Incidencia" >
                                        <div *ngIf="isDisabled" class="form-control" style="background-color: #E9ECEF;">{{incidenciaService.selectedTipoIncidencia.name}}</div>
                                    </div>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-10"></div>
                                    <div class="col-lg-2">
                                    <div class="form-group text-left">
                                        <button class="btn btn-success">Guardar</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                    <ng-template #contentfechacierre let-modal let-c="close" let-d="dismiss">
                        <div class="modal-header">
                          <h5  class="modal-title">FECHA DE SOLUCIÓN</h5>
                          <button type="button" class="close" aria-label="Close" (click)="closeModalFecha(userForm)">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <form (ngSubmit)="editFechaIncidente(userForm)" #userForm="ngForm" [formGroup]="myDialogFormFecha" ngNativeValidate>
                                <div class="form-group row" *ngIf="!isDisabled">
                                    <div class="col-md-6">
                                        <label for="fecha_ticket">Fecha Solución</label>

                                        <input class="form-control" id="fecha_resueltod" f type="date" name="fecha_resueltod"  
                                         [(ngModel)]="incidenciaService.selectedIncidencia.fecha_resueltod" placeholder="Fecha de Incidencia" >
                                    </div>
                                    <div class="col-md-6">
                                        <label for="hora_ticket">Hora Solución</label>
                                        <input class="form-control" id="hora_resueltot" f type="time" name="hora_resueltot"  
                                         [(ngModel)]="incidenciaService.selectedIncidencia.hora_resueltot" placeholder="Hora de Incidencia" >
                                    </div>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-10"></div>
                                    <div class="col-lg-2">
                                    <div class="form-group text-left">
                                        <button class="btn btn-success">Guardar</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>

                </div>
            </div>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>