import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { TipoInmueble } from '../models/tipo-inmueble';
import { Inmueble } from '../models/inmueble';

@Injectable({
  providedIn: 'root'
})
export class InmuebleService {
  selectedInmueble: Inmueble;
  inmuebles: Inmueble[];

  constructor(private http: HttpClient) {
    this.selectedInmueble = new Inmueble();
    this.inmuebles = [];
    
  }

  getInmuebles() {
    return this.http.get(Global.URL_API + 'property/getProperty', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  postInmueble(inmueble: Inmueble) {
    return this.http.post(Global.URL_API + 'property/saveProperty', inmueble, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  putInmueble(inmueble: Inmueble) {
    return this.http.put(Global.URL_API + 'property/Property' + `/${inmueble._id}`, inmueble, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

  deleteInmueble(_id: string) {
    return this.http.delete(Global.URL_API + 'property/Property' + `/${_id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

}
