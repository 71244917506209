import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Cajero } from 'src/app/models/cajero';
import { ResReporteCompulsa, ResReporteDetalleServicios } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { CajeroService } from 'src/app/services/cajero.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { ExcelService } from 'src/app/services/excel.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { ReprocesarService } from 'src/app/services/reprocesar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResConsultaPago } from 'src/app/models/reprocesar';
import { UserData } from 'src/app/models/user-data';
import { ToastService } from 'src/app/services/toastservice.service';
@Component({
  selector: 'app-reporte-compulsa',
  templateUrl: './reporte-compulsa.component.html',
  styleUrls: ['./reporte-compulsa.component.css'],
  providers: [DatePipe]
})

export class ReporteCompulsaComponent implements OnInit {
  public selectedCajero:number=0;
  public selectedServicio:Servicio=new Servicio();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public selectedDetalle: ResReporteCompulsa=new ResReporteCompulsa();
  public datosreporte: ResReporteCompulsa[] = [];
  myDialogFormDetail:FormGroup;
  activeModalDetail: NgbModalRef;
  myForm:FormGroup;
  activeAdmin:boolean=false;
  page = 1;
  pageSize = 10;
  filter="";
  activeModal: NgbModalRef;
  statusOperacion="";

  page_title = 'Reporte Compulsas';

  constructor(public toastService: ToastService,public modalDetail:NgbModal,public reprocesarService:ReprocesarService,public cajeroService:CajeroService,public servicioService:ServicioService,public reporteService:ReportesService,public datepipe: DatePipe,private excelService:ExcelService,public modal: NgbModal) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat)
    });
    this.getCajeros();
    this.getServicios();
    this.getReporteDetalle();
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    this.activeAdmin=(user&&(user.category.name=='ADMINISTRADOR'))
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
        index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
      this.servicioService.servicios=this.servicioService.servicios.filter((val) => {
        let rVal = (val.padre==0) 
        return rVal;
      });
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  getReporteDetalle(): void {
    this.reporteService.getReporteCompulsa(this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'))
      .subscribe((res: any) => {
        this.reporteService.datosreporteCompulsa = res.data as ResReporteCompulsa[];
        this.datosreporte=this.reporteService.datosreporteCompulsa;
        console.log(this.datosreporte);
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  exportPDF(){
    const doc = new jsPDF()
    let data:any[]=this.jsontoArr();
    autoTable(doc, {   columnStyles: { europe: { halign: 'center' } }, // European countries centered
    body: data,
    columns: [
      { header: 'Fecha', dataKey: 'fecha' },
      { header: 'Transacciones T+1', dataKey: 'transaccionescompulsa' },
      { header: 'Importe T+1', dataKey: 'importecompulsa' },
      { header: 'Transacciones actuales', dataKey: 'transaccionesactual' },
      { header: 'Importe Actual', dataKey: 'importeactual' }
    ], })
    doc.save('ReporteCompulsa'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM')+'.pdf')

  }
  jsontoArr():any[]
  {
    let data:any[]=[];
    for(var i in this.reporteService.datosreporteCompulsa) 
    {
      let row:any[]=[this.reporteService.datosreporteCompulsa[i].fecha,this.reporteService.datosreporteCompulsa[i].transaccionescompulsa,this.reporteService.datosreporteCompulsa[i].importecompulsa,this.reporteService.datosreporteCompulsa[i].transaccionesactual,this.reporteService.datosreporteCompulsa[i].importeactual];
      data.push(row);
    }
    return data;
  
  }
  exportExcel(){
    {
      let header:string[]=["Fecha","Transacciones T+1","Importe T+1","Transacciones actuales","Importe Actual"]
      let data:any[]=this.jsontoArr();
       /* table id is passed over here */   
      this.excelService.generateExcel(header,data,"Reporte Compulsa","A1:H1",'ReporteCompulsa'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM')+'.xlsx');
    }
  }
  
  //   let header=["Cajero","Fecha","Ubicación","Servicio","Referencia","Contribuyente","Total"]
  //   this.excelService.exportAsExcelFile(this.reporteService.datosreporteDetalles, 'ReporteDetalleServicios'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM'),header);

  // }
  consultarStatus(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                //descripcion_estatus
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postConsultaInformacion(token,registro.Referencia).subscribe((res: any) => {
                    this.statusOperacion=res.estatusDesc;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  reprocesar(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                let payment=res as ResConsultaPago;

                this.reprocesarService.postRenotificacion("309F1E78-E02E-44D7-BADF-200521D1E716",payment,registro).subscribe((res: any) => {
                    console.log(res);


                }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                  })
    

            }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postReprocesar(token,registro).subscribe((res: any) => {
                    this.statusOperacion=res.mensajeCiudadano;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'md' });
    this.activeModal.result.then(res => {
      this.statusOperacion="";
    }).catch(() => {
        this.statusOperacion="";
    });
  }
  closeModal(){
    this.statusOperacion = "";
    this.activeModal.close();
    
  }
  editRegistro(detalle:ResReporteCompulsa,content)
  {
    this.selectedDetalle=detalle;
    this.myDialogFormDetail = new FormGroup({
      'cfe': new FormControl("")
    });
    this.activeModalDetail = this.modalDetail.open(content, { size: 'md' });
    this.activeModalDetail.result.then(res => {
      
    }).catch(() => {
      
    });


    
  }
  closeModalDetail(){
    this.activeModalDetail.close();
  }
  editDetail(form?: NgForm) {


    this.activeModalDetail.close();
  }

}
