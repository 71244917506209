import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, RouterModule } from '@angular/router';
import { Console } from 'console';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let user = JSON.parse(localStorage.getItem('userData'));
    let date=new Date();
    if (user&&user.vigencia) {
      let vigencia=new Date(user.vigencia);
      if(vigencia.getTime()>date.getTime()){
        return true;
      }
      this.router.navigateByUrl('/login');
      return false;
    }
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

}
