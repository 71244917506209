import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Position } from '../../models/position';
import { PositionService } from '../../services/position.service';
import { ToastService } from '../../services/toastservice.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css'],
  providers: [PositionService]
})
export class PositionsComponent implements OnInit {
  page_title = 'Puestos';
  activeModal: NgbModalRef;
  page = 1;
  pageSize = 5;
  collectionSize = 0;

  constructor(public positionService: PositionService, public modal: NgbModal, public toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getPositions();
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.positionService.selectedPosition = new Position();
    }).catch(err => {
      this.positionService.selectedPosition = new Position();
    });
  }

  addPosition(form?: NgForm) {
    if (form.value._id) {
      this.positionService.putPosition(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toastService.show('Position actualizada');
            this.getPositions();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    } else {
      this.positionService.postPosition(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toastService.show('Position creada');
            this.getPositions();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    }
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.positionService.selectedPosition = new Position();
    }
    this.getPositions();
  }

  getPositions() {
    this.positionService.getPositions()
      .subscribe((res: any) => {
        this.positionService.positions = res.Position as Position[];
      }, (error: HttpErrorResponse) => {
        this.toastService.show(error.message);
      });
  }

  editPosition(position: Position, content) {
    this.positionService.selectedPosition = position;
    this.openModal(content);
  }

  deletePosition(_id: string) {
    // swal({
    //   title: "¿Estás seguro?",
    //   text: "¡Una vez eliminado, no podrás recuperar el registro!",
    //   icon: "warning",
    //   buttons: ['Conservar', 'Eliminar'],
    //   dangerMode: true,
    // })
    //   .then((willDelete) => {
    //     if (willDelete) {
    //       this.positionService.deletePosition(_id)
    //         .subscribe(res => {
    //           this.getPositions();
    //           swal("¡El puesto se ha eliminado!", {
    //             icon: "success",
    //           });
    //         }, (error: HttpErrorResponse) => {
    //           this.toastService.show(error.message);
    //         });
    //     }
    //   });

  }

}
