<nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#" >
    <!-- <img style="max-height: 30px;" alt="Qries" src="assets/logo.png"> -->
  </a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
    data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"> -->
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <!-- <a class="btn nav-link" (click)="userService.logout()">Sign out</a> -->
      <a class="btn nav-link" (click)="toggle()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-circle"fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
          <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
        </svg>
      </a>
    </li>
  </ul>
</nav>
<div id="markLogin" class="markLogin" aria-hidden="true" tabindex="-1" (blur)="toggle()">
  <div class="row">
    <div class="col-12 text-center">
      <svg width="5em" height="5em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
        <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
      </svg>
    </div>
    <div class="col-12 text-center mt-4">
      <h6 class="text-muted">{{ userData.category.name}}</h6>
      <h6 class="font-weight-light-bold">{{userData.usuario}}</h6>
      <h6 class="text-muted">{{userData.email}}</h6>
      <h6 *ngIf=" userData.category.name == 'Administrador'"  class="text-muted"><a href="https://api.whatsapp.com/send?text={{userData.Address[0].idInmueble}}" data-action="share/whatsapp/share">{{userData.Address[0].idInmueble}}</a></h6>
      <h6 *ngIf=" userData.category.name == 'Residente'"  class="text-muted"><a href="https://api.whatsapp.com/send?text={{userData.Address[0].noCuenta}}" data-action="share/whatsapp/share">{{userData.Address[0].noCuenta}}</a></h6>
    </div>
    <div class="col-12">
      <a class="btn nav-link" (click)="userService.logout()">Cerrar sesión</a>
    </div>
  </div>
</div>