import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { InmuebleService } from '../../services/inmueble.service';
import { ToastService } from '../../services/toastservice.service';
import { Inmueble } from 'src/app/models/inmueble';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-inmuebles',
  templateUrl: './inmuebles.component.html',
  styleUrls: ['./inmuebles.component.css']
})
export class InmueblesComponent implements OnInit {
  page_title = 'Inmuebles';
  activeModal: NgbModalRef;
  page = 1;
  pageSize = 5;

  constructor(public inmuebleService: InmuebleService, private toast: ToastService, public modal: NgbModal) { }

  ngOnInit(): void {
    this.getInmuebles();
  }

  getInmuebles() {
    this.inmuebleService.getInmuebles()
      .subscribe((res: any) => {
        this.inmuebleService.inmuebles = res.Property as Inmueble[];
      }, (err: HttpErrorResponse) => {
        this.toast.show(err.message);
      })
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.inmuebleService.selectedInmueble = new Inmueble();
    }).catch(() => {
      this.inmuebleService.selectedInmueble = new Inmueble();
    });
  }

  addInmueble(form?: NgForm) {
    if (form.value._id) {
      this.inmuebleService.putInmueble(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Inmueble actualizado');
            this.getInmuebles();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
    else {
      this.inmuebleService.postInmueble(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Inmueble creado');
            this.getInmuebles();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.inmuebleService.selectedInmueble = new Inmueble();
    }
    this.getInmuebles();
  }

  editInmueble(inmueble: Inmueble, content) {
    this.inmuebleService.selectedInmueble = inmueble;
    this.openModal(content);
  }

  deleteInmueble(_id: string) {
    // swal({
    //   title: "¿Estás seguro?",
    //   text: "¡Una vez eliminado, no podrás recuperar el registro!",
    //   icon: "warning",
    //   buttons: ['Conservar', 'Eliminar'],
    //   dangerMode: true,
    // })
    //   .then((willDelete) => {
    //     if (willDelete) {
    //       this.inmuebleService.deleteInmueble(_id)
    //         .subscribe(res => {
    //           this.getInmuebles();
    //           swal("¡El registro se ha eliminado!", {
    //             icon: "success",
    //           });
    //         }, (error: HttpErrorResponse) => {
    //           this.toast.show(error.message);
    //         });
    //     }
    //   });

  }

}
