<div class="row">
    <div class="col-md-4 col-lg-4 mt-3 offset-md-1 offset-lg-1 offset-2 col-8 offset-2 offset-md-2 col-sm-8 offset-md-2">
        <img src="assets/lgo.PNG" alt="Logo" class="img-fluid">
    </div>
    <div class="col-md-7 col-lg-7 d-flex align-items-center col-12 col-sm-12">
        <div class="col-10 offset-1">
            <h1 class="h1-responsive text-justify">
                <strong>
                    La mejor herramienta para
                    controlar el acceso de
                    visitantes a tu residencial o empresa
                </strong>
            </h1>
            <h1 class="h1-responsive text-center"><span class="text-right">QuickAccess</span></h1>
            <h2 class="h2-responsive text-justify"> Le ofrece la mayor
                seguridad y tranquilidad en el proceso de admisión y registro de sus invitados.
            </h2>
        </div>
    </div>
</div>