import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Categoria } from 'src/app/models/categoria';
import { UserData } from 'src/app/models/user-data';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  page_title = 'Categorias';
  activeModal: NgbModalRef;
  page = 1;
  pageSize = 5;

  constructor(public categoriaService: CategoriaService, public modal: NgbModal, private toast: ToastService) { }

  ngOnInit(): void {
    this.getCategorias();
  }

  getCategorias() {
    this.categoriaService.getCategorias()
      .subscribe((res: any) => {
        let cats: Categoria[] = res.Category as Categoria[];
        let user: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
        if (user.category.name != 'sa')
          this.categoriaService.categories = cats.filter(c => c.name != 'Administrador' && c.name != 'sa');
        else
        this.categoriaService.categories = cats;
          
      }, (err: HttpErrorResponse) => {
        this.toast.show(err.message);
      })
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.categoriaService.selectedCategory = new Categoria();
    }).catch(() => {
      this.categoriaService.selectedCategory = new Categoria();
    });
  }

  addCategoria(form?: NgForm) {
    if (form.value._id) {
      this.categoriaService.putCategoria(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Categoría actualizada');
            this.getCategorias();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
    else {
      this.categoriaService.postCategoria(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Categoría creada');
            this.getCategorias();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }

        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.categoriaService.selectedCategory = new Categoria();
    }
    this.getCategorias();
  }

  editCategoria(Categoria: Categoria, content) {
    this.categoriaService.selectedCategory = Categoria;
    this.openModal(content);
  }

  deleteCategoria(_id: string) {
    // swal({
    //   title: "¿Estás seguro?",
    //   text: "¡Una vez eliminado, no podrás recuperar el registro!",
    //   icon: "warning",
    //   buttons: ['Conservar', 'Eliminar'],
    //   dangerMode: true,
    // })
    //   .then((willDelete) => {
    //     if (willDelete) {
    //       this.categoriaService.deleteCategoria(_id)
    //         .subscribe(res => {
    //           this.getCategorias();
    //           swal("¡El registro se ha eliminado!", {
    //             icon: "success",
    //           });
    //         }, (error: HttpErrorResponse) => {
    //           this.toast.show(error.message);
    //         });
    //     }
    //   });

  }

}
