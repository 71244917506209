import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Cajero } from 'src/app/models/cajero';
import { ResReporteDetalleServicios } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { CajeroService } from 'src/app/services/cajero.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { ExcelService } from 'src/app/services/excel.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { ReprocesarService } from 'src/app/services/reprocesar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResConsultaPago } from 'src/app/models/reprocesar';
import { UserData } from 'src/app/models/user-data';
import { ToastService } from 'src/app/services/toastservice.service';
@Component({
  selector: 'app-reporte-detalle-servicios',
  templateUrl: './reporte-detalle-servicios.component.html',
  styleUrls: ['./reporte-detalle-servicios.component.css'],
  providers: [DatePipe]
})

export class ReporteDetalleServiciosComponent implements OnInit {
  public selectedCajero:number=0;
  public selectedServicio:Servicio=new Servicio();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public selectedDetalle: ResReporteDetalleServicios=new ResReporteDetalleServicios();
  public filtereddatosreporte: ResReporteDetalleServicios[] = [];
  myDialogFormDetail:FormGroup;
  activeModalDetail: NgbModalRef;
  myForm:FormGroup;
  activeAdmin:boolean=false;
  page = 1;
  pageSize = 10;
  filter="";
  activeModal: NgbModalRef;
  statusOperacion="";

  page_title = 'Reporte Detalle de Pago de Servicios';

  constructor(public toastService: ToastService,public modalDetail:NgbModal,public reprocesarService:ReprocesarService,public cajeroService:CajeroService,public servicioService:ServicioService,public reporteService:ReportesService,public datepipe: DatePipe,private excelService:ExcelService,public modal: NgbModal) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'servicio': new FormControl(""),
      'cajero': new FormControl("")
    });
    this.getCajeros();
    this.getServicios();
    this.getReporteDetalle();
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    this.activeAdmin=(user&&(user.category.name=='ADMINISTRADOR'))
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
        index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
      this.servicioService.servicios=this.servicioService.servicios.filter((val) => {
        let rVal = (val.padre==0) 
        return rVal;
      });
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  getReporteDetalle(): void {
    this.reporteService.getReporteDetalleServicios(this.selectedServicio,this.selectedCajero,this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'))
      .subscribe((res: any) => {
        this.reporteService.datosreporteDetalles = res.data as ResReporteDetalleServicios[];
        this.filtereddatosreporte=this.reporteService.datosreporteDetalles;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  exportPDF(){
    const doc = new jsPDF()
    let data:any[]=this.jsontoArr();
    autoTable(doc, {   columnStyles: { europe: { halign: 'center' } }, // European countries centered
    body: data,
    columns: [
      { header: 'Cajero', dataKey: 'Cajero' },
      { header: 'Fecha', dataKey: 'Fecha' },
      { header: 'Hora', dataKey: 'Hora' },
      { header: 'Ubicación', dataKey: 'Ubicacion' },
      { header: 'Servicio', dataKey: 'Servicio' },
      { header: 'Referencia', dataKey: 'Referencia' },
      { header: 'Contribuyente', dataKey: 'Contribuyente' },
      { header: 'Total', dataKey: 'Total' },
    ], })
    doc.save('DetalleServicios'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM')+'.pdf')

  }
  jsontoArr():any[]
  {
    let data:any[]=[];
    for(var i in this.reporteService.datosreporteDetalles) 
    {
      let row:any[]=[this.reporteService.datosreporteDetalles[i].Cajero,this.reporteService.datosreporteDetalles[i].Fecha,this.reporteService.datosreporteDetalles[i].Hora,this.reporteService.datosreporteDetalles[i].Ubicacion,this.reporteService.datosreporteDetalles[i].Servicio,this.reporteService.datosreporteDetalles[i].Referencia,this.reporteService.datosreporteDetalles[i].Contribuyente,this.reporteService.datosreporteDetalles[i].Total,this.reporteService.datosreporteDetalles[i].FormaPago];
      data.push(row);
    }
    return data;
  
  }
  exportExcel(){
    {
      let header:string[]=["Cajero","Fecha","Hora","Ubicacion","Servicio","Referencia","Contribuyente","Total","FormaPago"]
      let data:any[]=this.jsontoArr();
       /* table id is passed over here */   
      this.excelService.generateExcel(header,data,"Reporte de Detalle de Servicios","A1:H1",'DetalleServicios'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM')+'.xlsx');
    }
  }
  
  filterChange(e){
    this.filtereddatosreporte=this.reporteService.datosreporteDetalles.filter((val) => {
      let rVal = (val.Cajero.toString().includes(e)) 
      || (val.Fecha.toLocaleLowerCase().includes(e))
      || (val.Fecha.toLocaleLowerCase().includes(e))
      || (val.Hora.toLocaleLowerCase().includes(e))
      || (val.Ubicacion.toLocaleLowerCase().includes(e))
      || (val.Servicio.toLocaleLowerCase().includes(e))
      || (val.Referencia.toLocaleLowerCase().includes(e))
      || (val.Total.toString().includes(e))
      || (val.Contribuyente.toLocaleLowerCase().includes(e));
      return rVal;
    })

  }
  //   let header=["Cajero","Fecha","Ubicación","Servicio","Referencia","Contribuyente","Total"]
  //   this.excelService.exportAsExcelFile(this.reporteService.datosreporteDetalles, 'ReporteDetalleServicios'+this.datepipe.transform(this.selectedFechaInicio, 'ddMM-')+this.datepipe.transform(this.selectedFechaFin, 'ddMM'),header);

  // }
  consultarStatus(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                //descripcion_estatus
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postConsultaInformacion(token,registro.Referencia).subscribe((res: any) => {
                    this.statusOperacion=res.estatusDesc;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  reprocesar(registro:ResReporteDetalleServicios, content){
    if(registro&&registro.Referencia&&registro.Referencia.length==20){
        if(Number(registro.Referencia.substring(4,5))>=5){
            this.reprocesarService.postConsultaPago(registro.Referencia).subscribe((res: any) => {
                let payment=res as ResConsultaPago;

                this.reprocesarService.postRenotificacion("309F1E78-E02E-44D7-BADF-200521D1E716",payment,registro).subscribe((res: any) => {
                    console.log(res);


                }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                  })
    

            }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }else{
            this.openModal(content);
            this.reprocesarService.postLoginConsultaInformacion("kiosko001","d408fbde48379e46bbe2884f000a52b46e0fdf6c49830d4b7bdca6d183d6465a","3").subscribe((res: any) => {
                let token=res.mensajeTecnico;
                this.reprocesarService.postReprocesar(token,registro).subscribe((res: any) => {
                    this.statusOperacion=res.mensajeCiudadano;
                  }, (err: HttpErrorResponse) => {
                    console.log('err',err);
                })
              }, (err: HttpErrorResponse) => {
                console.log('err',err);
              })

        }
    }
  }
  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'md' });
    this.activeModal.result.then(res => {
      this.statusOperacion="";
    }).catch(() => {
        this.statusOperacion="";
    });
  }
  closeModal(){
    this.statusOperacion = "";
    this.activeModal.close();
    
  }
  editRegistro(detalle:ResReporteDetalleServicios,content)
  {
    this.selectedDetalle=detalle;
    this.myDialogFormDetail = new FormGroup({
      'cfe': new FormControl(detalle.cfe)
    });
    this.activeModalDetail = this.modalDetail.open(content, { size: 'md' });
    this.activeModalDetail.result.then(res => {
      
    }).catch(() => {
      
    });


    
  }
  closeModalDetail(){
    this.activeModalDetail.close();
  }
  editDetail(form?: NgForm) {
    let re = new RegExp("^[0-9]*$");
    if(!re.test(this.selectedDetalle.cfe.toString())){
      return;

    }
    this.reporteService.putCFE(this.selectedDetalle)
    .subscribe((res: any) => {
      if (res.codigo==200) {
        this.selectedDetalle=new ResReporteDetalleServicios();
        this.toastService.show('CFE Actualizado',{classname: 'bg-primary text-white',headertext: 'Información'});
        this.getReporteDetalle();
        this.activeModalDetail.close();
      }
      else {
        this.toastService.show(res.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
      }
    }, (error: HttpErrorResponse) => {
      this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
    });

    this.activeModalDetail.close();
  }

}
