import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordRecoveryService } from 'src/app/services/password-recovery.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-recovery-code',
  templateUrl: './recovery-code.component.html',
  styleUrls: ['./recovery-code.component.css']
})
export class RecoveryCodeComponent implements OnInit, AfterViewInit {
  passwordChanged = false;

  constructor(
    public passwordService: PasswordRecoveryService,
    private toast: ToastService,
    private router: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe((res: any) => {
      this.passwordService.passwordData.idUsuario = res.req;
    });
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'bg');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'bg');
  }

  changePassword(form?: NgForm) {
    if (this.passwordService.passwordData.password === this.passwordService.passwordData.cpassword) {
      this.passwordService.changePassword(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.passwordChanged = true;
          }
          else {
            this.toast.show(res.message);
            this.passwordChanged = false;
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
          this.passwordChanged = false;
        })
    }
    else {
      this.passwordService.passwordData.password = '';
      this.passwordService.passwordData.cpassword = '';
      this.toast.show("Las contraseñas no coinciden");
    }
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
    }
  }

}
