<nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
    <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#" >
        <img style="max-height: 30px;" alt="Qries" src="assets/logo.png">
    </a>    
    <div class="sidebar-sticky pt-3">
        <ul class="nav flex-column">
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                                <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            </svg>
                            Inicio <span class="sr-only">(current)</span>
                        </a>
        
                    </div>
                </div>
            </li>
        </ul>

        <nav *ngIf="activeCatalogos" class="navbar navbar-light bg-light">
            <div class="fs-2 mb-3 row" (click)="onCatalogosClick()">
                <div class="col-sm-1">
                    <svg *ngIf="!catalogosopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                      </svg>
                      <svg *ngIf="catalogosopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>                </div>
                <div class="col-sm">
                    <h5>Catálogos</h5>

                </div>
            </div>
        </nav>
        <ul  *ngIf="activeCatalogos&&catalogosopen" class="nav flex-column">
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/cajeros']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                            <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                        </svg>
                        Cajeros <span class="sr-only">(current)</span>
                        </a>
                    
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/serviciosactivos']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                                <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                            </svg>
                            Servicios Activos <span class="sr-only">(current)</span>
                        </a>
    
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/users']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-people" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg>
                            Usuarios
                        </a>
    
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/tipoincidencia']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-people" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg>
                            Tipos de Incidencia
                        </a>
    
                    </div>
                </div>
            </li>
        </ul>  
        <br *ngIf="catalogosopen">
        <nav class="navbar navbar-light bg-ligth">
            <div class="fs-2 mb-3 row" (click)="onMonitoreoClick()">
                <div class="col-sm-1">
                    <svg *ngIf="!monitoreoopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                      </svg>
                      <svg *ngIf="monitoreoopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>                </div>
                <div class="col-sm">
                    <h5>Monitoreo</h5>
                </div>
            </div>
        </nav>
        <ul *ngIf="monitoreoopen" class="nav flex-column">
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/monitoreocajeros']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                            </svg>
                            Monitoreo Cajeros
                        </a>
                    </div>
                </div>
            </li>
            <li class="nav-item" >
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/monitoreoincidencias']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>                    
                            Monitoreo Incidencias
                        </a>
    
                    </div>
                </div>
            </li>
            <li *ngIf="activeCatalogos" class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
						<a class="nav-link" [routerLink]="['/monitoreovalescaja']" [routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{exact:true}">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-calendar-day" viewBox="0 0 16 16">
								<path
									d="M4.684 11.523v-2.3h2.261v-.61H4.684V6.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V8.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105zm2.805-5.093c0 .238.192.425.43.425a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43zm.094 5.093h.672V7.418h-.672v4.105z" />
								<path
									d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
							</svg>
							Vales de Caja
						</a>

                    </div>
                </div>
            </li>

            <!-- <li *ngIf="activeCatalogos" class="nav-item" >
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/auditoria']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                            Auditoria
                        </a>
    
                    </div>
                </div>
            </li> -->
            <!-- <li *ngIf="activeCatalogos" class="nav-item" >
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
                        <a class="nav-link" [routerLink]="['/reprocesar']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>                    
                            Reprocesar
                        </a>
    
                    </div>
                </div>
            </li> -->
        </ul>
        <br *ngIf="monitoreoopen">
        <nav   class="navbar navbar-light bg-ligth">
            <div class="fs-2 mb-3 row" (click)="onReportesClick()">
                <div class="col-sm-1">
                    <svg *ngIf="!reportesopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                      </svg>
                      <svg *ngIf="reportesopen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>                </div>
                <div class="col-sm">
                    <h5>Reportes</h5>

                </div>
            </div>
        </nav>
        <ul  *ngIf="reportesopen"  class="nav flex-column">
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
						<a class="nav-link" [routerLink]="['/reporteservicios']" [routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{exact:true}">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
								<path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
							  </svg>                    
							  Reporte General Servicios
						</a>

                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
						<a class="nav-link" [routerLink]="['/reporteserviciosfecha']" [routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{exact:true}">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
								<path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
							  </svg>                    
							  Reporte Servicios
						</a>

                    </div>
                </div>
            </li>
            <li *ngIf="activeCatalogos" class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
						<a class="nav-link" [routerLink]="['/reportecompulsa']" [routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{exact:true}">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-calendar-day" viewBox="0 0 16 16">
								<path
									d="M4.684 11.523v-2.3h2.261v-.61H4.684V6.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V8.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105zm2.805-5.093c0 .238.192.425.43.425a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43zm.094 5.093h.672V7.418h-.672v4.105z" />
								<path
									d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
							</svg>
							Reporte Compulsas
						</a>

                    </div>
                </div>
            </li>

            <li *ngIf="activeReportes" class="nav-item">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm">
						<a class="nav-link" [routerLink]="['/reportedetalleservicios']" [routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{exact:true}">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-calendar-day" viewBox="0 0 16 16">
								<path
									d="M4.684 11.523v-2.3h2.261v-.61H4.684V6.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V8.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105zm2.805-5.093c0 .238.192.425.43.425a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43zm.094 5.093h.672V7.418h-.672v4.105z" />
								<path
									d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
							</svg>
							Reporte Detalle Servicios
						</a>

                    </div>
                </div>
            </li>
        </ul>

    </div>
</nav>
