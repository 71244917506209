<div class="row mt-5">
    <div class="col-12">
        <h1 class="h1-responsive text-center text-danger text-uppercase"> soluciones
            quickaccess </h1>
    </div>
</div>
<div class="row d-flex align-items-center justify-content-center px-5">
    <div class="col-12 col-sm-6 my-4"><img src="assets/banner-2.jpg" alt="Soluciones en Residenciales"
            class="img-fluid rounded-circle"></div>
    <div class="col-12 col-sm-6 my-4">
        <h1 class="h1-responsive text-uppercase text-left"> uso residencial </h1><br><br>
        <h3 class="h3-responsive text-justify"> Tranquilidad en tu hogar es lo más
            importante, por ello QuickAccess te garantiza admisión exclusiva y confiable de invitados a tu
            residencial. <br><br> Tú tienes el control de quién ingresa
            al residencial; con su sistema de generación de códigos QR y PIN, puedes tener la seguridad que el
            acceso es 100% seguro y rápido; además, cada invitación que generes quedará registrada, por lo que
            puedes consultar fecha y hora en que podrán acceder tus invitados. </h3>
    </div>
</div>
<div class="row d-flex align-items-center justify-content-center px-5">
    <div class="col-12 col-sm-6">
        <h1 class="h1-responsive text-uppercase text-left"> uso empresarial </h1><br><br>
        <h3 class="h3-responsive text-justify"> Tener el control de quien ingresa o sale
            de tu empresa de forma ágil, es primordial para mantener la seguridad. <br><br> QuickAccess te permite
            controlar las entradas y salidas de tus empleados,
            invitados o proveedores; podrás conocer el registro, permanencia y salida de cada uno de ellos. Puedes
            generar eventos y saber cuántas personas están presentes. </h3>
    </div>
    <div class="col-12 col-sm-6"><img src="assets/banner-2.jpg" alt="Soluciones en Empresas"
            class="img-fluid rounded-circle">
    </div>
</div>