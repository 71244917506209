import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Checkpoint } from 'src/app/models/checkpoint';
import { CheckpointService } from 'src/app/services/checkpoint.service';
import { ToastService } from 'src/app/services/toastservice.service';
//import swal from 'sweetalert';

@Component({
  selector: 'app-checkpoints',
  templateUrl: './checkpoints.component.html',
  styleUrls: ['./checkpoints.component.css']
})
export class CheckpointsComponent implements OnInit {
  page_title = 'Puntos de control';
  activeModal: NgbModalRef;
  page = 1;
  pageSize = 5;
  collectionSize = 0;

  constructor(public modal: NgbModal, public checkpointService: CheckpointService, private toast: ToastService) { }

  ngOnInit(): void {
    this.getCheckpoints();
  }

  getCheckpoints() {
    this.checkpointService.getCheckpoints()
      .subscribe((res: any) => {
        this.checkpointService.checkpoints = res.Checkpoint as Checkpoint[];
      }, (err: HttpErrorResponse) => {
        this.toast.show(err.message);
      })
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.checkpointService.selectedCheckpoint = new Checkpoint();
    }).catch(() => {
      this.checkpointService.selectedCheckpoint = new Checkpoint();
    });
  }

  addCheckpoint(form?: NgForm){
    if(form.value._id)
    {
      this.checkpointService.putCheckpoint(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            this.resetForm(form);
            this.toast.show('Punto de control actualizado');
            this.getCheckpoints();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
    else
    {
      this.checkpointService.postCheckpoint(form.value)
        .subscribe((res: any) => {
          if (!res.message) {
            // this.uploadFileToServer(res.User._id);
            this.resetForm(form);
            this.toast.show('Punto de control creado');
            this.getCheckpoints();
            this.activeModal.close();
          }
          else {
            this.toast.show(res.message);
          }

        }, (error: HttpErrorResponse) => {
          this.toast.show(error.message);
        });
    }
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.checkpointService.selectedCheckpoint = new Checkpoint();
    }
    this.getCheckpoints();
  }

  editCheckpoint(checkpoint: Checkpoint, content) {
    this.checkpointService.selectedCheckpoint = checkpoint;
    this.openModal(content);
  }

  deleteCheckpoint(_id: string) {
    // swal({
    //   title: "¿Estás seguro?",
    //   text: "¡Una vez eliminado, no podrás recuperar el registro!",
    //   icon: "warning",
    //   buttons: ['Conservar', 'Eliminar'],
    //   dangerMode: true,
    // })
    //   .then((willDelete) => {
    //     if (willDelete) {
    //       this.checkpointService.deleteCheckpoint(_id)
    //         .subscribe(res => {
    //           this.getCheckpoints();
    //           swal("¡El registro se ha eliminado!", {
    //             icon: "success",
    //           });
    //         }, (error: HttpErrorResponse) => {
    //           this.toast.show(error.message);
    //         });
    //     }
    //   });

  }

}
