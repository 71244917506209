<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="form-group row">
                        <div class="col-md-8">
                            <h2 class="card-title">{{page_title}}</h2>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <form (ngSubmit)="getReporteValesCaja()" #filtrosForm="ngForm" [formGroup]="myForm" >
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="name">Fecha inicio</label>
                                <input class="form-control" id="fechaInicio" f type="date" name="fechaInicio" 
                                [(ngModel)]="selectedFechaInicio" placeholder="Fecha inicio" >
                            
                            </div>
                            <div class="col-md-6">
                                <label for="name">Fecha fin</label>
                                <input class="form-control" id="fechafin" type="date" name="fechafin" #fechafin="ngModel"
                                [(ngModel)]="selectedFechaFin" placeholder="Fecha fin">
                            </div>

                        </div>

                        <div class="form-group row" >
                            <div class="col-md-6">
                                <label for="select1">Técnicos</label>
                                <select  class="form-control" name="tecnico" id="tecnico"
                                    #tecnico="ngModel" [(ngModel)]="selectedFiltroTecnico" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let tecnico of userService.tecnicos" [ngValue]="tecnico">
                                        {{tecnico?.nombres+' '+tecnico?.ap_paterno+' '+tecnico?.ap_materno}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="select1">Status Incidente</label>
                                <select  class="form-control" name="statusincidente" id="statusincidente"
                                    #statusincidente="ngModel" [(ngModel)]="selectedFiltroStatusIncidente" required>
                                    <option value="">SELECCIONAR TODOS</option>
                                    <option  *ngFor="let statusIncidente of statusIncidenteService.statusIncidentes" [ngValue]="statusIncidente">
                                        {{statusIncidente?.name}}</option>
                                </select>
                            </div>
        
                        </div>

                        <div class="form-group row">
            
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button  class="btn  btn-success">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Exportar
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a  class="dropdown-item" (click)="exportValeCaja()">Vales de Caja</a>
                                  <!-- <a class="dropdown-item" (click)="exportPDF()">PDF</a> -->
                                </div>
                              </div>
                        </div>
                        <div class="col-md-4"></div>

                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cajero</th>
                                            <th scope="col">Fecha y Hora</th>
                                            <th scope="col">Importe Total Pagado</th>
                                            <th scope="col">Cantidad No Devuelta</th>
                                            <th scope="col">Contribuyente</th>
                                            <th scope="col">Referencia</th>
                                            <th scope="col">Técnico</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of filteredIncidencias | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                            <td >{{item.num_cajero}}</td>
                                            <td >{{item.fecha_hora_vale}}</td>     
                                            <td align="right">{{item.importe_total_pagado |currency:'USD':'symbol'}}</td>
                                            <td align="right">{{item.cantidad_nodevuelta |currency:'USD':'symbol'}}</td>                                       
                                            <td >{{item.contribuyente}}</td>
                                            <td >{{item.referencia_pago}}</td>
                                            <td >{{item.tecnico}}</td>
                                            <td >{{item.statusincidencia}}</td>
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                         </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div *ngIf="filteredIncidencias.length>=page*pageSize">Mostrando registros del {{((page-1)*pageSize)+1}} al {{page*pageSize}} de un total de {{filteredIncidencias.length}} registros</div>
                            <div *ngIf="filteredIncidencias.length<(page*pageSize)">Mostrando registros del {{((page-1)*pageSize)+1}} al {{filteredIncidencias.length}} de un total de {{filteredIncidencias.length}} registros</div>
                        </div>
                        <div class="col-md-4" >
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="filteredIncidencias.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination> 

                        </div>
                    </div>


                </div>
            </div>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>