import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Password, RecoveryPassword } from '../models/recovery-password';

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {
  userData: RecoveryPassword;
  passwordData: Password;
  
  constructor(private http: HttpClient) { 
    this.userData = new RecoveryPassword();
    this.passwordData = new Password();
  }

  recoverPassword(recovery: RecoveryPassword) {
    return this.http.post(Global.URL_API + 'user/password-recovery', recovery);
  }

  changePassword(userData: Password){
    return this.http.post(Global.URL_API + 'user/change-password', userData);
  }
}
