import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Evento } from 'src/app/models/event';
import { UserService } from '../../services/user.service';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})

export class EventComponent implements OnInit {
  private _id: string;
  public evento: Evento;

  @ViewChild("passport") screen;
  // @ViewChild("canvas") canvas: ElementRef;
  // @ViewChild("downloadLink") downloadLink: ElementRef;

  constructor(private renderer: Renderer2, private router: ActivatedRoute, private userService: UserService, private route: Router) {
    this.evento = new Evento();
  }

  ngOnInit(): void {
    this.router.params.subscribe(res => this._id = res.id);
    this.userService.getEvent(this._id)
      .subscribe(
        (res: any) => {
          this.evento = res.data as Evento;
          this.evento.Event.HoraInicio = this.evento.Event.HoraInicio.split(' ')[1].substring(0, 8);
          this.evento.Event.HoraFin = this.evento.Event.HoraFin.split(' ')[1].substring(0, 8);
        },
        (error: HttpErrorResponse) => {
          this.route.navigateByUrl('/page-not-found');
        }
      );
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(document.body, 'background-color', '#e9ecef');
  }

  ngOnDestroy(): void {
    this.renderer.removeStyle(document.body, 'background-color');
  }

  downloadPassport(): void {
    // window.print();
    domtoimage.toPng(this.screen.nativeElement, { quality: 0.95 })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'QRCode.png';
      link.href = dataUrl;
      link.click();
        // var img = new Image();
        // img.src = dataUrl;
        // document.body.appendChild(img);
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
  }

}
