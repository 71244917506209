export class RecoveryPassword {
    constructor(t: any = {}) {
        Object.assign(this, t);
    }
    userdata: string = '';
}

export class Password {
    constructor(t: any = {}) {
        Object.assign(this, t);
    }
    idUsuario: string = '';
    codigo: string = '';
    password: string = '';
    cpassword: string = '';
}