import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Global } from '../config/config';
import { Router } from '@angular/router';
import { ValeCaja } from '../models/valecaja';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  constructor(private http: HttpClient, private router: Router) {
  }


  getAuditoria(fechaInicio:string,fechafin:string,tecnico:number,cajero:number) {
    const body={fechainicio:fechaInicio,fechafin:fechafin,idtecnico:tecnico,numcajero:cajero};
    return this.http.post(Global.URL_API + 'auditoria', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }

}
