import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Cajero } from 'src/app/models/cajero';
import { ResReporteDetalleServicios } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { CajeroService } from 'src/app/services/cajero.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { ExcelService } from 'src/app/services/excel.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { ReprocesarService } from 'src/app/services/reprocesar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResConsultaPago } from 'src/app/models/reprocesar';
import { UserData } from 'src/app/models/user-data';
import { Auditoria } from 'src/app/models/auditoria';
import { User } from 'src/app/models/user';
import { AuditoriaService } from 'src/app/services/auditoria.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css'],
  providers: [DatePipe]
})

export class AuditoriaComponent implements OnInit {
  public selectedCajero:number=0;
  public selectedTecnico:User=new User();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public filtereddatosreporte: ResReporteDetalleServicios[] = [];
  public auditoria:Auditoria=new Auditoria();
  myForm:FormGroup;
  activeAdmin:boolean=false;

  page_title = 'Auditoria';

  constructor(public auditoriaService:AuditoriaService,public cajeroService:CajeroService,public userService:UserService,public datepipe: DatePipe) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'tecnico': new FormControl(""),
      'cajero': new FormControl("")
    });
    this.getCajeros();
    this.getTecnicos();
    this.getAuditoria();
    let user: UserData = <UserData> JSON.parse(localStorage.getItem('userData'));
    this.activeAdmin=(user&&(user.category.name=='ADMINISTRADOR'))
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
        index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }

  getAuditoria(): void {
    this.auditoriaService.getAuditoria(this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'),this.selectedTecnico.id_usuario,this.selectedCajero)
      .subscribe((res: any) => {
        this.auditoria = res.data as Auditoria;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }

}
