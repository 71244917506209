import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  @Input() public event: any;
  page = 1;
  pageSize = 5;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    
  }

  isDefined(arrObj: any): boolean {
    if(arrObj !== undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

}
