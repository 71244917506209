import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RecoveryPassword } from 'src/app/models/recovery-password';
import { PasswordRecoveryService } from 'src/app/services/password-recovery.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit, AfterViewInit {

  constructor(public passwordService: PasswordRecoveryService, private router: Router, private toast: ToastService, private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'bg');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'bg');
  }

  recoverPassword(form?: NgForm) {
    console.log(form.value);
    this.passwordService.recoverPassword(form.value)
      .subscribe((res: any) => {
        if (!res.message) {
          // swal({
          //   title: "Recuperación de contraseña",
          //   text: "¡Se ha enviado un correo electrónico de recuperación a tu cuenta de correo registrada en la plataforma!",
          //   icon: "info"
          // });
          this.router.navigateByUrl('/login');
        }
        else {
          this.toast.show(res.message);
          this.resetForm(form);
        }
      }, (error:HttpErrorResponse) => {
        this.toast.show(error.message);
        form.resetForm(form);
      })
  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.passwordService.userData = new RecoveryPassword({});
    }
  }

}
