<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-body">
                    <h2 class="header">{{page_title}}</h2>
                    <button class="btn btn-success" (click)="openModal(content)">Agregar nuevo puesto</button>
                    <br>
                    <br>
                    <div class="table-responsive">
                        <table class="table table-striped table-sm">
                            <thead>
                                <th>Puesto</th>
                                <th>Opciones</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let position of positionService.positions | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                    <td>{{position.positionname}}</td>
                                    <td>
                                        <a (click)="editPosition(position, content)">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                class="bi bi-pencil-square" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </a>
                                        <a (click)="deletePosition(position._id)">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash"
                                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                            [collectionSize]="positionService.positions.length" [maxSize]="5" [rotate]="true" [ellipses]="false"
                            [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>

                </div>
            </div>
            <ng-template #content let-modal>
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form ngForm #positionForm="ngForm" (ngSubmit)="addPosition(positionForm)">
                        <input type="hidden" name="_id" id="_id" #_id="ngModel"
                            [(ngModel)]="positionService.selectedPosition._id">
                        <div class="form-group">
                            <label for="status">Position</label>
                            <input class="form-control" type="text" name="positionname" id="positionname"
                                #positionname="ngModel" [(ngModel)]="positionService.selectedPosition.positionname"
                                placeholder="Nombre del puesto">
                        </div>
                        <div class="form-group">
                            <label for="status">Estatus</label>
                            <select class="form-control" name="status" id="status" #status="ngModel"
                                [(ngModel)]="positionService.selectedPosition.status">
                                <option [value]=1>ACTIVO</option>
                                <option [value]=0>INACTIVO</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success">Save</button>
                            &nbsp;
                            <button class="btn btn-info" (click)="resetForm(positionForm)">Clean</button>
                        </div>
                    </form>
                </div>
            </ng-template>
            <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        </main>
    </div>
</div>