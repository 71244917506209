export class Evento {
    constructor(t: any = {}) {
        Object.assign(this, t);
    }
    Event: Event = new Event();
    Guest: Guest = new Guest();
    User: User = new User();
}

class Event {
    FechaFin: string = '';
    FechaInicio: string = '';
    HoraFin: string = '';
    HoraInicio: string = '';
    NombreEvento: string = '';
}

class Guest {
    NombreInvitado: string = '';
    pin: string = '00000';
    QR: string = '';
}

class User {
    NombreResidente: string = '';
    NombreFraccionamiento: string = '';
}

export interface MassiveEvent {
    _id: string;
    status: number;
    nombreEvento: string;
    fechaInicio: string;
    fechaFin: string;
    horaInicio: string;
    horaFin: string;
    _idUser: string;
    idTipoInvitado: number;
}

export interface PersonalEvent {
    _id: string;
    status: number;
    nombreEvento: string;
    NombreInvitado: string;
    fechaInicio: string;
    fechaFin: string;
    horaInicio: string;
    horaFin: string;
    _idUser: string;
    idTipoInvitado: number;
}

export interface ProviderEvent {
    _id: string;
    status: number;
    nombreProveedor: string;
    placas: string;
    fechaInicio: string;
    fechaFin: string;
    horaInicio: string;
    horaFin: string;
    _idUser: string;
    idTipoInvitado: number;
}

export interface EmployeeEvent {
    _id: string;
    status: number;
    nombreEmpleado: string;
    fechaInicio: string;
    fechaFin: string;
    horaInicio: string;
    horaFin: string;
    _idUser: string;
    idTipoInvitado: number;
}