<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                </div>
                <div class="card-body">
                    <h2 class="card-title">{{page_title}}</h2>
                    <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="eventService.events.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <form>
                                <div class="form-group form-inline">
                                    Buscar Registro<input class="form-control ml-2" type="text" [formControl]="filter"/>
                                </div>
                              </form>
                            <table class="table table-striped table-bordered" data-toggle="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Evento / Visita</th>
                                        <th scope="col">Inicio</th>
                                        <th scope="col">Fin</th>
                                        <th scope="col">Ver detalle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let event of eventService.events | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                        <td *ngIf="event.nombreEvento !== undefined">{{event.nombreEvento}}</td>
                                        <td *ngIf="event.nombreProveedor !== undefined">
                                            {{event.nombreProveedor - event.placas}}</td>
                                        <td *ngIf="event.nombreEmpleado">{{event.nombreEmpleado}}</td>
                                        <td>{{event.fechaInicio + ' ' + event.horaInicio.split(' ')[1].split('.')[0]}}
                                        </td>
                                        <td>{{event.fechaFin + ' ' + event.horaFin.split(' ')[1].split('.')[0]}}</td>
                                        <td>
                                            <a href="javascript:void(0);" (click)="viewEventDetail(event)"
                                                placement="top" [autoClose]="true" ngbTooltip="Detalle del evento">
                                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"
                                                    class="bi bi-list-check" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </a>
                                            <!-- &nbsp;
                                            <a href="javascript:void(0);" placement="top" ngbTooltip="Cancelar evento">
                                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-trash"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fill-rule="evenodd"
                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </a> -->
                                        </td>
                                    </tr>
                                    <!-- <tr *ngFor="let event of eventService.massiveEvents">
                                        <td>{{event._id}}</td>
                                        <td>{{event.nombreEvento}}</td>
                                        <td>{{event.fechaInicio + ' ' + event.horaInicio.split(' ')[1].split('.')[0]}}</td>
                                        <td>{{event.fechaFin + ' ' + event.horaFin.split(' ')[1].split('.')[0]}}</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let event of eventService.personalEvents">
                                        <td>{{event._id}}</td>
                                        <td>{{event.nombreEvento}}</td>
                                        <td>{{event.fechaInicio + ' ' + event.horaInicio.split(' ')[1].split('.')[0]}}</td>
                                        <td>{{event.fechaFin + ' ' + event.horaFin.split(' ')[1].split('.')[0]}}</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let event of eventService.providerEvents">
                                        <td>{{event._id}}</td>
                                        <td>{{event.nombreProveedor - event.placas}}</td>
                                        <td>{{event.fechaInicio + ' ' + event.horaInicio.split(' ')[1].split('.')[0]}}</td>
                                        <td>{{event.fechaFin + ' ' + event.horaFin.split(' ')[1].split('.')[0]}}</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let event of eventService.employeeEvents">
                                        <td>{{event._id}}</td>
                                        <td>{{event.nombreEmpleado}}</td>
                                        <td>{{event.fechaInicio + ' ' + event.horaInicio.split(' ')[1].split('.')[0]}}</td>
                                        <td>{{event.fechaFin + ' ' + event.horaFin.split(' ')[1].split('.')[0]}}</td>
                                        <td></td>
                                    </tr> -->
                                </tbody>
                            </table>
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="eventService.events.length" [maxSize]="5" [rotate]="true"
                                [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="status">Selecciona el tipo de evento/visita</label>
                                        <select class="form-control" name="tipoInvitado" id="tipoInvitado"
                                            (change)="showResult($event.target.value)">
                                            <option [value]=0>Evento Masivo</option>
                                            <option [value]=1>Evento Personal</option>
                                            <option [value]=2>Proveedor</option>
                                            <option [value]=3>Empleado</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="list-group" *ngIf="tipoInvitado == 0">
                                        <button type="button" class="list-group-item list-group-item-action"
                                            *ngFor="let event of eventService.massiveEvents"
                                            (click)="selectEvent(event)">
                                            {{event.nombreEvento}} </button>
                                    </div>
                                    <div class="list-group" *ngIf="tipoInvitado == 1">
                                        <button type="button" class="list-group-item list-group-item-action"
                                            *ngFor="let event of eventService.personalEvents"
                                            (click)="selectEvent(event)">
                                            {{event.nombreEvento}}</button>
                                    </div>
                                    <div class="list-group" *ngIf="tipoInvitado == 2">
                                        <button type="button" class="list-group-item list-group-item-action"
                                            *ngFor="let event of eventService.providerEvents"
                                            (click)="selectEvent(event)">
                                            {{event.nombreProveedor}}</button>
                                    </div>
                                    <div class="list-group" *ngIf="tipoInvitado == 3">
                                        <button type="button" class="list-group-item list-group-item-action"
                                            *ngFor="let event of eventService.employeeEvents"
                                            (click)="selectEvent(event)">
                                            {{event.nombreEmpleado}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" *ngIf="tipoInvitado == 0 && eventService.selectedEvent">
                            <div class="card">
                                <div class="card-header">
                                    Evento masivo
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">{{eventService.selectedEvent.nombreEvento}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">{{eventService.selectedEvent._id}}</h6>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha inicio: </span>{{eventService.selectedEvent.fechaInicio + ' ' + eventService.selectedEvent.horaInicio}}
                                    </p>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha fin: </span>{{eventService.selectedEvent.fechaFin + ' ' + eventService.selectedEvent.horaFin}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" *ngIf="tipoInvitado == 1 && eventService.selectedEvent">
                            <div class="card">
                                <div class="card-header">
                                    Invitación personal
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">{{eventService.selectedEvent.nombreEvento}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">{{eventService.selectedEvent._id}}</h6>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha inicio: </span>{{eventService.selectedEvent.fechaInicio + ' ' + eventService.selectedEvent.horaInicio}}
                                    </p>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha fin: </span>{{eventService.selectedEvent.fechaFin + ' ' + eventService.selectedEvent.horaFin}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" *ngIf="tipoInvitado == 2 && eventService.selectedEvent">
                            <div class="card">
                                <div class="card-header">
                                    Proveedor
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">{{eventService.selectedEvent.nombreProveedor}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">{{eventService.selectedEvent._id}}</h6>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Placas: </span>{{eventService.selectedEvent.placas}}
                                    </p>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha inicio: </span>{{eventService.selectedEvent.fechaInicio + ' ' + eventService.selectedEvent.horaInicio}}
                                    </p>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha fin: </span>{{eventService.selectedEvent.fechaFin + ' ' + eventService.selectedEvent.horaFin}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" *ngIf="tipoInvitado == 3 && eventService.selectedEvent">
                            <div class="card">
                                <div class="card-header">
                                    Empleado
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">{{eventService.selectedEvent.nombreEmpleado}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">{{eventService.selectedEvent._id}}</h6>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha inicio: </span>{{eventService.selectedEvent.fechaInicio + ' ' + eventService.selectedEvent.horaInicio}}
                                    </p>
                                    <p class="card-text">
                                        <span class="font-weight-bold">Fecha fin: </span>{{eventService.selectedEvent.fechaFin + ' ' + eventService.selectedEvent.horaFin}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </main>
    </div>
</div>