import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../services/toastservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserAddressComponent } from './user-address/user-address.component';
import { CategoriaService } from '../../services/categoria.service';
import { Categoria } from 'src/app/models/categoria';
import { UserData } from 'src/app/models/user-data';
import { MustMatch } from './_helpers/must-match.validator';
import { PerfilService } from 'src/app/services/perfil.service';
import { Perfil } from 'src/app/models/perfil';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [UserService,DatePipe],
  entryComponents: [UserAddressComponent]
})
export class UsersComponent implements OnInit {

  page_title = 'Usuarios';
  activeModal: NgbModalRef;
  public selectedDate:Date=new Date();
  page = 1;
  pageSize = 10;
  filter = "";
  registerForm: FormGroup;
  submitted = false;
  edit=false;
  filteredUsers:User[]=[];
  constructor(public userService: UserService, public modal: NgbModal, public toastService: ToastService, public categoriaService: CategoriaService,private formBuilder: FormBuilder,private perfilService:PerfilService,public datepipe: DatePipe) {
  
  }

  ngOnInit(): void {


    // this.minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    // this.getCategories();
    this.getUsers();
    this.getPerfiles();
    this.setRegisterForm();
    this.perfilService.selectedPerfil=new Perfil();
  
  }
  setRegisterForm(){
    let dateformat=this.datepipe.transform(this.userService.selectedUser.vigencia, 'yyyy-MM-dd')
    this.registerForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      ap_paterno: ['', Validators.required],
      ap_materno: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      celular: ['', Validators.required],
      nss: [''],
      usuario: ['', Validators.required],
      password: !this.edit?['', [Validators.required, Validators.minLength(6)]]:['',[]],
      confirmPassword: !this.edit?['', Validators.required]:['',[]],
      vigencia: [''],
      status: ['', Validators.required],
      perfil:['',Validators.required],
      borrar:['',Validators.required],
      insertar:['',Validators.required],
      modificar:['',Validators.required],
      notificacion:['',Validators.required],
      reporteemail:['',Validators.required],
      // acceptTerms: [false, Validators.requiredTrue]
  }, !this.edit?{
      validator: MustMatch('password', 'confirmPassword')
  }:{});
  this.registerForm.get('vigencia').patchValue(dateformat);


  }
  getPerfiles():void{
    this.perfilService.getPerfiles()
    .subscribe((res: any) => {
      this.perfilService.perfiles = res as Perfil[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    console.log("on submit");
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    this.userService.selectedUser.id_proyecto=userData.id_proyecto;
    this.userService.selectedUser.id_perfil=this.perfilService.selectedPerfil.id_perfil;
    this.userService.selectedUser.vigencia=this.registerForm.get('vigencia').value;
    console.log(userData);
    console.log(this.userService.selectedUser);
    if(this.registerForm.get('borrar').value){
      this.userService.selectedUser.borrar=1;
    }else{
      this.userService.selectedUser.borrar=0;
    }
    if(this.registerForm.get('insertar').value){      
      this.userService.selectedUser.insertar=1;
    }else{
      this.userService.selectedUser.insertar=0;
    }
    if(this.registerForm.get('modificar').value){      
      this.userService.selectedUser.modificar=1;
    }else{
      this.userService.selectedUser.modificar=0;
    }
    if(this.registerForm.get('notificacion').value){      
      this.userService.selectedUser.notificacion=1;
    }else{
      this.userService.selectedUser.notificacion=0;
    }
    if(this.registerForm.get('reporteemail').value){      
      this.userService.selectedUser.reporteemail=1;
    }else{
      this.userService.selectedUser.reporteemail=0;
    }
    console.log(this.userService.selectedUser);
    if (this.userService.selectedUser.id_usuario) {
      this.userService.putUser(this.userService.selectedUser)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.onReset();
            this.toastService.show('Usuario actualizado');
            this.getUsers();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    } else {
      this.userService.postUser(this.userService.selectedUser)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.onReset();
            this.toastService.show('Usuario creado');
            this.getUsers();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    }

  }

onReset() {
    this.edit=false;
    this.setRegisterForm();
    this.submitted = false;
    this.registerForm.reset();
    this.activeModal.close();
    this.perfilService.selectedPerfil=new Perfil();
}

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.userService.selectedUser = new User();
      this.getUsers();
    }).catch(err => {
      this.userService.selectedUser = new User();
      this.getUsers();
    });
  }
  editUsuario(usuario: User, content) {
    this.edit=true;
    this.userService.selectedUser = usuario;
    this.perfilService.selectedPerfil=this.perfilService.perfiles.find(e=>e.id_perfil==usuario.id_perfil);
    this.setRegisterForm();

    this.openModal(content);
  }
  async getUsers() {
    try {
      let response = await this.userService.getUsers().toPromise() as any;
      if (response.codigo!=200)
        this.toastService.show(response.message);
      else
      {
        this.userService.users = response.data as User[];
        this.filteredUsers=this.userService.users;

      }
    }
    catch (err) {
      this.toastService.show(err);
    }

    // .subscribe((res: any) => {
    //   this.userService.users = res.User as User[];
    // }, (err: HttpErrorResponse) => {
    //   this.toastService.show(err.message);
    // });

  }

  filterChange(e){
    e=e.toLocaleLowerCase();
    this.filteredUsers=this.userService.users.filter((val) => {
      let rVal = (val.usuario.toString().includes(e)) 
      || (val.nombres.toLocaleLowerCase().includes(e))
      || (val.ap_paterno.toLocaleLowerCase().includes(e))
      || (val.celular.toLocaleLowerCase().includes(e))
      || (val.email.toLocaleLowerCase().includes(e));
      return rVal;
    })

  }

}
