import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cajero } from 'src/app/models/cajero';
import { Estado } from 'src/app/models/estado';
import { Municipio } from 'src/app/models/municipio';
import { StatusCajero } from 'src/app/models/statuscajero';
import { TipoIncidencia } from 'src/app/models/tipoincidencia';
import { UserData } from 'src/app/models/user-data';
import { CajeroService } from 'src/app/services/cajero.service';
import { DetCajeroServicioService } from 'src/app/services/detcajeroservicio.service';
import { TipoIncidenciaService } from 'src/app/services/tipoincidencia.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-tipoincidencia',
  templateUrl: './tipo-incidencia.component.html',
  styleUrls: ['./tipo-incidencia.component.css']
})
export class TipoIncidenciaComponent implements OnInit {
  page_title = 'Tipo Incidencia';
  activeModal: NgbModalRef;
  page = 1;
  filter = "";
  pageSize = 10;
  registerForm: FormGroup;
  public filteredtipoincidencia: any[] = [];
  selectedTipoIncidencia:TipoIncidencia;
  isEdit=false;
  submitted=false;
  constructor(public tipoIncidenciaService:TipoIncidenciaService, public modal: NgbModal,public toastService: ToastService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    
    this.getTiposIncidencia();
    this.selectedTipoIncidencia=new TipoIncidencia();
    this.setRegisterForm()
  }
  setRegisterForm(){
    this.registerForm = this.formBuilder.group({
      name: [{value:'',disabled : this.isEdit}, Validators.required],
      status: ['', Validators.required],
  }, );


  }
  get f() { return this.registerForm.controls; }

  getTiposIncidencia():void{
    this.tipoIncidenciaService.getTipoIncidencia()
    .subscribe((res: any) => {
      this.tipoIncidenciaService.tiposincidencia = res.datos as TipoIncidencia[];
      this.filteredtipoincidencia=this.tipoIncidenciaService.tiposincidencia;
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  openModal(content) {
    this.setRegisterForm();
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.selectedTipoIncidencia = new TipoIncidencia();
    }).catch(() => {
      this.selectedTipoIncidencia = new TipoIncidencia();
    });
  }
  editTipoIncidencia(tipoincidencia:TipoIncidencia,content)
  {
    this.isEdit=true;
    this.selectedTipoIncidencia=tipoincidencia;
    this.openModal(content);
    
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    if (this.selectedTipoIncidencia.id_tipoincidencia) {
      this.tipoIncidenciaService.putTipoIncidencia(this.selectedTipoIncidencia)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.onReset();
            this.toastService.show('Tipo de incidencia actualizada');
            this.getTiposIncidencia();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    } else {
      this.selectedTipoIncidencia.name=this.selectedTipoIncidencia.name.toUpperCase();
      this.selectedTipoIncidencia.id_proyecto=1;
      this.tipoIncidenciaService.postTipoIncidencia(this.selectedTipoIncidencia)
        .subscribe((res: any) => {
          if (res.codigo==200) {
            this.onReset();
            this.toastService.show('Tipo de incidencia creada');
            this.getTiposIncidencia();
            this.activeModal.close();
          }
          else {
            this.toastService.show(res.message);
          }
        }, (error: HttpErrorResponse) => {
          this.toastService.show(error.message);
        });
    }

  }

  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.selectedTipoIncidencia = new TipoIncidencia();
    }
    this.getTiposIncidencia();
  }
  onReset(){
    this.selectedTipoIncidencia = new TipoIncidencia();
    this.activeModal.close();
    this.getTiposIncidencia();
    this.isEdit=false;
    this.submitted = false;
    
  }
  filterChange(e){
    e=e.toLocaleLowerCase();
    this.filteredtipoincidencia=this.tipoIncidenciaService.tiposincidencia.filter((val) => {
      let rVal = (val.id.includes(e)) 
      || (val.name.toLocaleLowerCase().includes(e))
      return rVal;
    })

  }
}
