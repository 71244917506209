import { Categoria } from './categoria';

export class User {
    constructor(t:any = {})
    {
        Object.assign(this, t);
    }
    id_usuario: number = 0;
    id_perfil: number = 0;
    id_proyecto: number = 0;
    id_menu: number = 1;
    usuario : String = '';
    vigencia : Date =new Date();
    ap_paterno : String = '';
    ap_materno : String = '';
    nombres :  String = '';
    telefono :  String = '';
    celular :  String = '';
    email: String = '';
    contrasena: String='';
    contrasena2: String='';
    nss: String = '';
    perfil: String = '';
    status : number = 1;
    borrar : number = 0;
    insertar : number = 0;
    modificar : number = 0;
    notificacion : number = 0;
    reporteemail : number = 0;
}