import { AfterViewInit, Component, OnInit, Renderer2  } from '@angular/core';
import { Form, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { Login } from 'src/app/models/login';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginData: Login;
  fieldTextType: boolean;

  constructor(private userService: UserService, public toastService: ToastService, private router: Router, private renderer: Renderer2) { 
   }

  ngOnInit() {        
    this.loginData = new Login();
    let user = JSON.parse(localStorage.getItem('userData'));
    if (user) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'bg');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'bg');
  }

  login(form?: NgForm) {
    
    this.userService.login({ "usuario": form.value.email, "contrasena": form.value.password, "gethash": false ,vigencia:new Date()})
      .subscribe((res: any) => {
        const userData = res.data;
        if (res.data) {
          this.userService.login({ "usuario": form.value.email, "contrasena": form.value.password, "gethash": true ,vigencia:new Date()})

          .subscribe((res: any) => {
              userData.token = res.token;
              userData.usuario=form.value.email;
              console.log(userData)
              localStorage.setItem('userData', JSON.stringify(userData));
              this.router.navigate(['/dashboard']);
            }, (error: any) => {
              this.toastService.show(error.error.message,{
                classname: 'bg-warning text-dark',
                headertext: 'Error'
              });
            });
        }
        else {
          this.toastService.show(res.message,{
            classname: 'bg-warning text-dark',
            headertext: 'Error'
          });
        }
      }, (error: any) => {
        this.toastService.show(error.error.message,{
          classname: 'bg-warning text-dark',
          headertext: 'Error'
        });
      });
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
