<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div class="card mt-4">
                <div class="card-header">
                </div>
                <div class="card-body">
                    <h2 class="card-title">{{page_title}}</h2>
                    <!-- <button class="btn btn-info pull-left" (click)="downloadExcel()" *ngIf="monitoreoService.datosmonitoreo.length > 0">
                        Descargar 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                          </svg>
                    </button> -->
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-wrapper">
                                <table class="table table-striped table-bordered" data-toggle="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Servicio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>VALOR</td>
                                        </tr>
                                        <!-- <tr *ngFor="let item of monitoreoService.datosmonitoreo | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                            <td *ngIf="item.Cajero !== undefined">{{item.Cajero}}</td>
                                        </tr> -->
                                    </tbody>
                                </table>
    
                            </div>
                            <!-- <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                            [collectionSize]="monitoreoService.datosmonitoreo.length" [maxSize]="5" [rotate]="true"
                            [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination> -->
                </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>