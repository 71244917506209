import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { UserData } from '../models/user-data';
import {MassiveEvent, PersonalEvent, ProviderEvent, EmployeeEvent } from '../models/event';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  selectedEvent: any;
  // selectedMEvent: MassiveEvent;
  // selectedProvider: ProviderEvent;
  // selectedPEvent: PersonalEvent;
  // selectedEmployee: EmployeeEvent;
  massiveEvents: MassiveEvent[];
  providerEvents: ProviderEvent[];
  personalEvents: PersonalEvent[];
  employeeEvents: EmployeeEvent[];
  events: any[] = [];

  constructor(private http: HttpClient) { }

  getEvents() {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.post(Global.URL_API + 'event/admonEvent', { _idUser: userData._id }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
