<!--Main Navigation-->
<header>
    <!-- <nav class="navbar fixed-top navbar-expand-lg navbar-light  red scrolling-navbar"> -->
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-danger scrolling-navbar"> 

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- <ul class="navbar-nav mr-auto text-white">
                <li class="nav-item active">
                    <div [routerLink]="['']" fragment="inicio">
                        <a class="nav-link text-white" href="#inicio"><strong>INICIO</strong>  <span class="sr-only">(current)</span></a>
                    </div>
                </li>
                <li class="nav-item ">
                    <div [routerLink]="['']" fragment="features">
                        <a class="nav-link text-white" href="#features"><strong>CARACTERÍSTICAS</strong> </a>
                    </div>
                </li>
                <li class="nav-item ">
                    <div [routerLink]="['']" fragment="solutions">
                        <a class="nav-link text-white" href="#solutions"><strong>SOLUCIONES</strong></a>
                    </div>
                </li>
                <li class="nav-item ">
                    <div [routerLink]="['']" fragment="contact">
                        <a class="nav-link text-white" href="#contact"><strong>CONTACTO</strong></a>
                    </div>
                </li>
            </ul> -->
            <ul class="navbar-nav nav-flex-icons">
                <!-- <li class="nav-item">
                    <a class="nav-link text-white"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white"><i class="fab fa-twitter"></i></a>
                </li> -->
                
                <!-- <li class="nav-item">
                    <a class="nav-link text-white" [routerLink]="['/residentes']"><i class="fas fa-house-user"></i> <strong> REGISTRO</strong> </a>
                </li> -->
                <li>
                    <a class="nav-link text-white" [routerLink]="['/login']"><i class="fas fa-sign-in-alt"></i> <strong> INICIAR SESIÓN</strong> </a>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!--Main Navigation-->