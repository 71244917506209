<div class="row d-flex align-items-center">
    <div class="col-12 col-md-6 pt-md-5 mt-md-5">
        <div class="offset-1 col-10">
            <h1 class="h1-responsive text-justify">
                <strong class="text-danger text-uppercase">
                    da valor agregado
                    a tu residencial o empresa
                </strong>
            </h1>
        </div>
        <div class="offset-1 col-10 mt-lg-5">
            <h1 class="h1-responsive text-justify">
                Incorporando QuickAccess, el sistema
                de registro y recepción de visitantes, que te facilita el control de acceso, ofreciendo la
                tranquilidad, seguridad y rapidez que necesitas al ingresar a tu inmueble residencial o corporativo
                empresarial.
            </h1>
        </div>
    </div>
    <div class="col-12 col-md-6 pt-lg-5 mt-lg-5">
        <img src="assets/banner-3.jpg" alt="Familia" class="img-fluid">
    </div>
</div>
<div class="row d-flex align-items-center mt-md-5">
    <div class="col-12 col-md-6 px-md-5">
        <h1 class="h1-responsive text-center"> TENER EL CONTROL DE ACCESO DE TUS VISITAS
            ES MUY RÁPIDO, FÁCIL Y SEGURO CON QUICKACCESS </h1>
        <img src="assets/banner-3.jpg" alt="Phones" class="img-fluid">
    </div>
    <div class="col-12 col-md-6 px-md-5">
        <div class="col-12 mb-2">
            <mdb-card>
                <mdb-card-body class="pt-0 pb-0">
                    <mdb-card-title>
                        <div class="row py-2 px-4 d-flex align-items-center my-2">
                            <div class="col-3 col-sm-3 col-md-2 col-lg-2 pr-2 pl-0">
                                <img src="assets/banner-3.jpg" alt="phone"
                                    class="img-fluid">
                            </div>
                            <div class="col-9 col-sm-9 col-md-10 col-lg-10 pr-0 pl-0">
                                <h2 class="h2-responsive text-left">
                                    APLICACIÓN PARA GENERAR LISTA DE INVITADOS
                                </h2>
                            </div>
                        </div>
                    </mdb-card-title>
                    <mdb-card-text>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="h3-responsive text-justify">
                                    - Facilita la creación de
                                    eventos, brindando rápida admisión y seguridad a tus invitados. <br>
                                    - Genera
                                    invitaciones
                                    y autoriza visitas
                                    desde tu celular.
                                </h3>
                            </div>
                        </div>
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-12 mb-2">
            <mdb-card>
                <mdb-card-body class="pt-0 pb-0">
                    <mdb-card-title>
                        <div class="row py-3 px-4 d-flex align-items-center my-2">
                            <div class="col-3 col-sm-3 col-md-2 col-lg-2 pr-2 pl-0">
                                <img src="assets/banner-3.jpg" alt="phone"
                                    class="img-fluid">
                            </div>
                            <div class="col-9 col-sm-9 col-md-10 col-lg-10 pr-0 pl-0">
                                <h2 class="h2-responsive text-left">
                                    RECONOCIMIENTO POR CÓDIGO QR Y
                                    PIN
                                </h2>
                            </div>
                        </div>
                    </mdb-card-title>
                    <mdb-card-text>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="h3-responsive text-justify">
                                    Generación de control de
                                    acceso por código QR y PIN, garantizando que el ingreso sea ágil y confiable.
                                </h3>
                            </div>
                        </div>
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-12 mb-2">
            <mdb-card>
                <mdb-card-body class="pt-0 pb-0">
                    <mdb-card-title>
                        <div class="row py-3 px-4 d-flex align-items-center my-2">
                            <div class="col-3 col-sm-3 col-md-2 col-lg-2 pr-2 pl-0">
                                <img src="assets/banner-3.jpg" alt="phone"
                                    class="img-fluid">
                            </div>
                            <div class="col-9 col-sm-9 col-md-10 col-lg-10 pr-0 pl-0">
                                <h2 class="h2-responsive text-left"> COMUNICACIÓN ÁGIL CON
                                    PROVEEDORES </h2>
                            </div>
                        </div>
                    </mdb-card-title>
                    <mdb-card-text>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="h3-responsive text-justify ">
                                    Acceso rápido a la lista de
                                    proveedores registrados, para que puedas contactar con ellos de forma segura.
                                </h3>
                            </div>
                        </div>
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-12">
            <mdb-card>
                <mdb-card-body class="pt-0 pb-0">
                    <mdb-card-title>
                        <div class="row py-3 px-4 d-flex align-items-center my-2">
                            <div class="col-3 col-sm-3 col-md-2 col-lg-2 pr-2 pl-0">
                                <img src="assets/banner-3.jpg" alt="phone"
                                    class="img-fluid">
                            </div>
                            <div class="col-9 col-sm-9 col-md-10 col-lg-10 pr-0 pl-0">
                                <h2 class="h2-responsive text-left">AGILIDAD AL INGRESAR AL RESIDENCIAL O EMPRESA
                                </h2>
                            </div>
                        </div>
                    </mdb-card-title>
                    <mdb-card-text>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="h3-responsive text-justify"> Admisión rápida para tus
                                    visitantes, gracias al código QR y PIN, que te permite validar su entrada. </h3>
                            </div>
                        </div>
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>