import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../config/config';
import { Cajero } from '../models/cajero';
import { DetalleIncidencia, Incidencia } from '../models/incidencia';
import { OrigenIncidencia, TipoIncidencia } from '../models/tipoincidencia';
import { User } from '../models/user';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class IncidenciaService {
  selectedIncidencia:Incidencia;
  selectedTipoIncidencia:TipoIncidencia;
  selectedOrigenIncidencia:OrigenIncidencia;
  selectedDetalleIncidencia:DetalleIncidencia;
  incidencias:Incidencia[]=[];
  detalles:DetalleIncidencia[]=[];
  constructor(private http: HttpClient) { }

  getIncidencias(tipoincidencia:TipoIncidencia,cajero:number,tecnico:number,fechaInicio:string,fechafin:string,usuario:number,id_statusincidencia:number) {
    const body={fechainicio:fechaInicio,fechafin:fechafin,num_cajero:cajero,id_usuario:tecnico,id_tipoincidencia:tipoincidencia.id_tipoincidencia,usuario_reporto:usuario,id_statusincidencia:id_statusincidencia};
    return this.http.post(Global.URL_API + 'incidencias',body,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  getIncidenciasByUser(iduser:string) {
    return this.http.get(Global.URL_API + 'incidencia' + `/${iduser}`,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  putIncidencia(incidencia: Incidencia) {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    incidencia.comentarios=incidencia.comentarios?.toUpperCase();
    incidencia.visto_bueno=incidencia.visto_bueno?.toUpperCase();
    return this.http.put(Global.URL_API + 'incidencia' + `/${incidencia.id_incidencia}`, incidencia, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  putFecha(incidencia: Incidencia,fecha_resuelto:string,hora_resuelto:string) {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.put(Global.URL_API + 'incidenciafecha' + `/${incidencia.id_incidencia}`, {fecha_resuelto:fecha_resuelto,hora_resuelto:hora_resuelto}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  postIncidencia(incidencia:Incidencia) {
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    return this.http.post(Global.URL_API + 'incidencia' , incidencia, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
    return undefined;
  }
  getDetalleIncidencia(idIncidencia:string) {
    return this.http.get(Global.URL_API + 'detalleincidencia' + `/${idIncidencia}`,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
  postDetalleIncidencia(detalleincidencia:DetalleIncidencia) {
    return this.http.post(Global.URL_API + 'detalleincidencia' , detalleincidencia, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('userData')).token
      })
    });
  }
}
