import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-excel',
  templateUrl: './reporte-excel.component.html',
  styleUrls: ['./reporte-excel.component.css']
})
export class ReporteExcelComponent implements OnInit {
  page_title = 'Reporte detalle de pago de servicios';

  constructor() { }

  ngOnInit(): void {
  }

}
