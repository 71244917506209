import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderBy } from '../../services/orderBy.pipe';
import { Cajero } from 'src/app/models/cajero';
import { DatosReporteGeneralServicios } from 'src/app/models/reportes';
import { Servicio } from 'src/app/models/servicio';
import { User } from 'src/app/models/user';
import { UserData } from 'src/app/models/user-data';
import { CajeroService } from 'src/app/services/cajero.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toastservice.service';

@Component({
  selector: 'app-reporte-servicios',
  templateUrl: './reporte-servicios.component.html',
  styleUrls: ['./reporte-servicios.component.css'],
  providers: [DatePipe,OrderBy]
})
export class ReporteServiciosComponent implements OnInit {
  public selectedCajero: number=0;
  public selectedServicio:Servicio=new Servicio();
  public selectedTecnico:User=new User();
  public selectedFechaInicio:Date=new Date();
  public selectedFechaFin:Date=new Date();
  public idTecnico:number=0;
  public nombreTecnico:string="";
  public today:string;
  myForm:FormGroup;
  order: string = '';
  templist = [];
  isLoading = false;

  page_title = 'Reporte General de Pago de Servicios';

  constructor(public cajeroService:CajeroService,public servicioService:ServicioService,public userService:UserService,public reporteService:ReportesService,public datepipe: DatePipe, public toastService: ToastService) { 
  }

  ngOnInit(): void {
    let date=new Date();
    let dateformat=this.datepipe.transform(date, 'yyyy-MM-dd')
    this.today=dateformat;
    let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
    this.myForm = new FormGroup({
      'fechaInicio': new FormControl(dateformat),
      'fechafin': new FormControl(dateformat),
      'servicio': new FormControl(""),
      'cajero': new FormControl(""),
      'tecnico': new FormControl("")
    });
    if(userData.category.name=='TECNICO'){
      this.idTecnico=Number(userData._id);
      this.nombreTecnico=userData.name+' '+userData.surname;

    }
    this.getCajeros();
    this.getServicios();
    this.getTecnicos();
    this.getReporteGeneral();

  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
        index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
      if(userData.category.name=='TECNICO'){
        this.cajeroService.cajeros=this.cajeroService.cajeros.filter((val) => {
          let rVal = (val.id_usuario.toString()==userData._id);
          return rVal;
        })
      }
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getServicios():void{
    this.servicioService.getServicios()
    .subscribe((res: any) => {
      this.servicioService.servicios = res as Servicio[];
      this.servicioService.servicios=this.servicioService.servicios.filter((val) => {
        let rVal = (val.padre==0) 
        return rVal;
      });
  
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getReporteGeneral(): void {
    this.isLoading = true;

    this.reporteService.getReporteGeneralServicios(this.selectedServicio,this.selectedCajero,this.idTecnico>0?this.idTecnico:this.selectedTecnico.id_usuario,this.datepipe.transform(this.selectedFechaInicio, 'yyyy-MM-dd'),this.datepipe.transform(this.selectedFechaFin, 'yyyy-MM-dd'))
      .subscribe((res: any) => {
        this.isLoading=false;
        this.reporteService.datosreporteGeneral = res.data as DatosReporteGeneralServicios;
      }, (err: HttpErrorResponse) => {
        this.isLoading=false;
        console.log('err',err);
      })
    //this.getReporteTmas1();
  }
  getReporteTmas1(item:any): void {
    this.reporteService.getReporteTmas1(item.Fecha,item.Fecha)
      .subscribe((res: any) => {
        this.reporteService.datostmas1 = res.data as any[];
        this.printTmas1(item,'');
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getReporteTmas1Tarjeta(item:any): void {
    this.reporteService.getReporteTmas1Tarjeta(item.Fecha,item.Fecha)
      .subscribe((res: any) => {
        this.reporteService.datostmas1 = res.data as any[];
        this.printTmas1(item,'tarjeta');
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }  
  printTmas1(item:any,name:any){
    // if(item.Fecha==this.today){
    //   this.toastService.show('Favor de descargar este archivo a partir del día de mañana. Gracias');
    //   return;

    // }
    let texto=this.getInfoTmas1(item.Fecha)
    this.dyanmicDownloadByHtmlTag({
      fileName: item.Fecha.replaceAll('-','')+name+'.txt',
      text: texto
    });
  }
  getInfoTmas1(fecha:any):string{
    let reporte='';
    this.reporteService.datostmas1.forEach(function (value) {
      if(value.Fecha==fecha)
      {
        value.Registros.forEach(function(registro)  {
          reporte=reporte+registro.Convenio;
          reporte=reporte+registro.Referencia;
          reporte=reporte+registro.Concepto.replaceAll('-',' ').replaceAll('Á','A').replaceAll('É','E').replaceAll('Í','I').replaceAll('Ó','O').replaceAll('Ú','U');
          reporte=reporte+registro.Importe;
          reporte=reporte+registro.Comision;
          reporte=reporte+registro.Abono;
          reporte=reporte+registro.GuiaCIE;
          reporte=reporte+registro.Fecha.substring(0,10);
          reporte=reporte+registro.Plaza.toUpperCase().replaceAll('-',' ').replaceAll('Á','A').replaceAll('É','E').replaceAll('Í','I').replaceAll('Ó','O').replaceAll('Ú','U');
          reporte=reporte+registro.Sucursal;
          reporte=reporte+registro.TipoPago;
          reporte=reporte+'\n'
        });
        value.Totales.forEach(total => {
          reporte=reporte+total.Nombre;
          reporte=reporte+total.Total;
          reporte=reporte+'\n'
          
        });
      }
    })
    return reporte;
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string}) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    //const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    const fileType = 'text/plain';
    console.log(fileType);
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  displayedColumns = ['Cajero', 'Ubicacion', 'Nombre', 'Total','MontoTotal'];
  sortByCol(name: string) {
    this.order = name;
  }
}
