export class TipoIncidencia {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    id_tipoincidencia: number = 0;
    id_proyecto: number = 0;
    id:string='';
    name: string = '';
    status: number = 1;
}
export class OrigenIncidencia {
    constructor(t:any = {}){
        Object.assign(this, t);
    }
    id_origenincidencia: number = 0;
    name: string = '';
    status: number = 1;
}
