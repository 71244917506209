import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, PipeTransform  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MonitoreoService } from 'src/app/services/monitoreo.service';
import { CajeroService } from 'src/app/services/cajero.service';
import { StatusCajeroService } from 'src/app/services/statuscajero.service';
import { Cajero } from 'src/app/models/cajero';
import { StatusCajero } from 'src/app/models/statuscajero';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserData } from 'src/app/models/user-data';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExcelService } from 'src/app/services/excel.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-monitoreo-cajero',
  templateUrl: './monitoreocajero.component.html',
  styleUrls: ['./monitoreocajero.component.css'],
  providers: [DecimalPipe,DatePipe]

})
export class MonitoreoCajerosComponent implements OnInit {
  page_title = 'Monitoreo de Cajeros';

  public selectedStatusCajero:StatusCajero=new StatusCajero();
  public selectedTecnico:User=new User();
  public filteredmonitoreo: any[] = [];
  public selectedCajero: number=0;

  page = 1;
  pageSize = 10;
  collectionSize = 0;
  filter = "";
  public nombreTecnico:string="";
  myForm:FormGroup;
  constructor(public monitoreoService: MonitoreoService,public cajeroService:CajeroService,public statusCajeroService:StatusCajeroService,public userService:UserService, private modal: NgbModal, pipe: DecimalPipe,public datepipe: DatePipe,private excelService:ExcelService) {

  }

  ngOnInit(): void {
    this.getCajeros();
    this.getStatusCajero();
    this.getTecnicos();
    this.getDataMonitoreo();
    this.myForm = new FormGroup({
      'cajero': new FormControl(""),
      'tecnico': new FormControl(""),
      'status': new FormControl("")
    });

  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.cajeroService.cajeros.forEach(index=>{
        index.tempLabel=index.numero+' - '+index.ubicacionfisica;
      })
      if(userData.category.name=='TECNICO'){
        this.cajeroService.cajeros=this.cajeroService.cajeros.filter((val) => {
          let rVal = (val.id_usuario.toString()==userData._id);
          return rVal;
        })
      }
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getStatusCajero():void{
    this.statusCajeroService.getStatusCajero()
    .subscribe((res: any) => {
      this.statusCajeroService.statusCajero = res as StatusCajero[];
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }
  getDataMonitoreo(): void {
    this.monitoreoService.getMonitoreoCajeros(this.selectedCajero,new StatusCajero(),this.selectedTecnico)
      .subscribe((res: any) => {
        let userData: UserData = <UserData>JSON.parse(localStorage.getItem('userData'));
        this.monitoreoService.datosmonitoreo = res.datos as any[];
        if(userData.category.name=='TECNICO'){
          this.monitoreoService.datosmonitoreo=this.monitoreoService.datosmonitoreo.filter((val) => {
            let rVal = (val.id_usuario.toString()==userData._id.toString());
            return rVal;
          })
          this.nombreTecnico=userData.name+" "+userData.surname;
        }
        this.monitoreoService.datosmonitoreo.forEach(registro => {
          var totalefectivo;
          var folios;
          totalefectivo=registro.M1p+registro.M5p*5+registro.M10p*10+registro.B50p*50;
          folios=(registro.FolioActualB1==''&&registro.FolioActualB2=='')?0:(registro.FolioRestantesB1+registro.FolioRestantesB2);
          if(registro.Diferencia>=2){
            registro.StatusCajero="SIN CONEXIÓN A INTERNET"
          }
          else if(totalefectivo<500&&(registro.FolioRestantesB1<15|| registro.FolioRestantesB2<15)){
              registro.StatusCajero="POCOS FOLIOS Y POCO EFECTIVO"
          }else if(totalefectivo<500){
              registro.StatusCajero="POCO EFECTIVO"
          }
          else if(registro.FolioRestantesB1<15|| registro.FolioRestantesB2<15){
            registro.StatusCajero="POCOS FOLIOS"
          }
          else
          {
            registro.StatusCajero="CORRECTO"
          }


        });
        this.filteredmonitoreo=this.monitoreoService.datosmonitoreo;
        if(this.selectedStatusCajero.name)
        {
          this.filteredmonitoreo=this.filteredmonitoreo.filter((val) => {
            let rVal = (val.StatusCajero==this.selectedStatusCajero.name) 
            return rVal;
          });
        }
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }
  getTecnicos(): void {
    const perfil='TECNICOS'
    this.userService.getUsers(perfil)
      .subscribe((res: any) => {
        this.userService.tecnicos = res.data;
      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })
  }




  exportPDF(){
    let date:Date=new Date();    const doc = new jsPDF()
    let data:any[]=this.jsontoArr();
    autoTable(doc, {   columnStyles: { europe: { halign: 'center' } }, // European countries centered
    body: data,
    columns: [
      { header: 'Cajero', dataKey: 'Cajero' },
      { header: 'Municipio', dataKey: 'Municipio' },
      { header: 'Ubicación', dataKey: 'Ubicacion' },
      { header: 'Fecha y Hora', dataKey: 'Fecha y Hora' },
      { header: 'Status', dataKey: 'Status' },
      { header: 'Bandeja 1', dataKey: 'Bandeja 1' },
      { header: 'Bandeja 2', dataKey: 'Bandeja 2' },
      { header: 'M1P', dataKey: 'M1P' },
      { header: 'M5P', dataKey: 'M5P' },
      { header: 'M10P', dataKey: 'M10P' },
      { header: 'B50P', dataKey: 'B50P' },
      { header: 'Versión', dataKey: 'Version' },
      { header: 'Cambio', dataKey: 'Cambio' },
      { header: 'Técnico', dataKey: 'Tecnico' },
    ], })
    doc.save('MonitoreoCajeros'+this.datepipe.transform(date, 'ddMM')+'.pdf')

  }
  jsontoArr():any[]
  {
    let data:any[]=[];
    for(var i in this.monitoreoService.datosmonitoreo) 
    {
      let row:any[]=[this.monitoreoService.datosmonitoreo[i].Cajero,this.monitoreoService.datosmonitoreo[i].Municipio,this.monitoreoService.datosmonitoreo[i].Ubicacion,this.datepipe.transform(this.monitoreoService.datosmonitoreo[i].Fecha, 'dd/MM/yyyy')+" "+this.datepipe.transform(this.monitoreoService.datosmonitoreo[i].Hora, 'HH:mm:ss'),this.monitoreoService.datosmonitoreo[i].StatusCajero,this.monitoreoService.datosmonitoreo[i].FolioRestantesB1,this.monitoreoService.datosmonitoreo[i].FolioRestantesB2,this.monitoreoService.datosmonitoreo[i].M1p,this.monitoreoService.datosmonitoreo[i].M5p*5,this.monitoreoService.datosmonitoreo[i].M10p*10,this.monitoreoService.datosmonitoreo[i].B50p*50,this.monitoreoService.datosmonitoreo[i].Version,this.monitoreoService.datosmonitoreo[i].Stock,this.monitoreoService.datosmonitoreo[i].Nombre+' '+this.monitoreoService.datosmonitoreo[i].ApPaterno+' '+this.monitoreoService.datosmonitoreo[i].ApMaterno];
      data.push(row);
    }
    return data;
  
  }
  exportExcel(){
    {
      let date:Date=new Date();
      let header:string[]=["Cajero","Municipio","Ubicación","Fecha y Hora","Status","Formatos para Actas","Hojas Bond","M1P","M5P","M10P","B50P","Versión","Cambio","Técnico"]
      let data:any[]=this.jsontoArr();
       /* table id is passed over here */   
      
      this.excelService.generateExcel(header,data,"Monitoreo de Cajeros","A1:N1",'MonitoreoCajeros'+this.datepipe.transform(date, 'ddMM')+'.xlsx');
    }
  }
  
  filterChange(e){
    e=e.toLocaleLowerCase();
    this.filteredmonitoreo=this.monitoreoService.datosmonitoreo.filter((val) => {
      let rVal = (val.Cajero.toString().includes(e)) 
      || (val.Municipio.toLocaleLowerCase().includes(e))
      || (val.Ubicacion.toLocaleLowerCase().includes(e))
      || (val.Fecha.toLocaleLowerCase().includes(e))
      || (val.Hora.toLocaleLowerCase().includes(e))
      || (val.StatusCajero.toLocaleLowerCase().includes(e))
      || (val.FolioRestantesB1.toLocaleLowerCase().includes(e))
      || (val.FolioRestantesB2.toLocaleLowerCase().includes(e))
      || (val.M1p.toString().includes(e))
      || (val.M5p.toString().includes(e))
      || (val.M10p.toString().includes(e))
      || (val.B50p.toString().includes(e))
      || (val.Version.toLocaleLowerCase().includes(e))
      || (val.Stock.toString().includes(e))
      || (val.Nombre.toLocaleLowerCase().includes(e))
      || (val.ApPaterno.toLocaleLowerCase().includes(e))
      || (val.ApMaterno.toLocaleLowerCase().includes(e));
      return rVal;
    })

  }
  changeCajero(e)
  {
  }
}
