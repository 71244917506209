import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ToastService } from 'src/app/services/toastservice.service';
import { Form, FormGroup, NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { UserAddressService } from '../../services/user-address.service';
import { User } from 'src/app/models/user';
import { UserAddress } from 'src/app/models/user-address';
import { InmuebleService } from 'src/app/services/inmueble.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Inmueble } from 'src/app/models/inmueble';
import { CategoriaService } from 'src/app/services/categoria.service';
import { Categoria } from 'src/app/models/categoria';
import { Residential } from 'src/app/models/residential';
import { TipoInmuebleService } from 'src/app/services/tipo-inmueble.service';
import { TipoInmueble } from 'src/app/models/tipo-inmueble';

@Component({
  selector: 'app-residentes',
  templateUrl: './residentes.component.html',
  styleUrls: ['./residentes.component.css']
})

export class ResidentesComponent implements OnInit, AfterViewInit {
  @ViewChild('btnRegistro') button: ElementRef;
  @ViewChild('registroForm') form: FormGroup;
  @ViewChild('selectInmueble') selectInmueble: ElementRef;

  public registrado: boolean;
  public tiposRegistro = [
    { val: 'ID', text: 'Usando mi ID de fraccionamiento' },
    { val: 'LISTA', text: 'Prefiero elegir de una lista mi fraccionamiento' },
    { val: 'CUENTARESIDENTE', text: 'Usando mi número de cuenta de residente' }
  ];
  public privedifcalle = [
    { val: 'CALLE', text: 'Calle' },
    { val: 'EDIFICIO', text: 'Edificio' },
    { val: 'PRIVADA', text: 'Privada' }
  ];
  tipoRegistro = "ID";
  showPrivadaEdif = false;
  canSelectPropertyType = true;
  public residenciales: Residential[];
  padreId = '-1';
  isValidAccount = false;

  constructor(
    public toast: ToastService,
    private renderer: Renderer2,
    public userService: UserService,
    public userAddressService: UserAddressService,
    public inmuebleService: InmuebleService,
    private categoriaService: CategoriaService,
    public tipoInmuebleService: TipoInmuebleService
  ) {
    this.registrado = false;
  }

  ngOnInit(): void {
    // this.getInmuebles();
    // this.init();
  }

  // async init() {
  //   try {
  //     let res = await this.categoriaService.getCategorias().toPromise() as any;
  //     this.categoriaService.categories = res.Category as Categoria[];
  //     res = await this.userAddressService.getResidentials().toPromise() as any;
  //     this.residenciales = res.ResidentialArea as Residential[];
  //     this.tipoInmuebleService.data = [];
  //     this.userService.selectedUser = new User();
  //     this.userAddressService.selectedUserAddress = new UserAddress();
  //     this.padreId = "-1";
  //     // let resp = await this.tipoInmuebleService.getTipoInmueble().toPromise() as any;
  //     // this.tipoInmuebleService.data = resp.tipoInmueble;
  //   }
  //   catch (err) {
  //     console.error(err.message);
  //   }
  // }

  // async getInmuebles() {
  //   try {
  //     let res = await this.inmuebleService.getInmuebles().toPromise() as any;
  //     this.inmuebleService.inmuebles = res.Property as Inmueble[];
  //   }
  //   catch (err) {
  //     this.toast.show(err.message);
  //   }
  // }

  // async registrarResidente(form?: NgForm) {
  //   try {
  //     if (!this.showPrivadaEdif) {
  //       this.userAddressService.selectedUserAddress.calle = this.form.controls["calle"].value;
  //     }
  //     else {
  //       this.userAddressService.selectedUserAddress.calle = "";
  //     }
  //     // if(this.tipoRegistro == "CUENTARESIDENTE" && this.showPrivadaEdif)
  //     if(this.tipoRegistro == "CUENTARESIDENTE" && this.userAddressService.selectedUserAddress.tipoInmueble._id !== '')
  //     {
  //       this.userAddressService.selectedUserAddress.calle = "";
  //     }
  //     console.log(this.userService.selectedUser);
  //     console.log(this.userAddressService.selectedUserAddress);
  //     let usr = await this.userService.postUser(this.userService.selectedUser).toPromise() as any;
  //     if (usr.message) {
  //       this.toast.show(usr.message);
  //     }
  //     else {
  //       let userx = usr.User as User;
  //       this.userAddressService.selectedUserAddress.idUsuario = userx._id;
  //       let usrAddress = await this.userAddressService.postUserAddress(this.userAddressService.selectedUserAddress).toPromise() as any;
  //       if (usrAddress.message) {
  //         this.toast.show("error al registrar usuario");
  //       }
  //       else {
  //         this.toast.show("registro exitoso");
  //         this.registrado = true;
  //       }
  //     }
  //   }
  //   catch (err) {
  //     this.toast.show(err.message);
  //   }
  // }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'bg');
  }

  // ngOnDestroy(): void {
  //   this.renderer.removeClass(document.body, 'bg');
  // }

  // async onChangeEvent(event: any) //Evento cuando se elige alta de residente por listado de fraccionamientos o por ID de fraccionamiento
  // {
  //   try {
      
  //     this.form.controls["selectInmueble"].reset();
  //     if (this.showPrivadaEdif)
  //     {
  //       this.tipoInmuebleService.data = [];
  //       this.form.controls["tipoInmueble"].reset();
  //     }
        
  //     this.padreId = event.target.value as string;
  //     let res = await this.userAddressService.getUserAddress(event.target.value).toPromise() as any;
  //     if (res.message) {
  //       this.isValidAccount = false;
  //       this.toast.show(res.message);
  //       await this.init();
  //     }
  //     else {
  //       this.isValidAccount = true;
  //       let result = res.UserAddress as UserAddress;
  //       this.userAddressService.selectedUserAddress.alias = result.alias;
  //       this.userAddressService.selectedUserAddress.colonia = result.alias;
  //       this.userAddressService.selectedUserAddress.cp = result.cp;
  //       this.userAddressService.selectedUserAddress.idInmueble = result.idInmueble;
  //       // this.userAddressService.selectedUserAddress.tipoInmueble = result.tipoInmueble;
  //       let noCuenta = Date.now().toString();
  //       this.userAddressService.selectedUserAddress.noCuenta = noCuenta.substr(0, noCuenta.length - 2);
  //       this.userService.selectedUser.validity = (new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())).toISOString();
  //       this.userService.selectedUser.status = 2;
  //       this.userService.selectedUser.category = this.categoriaService.categories.find(x => x.nombre == "Residente");
  //     }
  //   }
  //   catch (err) {
  //     this.toast.show(err.message);
  //   }
  // }

  // async onNoCuentaSelected(event: any)//Evento cuando se elige el alta por el numero de cuenta del residente
  // {
  //   try {
  //     let res = await this.userAddressService.getUserAddressByAccountNumber(event.target.value).toPromise() as any;
  //     if (res.message) {
  //       this.isValidAccount = false;
  //       this.toast.show(res.message);
  //       this.userAddressService.selectedUserAddress = new UserAddress();
  //     }
  //     else {
  //       this.isValidAccount = true;
  //       let result = res.UserAddress as UserAddress;
  //       console.log(result);
        
  //       this.userAddressService.selectedUserAddress.alias = result.alias;
  //       this.userAddressService.selectedUserAddress.colonia = result.alias;
  //       this.userAddressService.selectedUserAddress.cp = result.cp;
  //       this.userAddressService.selectedUserAddress.idInmueble = result.idInmueble;
  //       if(result.tipoInmueble._id !== '')
  //       {
  //         this.userAddressService.selectedUserAddress.tipoInmueble = result.tipoInmueble;
  //       }
  //       else
  //       {
  //         this.userAddressService.selectedUserAddress.calle = result.calle;
  //       }
  //       this.userAddressService.selectedUserAddress.numExt = result.numExt;
  //       this.userAddressService.selectedUserAddress.numInt = result.numInt;
  //       this.userAddressService.selectedUserAddress.noHabitantes = result.noHabitantes;
  //       this.padreId = res.User.padreId;
  //       this.userService.selectedUser.validity = (new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())).toISOString();
  //       this.userService.selectedUser.status = 2;
  //       this.userService.selectedUser.category = this.categoriaService.categories.find(x => x.nombre == "Residente");
  //     }
  //   }
  //   catch (err) {
  //     this.toast.show(err.message);
  //   }
  // }

  // async onSelectInmueble(event: any) {
  //   if (event.target.value != "CALLE") {
  //     this.showPrivadaEdif = true;
  //     //se obtienen los tipos de inmueble (privadas/edificios)
  //     let resp = await this.tipoInmuebleService.getTipoInmueblePorTipo(this.padreId, (event.target.value)).toPromise() as any;
  //     this.tipoInmuebleService.data = resp.PropertyType as TipoInmueble[];
  //   }
  //   else {
  //     this.userAddressService.selectedUserAddress.tipoInmueble = new TipoInmueble();
  //     this.form.controls["selectInmueble"].setValue(this.privedifcalle[0].val);
  //     this.showPrivadaEdif = false;
  //   }
  // }

  // async onPropertyTypeChange(event: any) {
    
  // }

  // onSelectRegistro(event: any, frm: NgForm) {
  //   this.init();
  //   this.tipoRegistro = event.target.value;
  //   this.isValidAccount = false;
  //   frm.reset();
  //   this.userAddressService.selectedUserAddress = new UserAddress();
  //   this.userService.selectedUser = new User();
    
  //   if (this.tipoRegistro == 'CUENTARESIDENTE') {
  //     this.canSelectPropertyType = false;
  //     this.showPrivadaEdif = false;
  //     this.form.controls['numExt'].disable();
  //     this.form.controls['numInt'].disable();
  //     this.form.controls['noHabitantes'].disable();
  //   }
  //   else {
  //     this.canSelectPropertyType = true;
  //     this.showPrivadaEdif = false;
  //     this.form.controls['numExt'].enable();
  //     this.form.controls['numInt'].enable();
  //     this.form.controls['noHabitantes'].enable();
      
  //   }
  // }

  // compareFn(ua1: any, ua2: any): boolean {
  //   return ua1 && ua2 ? ua1._id === ua2._id : ua1 === ua2;
  // }

}
