<div class="col-md-6 mx-auto" *ngIf="!registrado">
    <div class="card-group mt-4">
        <div class="card">
            <div class="card-body">
                <form ngForm #registroForm="ngForm" ngNativeValidate (ngSubmit)="registrarResidente(registroForm)">
                    <!-- <form ngForm #loginForm="ngForm" (ngSubmit)="login(loginForm)" ngNativeValidate> -->
                    <h1>QuickAccess</h1>
                    <p class="text-muted">Registrate aquí en tu fraccionamiento</p>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <label for="tiposRegistro">¿Cómo vas a realizar tu registro?</label>
                                <select class="form-control" name="tiposRegistro" id="tiposRegistro"
                                    (change)="onSelectRegistro($event, registroForm)">
                                    <option *ngFor="let opt of tiposRegistro" [value]="opt.val">{{opt.text}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                        <!-- <div class="form-group"> -->
                        <!-- <label for="">¿Cómo vas a realizar tu registro?</label> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="tipoRegistro"
                                    id="tipoRegistro" checked (change)="onChangeEvent($event)">
                                <label class="form-check-label" for="tipoRegistro">
                                    Usando mi ID de fraccionamiento
                                </label>
                            </div>
                        </div> -->
                        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="tipoRegistro"
                                    id="tipoRegistro2" (change)="onChangeEvent($event)">
                                <label class="form-check-label" for="tipoRegistro2">
                                    Prefiero elegir de una lista mi fraccionamiento
                                </label>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="tipoRegistro == 'ID'">
                            <div class="form-group">
                                <label for="idFraccionamiento">ID de fraccionamiento</label>
                                <input name="idFraccionamiento" id="idFraccionamiento" #idFraccionamiento
                                    class="form-control" placeholder="Ingresa el ID de tu fraccionamiento" required
                                    type="text" (change)="onChangeEvent($event)">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="tipoRegistro == 'LISTA'">
                            <div class="form-group">
                                <label for="idFraccionamiento">Lista de fraccionamientos</label>
                                <select class="form-control" name="idFraccionamiento" id="idFraccionamiento"
                                    #idFraccionamiento="ngModel" (change)="onChangeEvent($event)"
                                    [(ngModel)]="residenciales.idUsuario">
                                    <option *ngFor="let res of residenciales" [value]="res.idUsuario">{{res.alias}} -
                                        {{res.colonia}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="tipoRegistro == 'CUENTARESIDENTE'">
                            <div class="form-group">
                                <label for="noCuenta">Número de cuenta de residente</label>
                                <input name="noCuenta" id="noCuenta" #noCuenta="ngModel" ngModel class="form-control"
                                    placeholder="Ingresa tu número de cuenta de residente " required type="text"
                                    (change)="onNoCuentaSelected($event)">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="idInmueble">Tipo de inmueble</label>
                                <select class="form-control" name="idInmueble" id="idInmueble" #idInmueble="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.idInmueble" disabled>
                                    <option [value]="undefined"></option>
                                    <option *ngFor="let inmueble of inmuebleService.inmuebles" [value]="inmueble._id">
                                        {{inmueble.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="calle">Nombre del inmueble</label>
                                <input class="form-control" type="text" name="alias" id="alias" #alias="ngModel"
                                    [ngModel]="userAddressService.selectedUserAddress.alias"
                                    [value]="userAddressService.selectedUserAddress.alias"
                                    placeholder="Nombre del inmueble" readonly required>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="canSelectPropertyType">
                            <div class="form-group">
                                <label for="selectInmueble">Privada/Edificio/Calle</label>
                                <select class="form-control" name="selectInmueble" id="selectInmueble"
                                    #selectInmueble="ngModel" (change)="onSelectInmueble($event)" ngModel required>
                                    <option *ngFor="let opt of privedifcalle" [value]="opt.val">{{opt.text}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="!canSelectPropertyType">
                            <div class="form-group">
                                <label for="txtPropertyType">Privada/Edificio/Calle</label>
                                <input class="form-control" type="text" name="txtPropertyType" id="txtPropertyType"
                                    #txtPropertyType="ngModel"
                                    [ngModel]="userAddressService.selectedUserAddress.tipoInmueble.tipoInmueble"
                                    [value]="userAddressService.selectedUserAddress.tipoInmueble.tipoInmueble"
                                    placeholder="Nombre del inmueble" readonly>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                            *ngIf="canSelectPropertyType && showPrivadaEdif">
                            <div class="form-group">
                                <label for="tipoInmueble">Privada / Edificio</label>
                                <select (change)="onPropertyTypeChange($event)" [compareWith]="compareFn"
                                    class="form-control" name="tipoInmueble" id="tipoInmueble" #tipoInmueble="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.tipoInmueble" required>
                                    <option *ngFor="let privada of tipoInmuebleService.data" [ngValue]="privada">
                                        {{privada.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                            *ngIf="!canSelectPropertyType && !showPrivadaEdif && userAddressService.selectedUserAddress.tipoInmueble.calle != ''">
                            <div class="form-group">
                                <label for="calle">Calle0</label>
                                <input class="form-control" type="text" name="calle" id="calle" #calle="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.tipoInmueble.calle"
                                    placeholder="Calle" required
                                    [disabled]="!canSelectPropertyType && !showPrivadaEdif">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                            *ngIf="!canSelectPropertyType && !showPrivadaEdif && userAddressService.selectedUserAddress.calle != ''">
                            <div class="form-group">
                                <label for="calle">Calle1</label>
                                <input class="form-control" type="text" name="calle" id="calle" #calle="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.calle"
                                    placeholder="Calle" required
                                    [disabled]="!canSelectPropertyType && !showPrivadaEdif">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                            *ngIf="canSelectPropertyType && !showPrivadaEdif">
                            <div class="form-group">
                                <label for="calle">Calle2</label>
                                <input class="form-control" type="text" name="calle" id="calle" #calle="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.calle" placeholder="Calle"
                                    required>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="numExt">No. exterior</label>
                                <input class="form-control" type="text" name="numExt" id="numExt" #numExt="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.numExt"
                                    placeholder="No exterior" required>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="numInt">No. interior</label>
                                <input class="form-control" type="text" name="numInt" id="numInt" #numInt="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.numInt"
                                    placeholder="No interior">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="colonia">Colonia</label>
                                <input class="form-control" type="text" name="colonia" id="colonia" required
                                    placeholder="Colonia" readonly #colonia="ngModel"
                                    [ngModel]="userAddressService.selectedUserAddress.colonia"
                                    [value]="userAddressService.selectedUserAddress.colonia">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="cp">C.P.</label>
                                <input class="form-control" type="text" name="cp" id="cp" placeholder="Código postal"
                                    required readonly #cp="ngModel"
                                    [ngModel]="userAddressService.selectedUserAddress.cp"
                                    [value]="userAddressService.selectedUserAddress.cp">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="noHabitantes">Habitantes</label>
                                <input class="form-control" type="number" name="noHabitantes" id="noHabitantes"
                                    #noHabitantes="ngModel"
                                    [(ngModel)]="userAddressService.selectedUserAddress.noHabitantes"
                                    placeholder="No. habitantes" min="1" required>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <!-- <input type="hidden" name="_id" id="_id" #_id="ngModel" [(ngModel)]="userService.selectedUser._id"> -->
                            <div class="form-group">
                                <label for="status">Nombre</label>
                                <input class="form-control" type="text" name="name" id="name" #name="ngModel"
                                    placeholder="Nombre" [(ngModel)]="userService.selectedUser.name" required>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="surname">Apellidos</label>
                                <input class="form-control" type="text" name="surname" id="surname" #surname="ngModel"
                                    [(ngModel)]="userService.selectedUser.surname" placeholder="Apellidos" required>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="phone">Número de Telefono</label>
                                <input class="form-control" type="text" name="phone" id="phone" #phone="ngModel"
                                    [(ngModel)]="userService.selectedUser.phone" placeholder="No. de teléfono">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input class="form-control" type="text" name="email" id="email" #email="ngModel"
                                    [(ngModel)]="userService.selectedUser.email" placeholder="Correo electrónico"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <button #btnRegistro id="btnRegistro" class="btn btn-primary px-4 btn-block"
                                [disabled]="!isValidAccount">Registrarse</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="col-md-6 mx-auto" *ngIf="registrado">
    <div class="card-group mt-4">
        <div class="card">
            <div class="card-body">
                <h1 class="text-center">Registro exitoso</h1>
                <p class="text-muted">Muchas gracias por registrarte en QuickAccess, tus datos serán validados
                    y te notificaremos a través del correo electrónico registrado.</p>
                <button class="btn btn-success btn-block" [routerLink]="['/login']">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>