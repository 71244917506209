import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalComponent } from './principal/principal.component';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { Routes, RouterModule } from '@angular/router';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { InicioComponent } from './inicio/inicio.component';
import { FeaturesComponent } from './features/features.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ContactComponent } from './contact/contact.component';
import { EventComponent } from './event/event.component';

const routes: Routes = [
  { path: '', component: PrincipalComponent, },
  { path: 'event/:id', component: EventComponent }
]

@NgModule({
  declarations: [
    PrincipalComponent,
    MainNavbarComponent,
    InicioComponent,
    FeaturesComponent,
    SolutionsComponent,
    ContactComponent,
    EventComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MDBBootstrapModule.forRoot()
  ]
})
export class MainModule { }
