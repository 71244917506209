import { Component, TemplateRef } from '@angular/core';

import { ToastService } from '../../services/toastservice.service';

@Component({
  selector: 'app-toasts',
  template: `<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.options.classname" [autohide]="true"
  [delay]="toast.delay || 5000" (hide)="toastService.remove(toast)" [header]="toast.options.headertext">
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>`,
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastscontainerComponent {
  constructor(public toastService: ToastService) { }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
