import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cajero } from 'src/app/models/cajero';
import { Estado } from 'src/app/models/estado';
import { Municipio } from 'src/app/models/municipio';
import { StatusCajero } from 'src/app/models/statuscajero';
import { UserData } from 'src/app/models/user-data';
import { CajeroService } from 'src/app/services/cajero.service';
import { DetCajeroServicioService } from 'src/app/services/detcajeroservicio.service';
import { EstadoService } from 'src/app/services/estado.service';
import { MunicipioService } from 'src/app/services/municipio.service';
import { StatusCajeroService } from 'src/app/services/statuscajero.service';
import { ToastService } from 'src/app/services/toastservice.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-servicioactivo',
  templateUrl: './servicioactivo.component.html',
  styleUrls: ['./servicioactivo.component.css']
})
export class ServicioactivoComponent implements OnInit {
  page_title = 'Servicios Activos';
  activeModal: NgbModalRef;
  page = 1;
  filter = "";
  pageSize = 10;
  public filteredcajero: any[] = [];

  constructor(public detCajeroServicioService:DetCajeroServicioService,public cajeroService:CajeroService, public modal: NgbModal,public toastService: ToastService) { }

  ngOnInit(): void {
    
    this.getCajeros();
    this.cajeroService.selectedCajero=new Cajero();
  }
  getCajeros():void{
    this.cajeroService.getCajeros()
    .subscribe((res: any) => {
      this.cajeroService.cajeros = res.datos as Cajero[];
      this.filteredcajero=this.cajeroService.cajeros;
    }, (err: HttpErrorResponse) => {
      console.log('err',err);
    })
  }

  openModal(content) {
    this.activeModal = this.modal.open(content, { size: 'lg' });
    this.activeModal.result.then(res => {
      this.cajeroService.selectedCajero = new Cajero();
    }).catch(() => {
      this.cajeroService.selectedCajero = new Cajero();
    });
  }
  editCajero(cajero:Cajero,content)
  {
    this.getDetalleCajeroServicio(cajero);
    this.openModal(content);
    
  }
  getDetalleCajeroServicio(cajero:Cajero){
    this.detCajeroServicioService.getDetCajeroServicio(cajero.id_cajero)
      .subscribe((res: any) => {
        this.detCajeroServicioService.detallesCajeroSevicio = res.datos;

      }, (err: HttpErrorResponse) => {
        console.log('err',err);
      })

  }
  resetForm(form?: NgForm) {
    if (form) {
      form.reset();
      this.cajeroService.selectedCajero = new Cajero();
    }
    this.getCajeros();
  }
  closeModal(){
    this.cajeroService.selectedCajero = new Cajero();
    this.activeModal.close();
    this.getCajeros();
    
  }
  filterChange(e){
    e=e.toLocaleLowerCase();
    this.filteredcajero=this.cajeroService.cajeros.filter((val) => {
      let rVal = (val.numero.toString().includes(e)) 
      || (val.ubicacionfisica.toLocaleLowerCase().includes(e))
      || (val.id_usuario.toString().includes(e))
      || (val.id_teamviewer.toLocaleLowerCase().includes(e))
      return rVal;
    })

  }
  changeStatus(e)
  {
    
    this.detCajeroServicioService.postDetCajeroServicio(this.detCajeroServicioService.detallesCajeroSevicio[e])
    .subscribe((res: any) => {
    }, (error: HttpErrorResponse) => {
      //this.toastService.show(error.message,{classname: 'bg-warning text-dark',headertext: 'Información'});
    });
  }
}
