import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit, AfterViewInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.renderer.setStyle(document.body,'background-color', '#E9ECEE');
    // this.renderer.setStyle(document.body, 'background-image', "url('assets/2114.png_860.png')");
    // this.renderer.setStyle(document.body, 'background-repeat', 'no-repeat');
    // this.renderer.setStyle(document.body, 'background-position', 'center');
  }

  ngOnDestroy(): void {
    this.renderer.removeStyle(document.body,'background-image');
  }

}
